export enum PRODUCTS_NAME {
  AIRHELP_PRO_ANNUALLY = 'airhelp_pro_annually',
  AIRHELP_SMART_ANNUALLY = 'airhelp_smart_annually',
  AIRHELP_LITE_ANNUALLY = 'airhelp_lite_annually',
  MEMBERSHIP_ESSENTIAL_ANNUALLY = 'membership_essential_19_99_eur_annually',
  MEMBERSHIP_ESSENTIAL_SINGLE = 'membership_essential_19_99_eur_single',
  MEMBERSHIP_COMPLETE_ANNUALLY = 'membership_complete_49_99_eur_annually',
  MEMBERSHIP_COMPLETE_SINGLE = 'membership_complete_49_99_eur_single',
  MEMBERSHIP_COMPLETE_INTERNAL = 'membership_complete_internal',
  AIR_BUNDLE_MEMBERSHIP_ESSENTIAL_ANNUALLY = 'membership_essential_24_99_eur_annually',
  AIR_BUNDLE_MEMBERSHIP_ESSENTIAL_SINGLE = 'membership_essential_24_99_eur_single',
  AIR_BUNDLE_MEMBERSHIP_COMPLETE_ANNUALLY = 'membership_complete_64_99_eur_annually',
  AIR_BUNDLE_MEMBERSHIP_COMPLETE_SINGLE = 'membership_complete_64_99_eur_single',
  UPGRADE_TO_TRIP_COMPLETE = 'upgrade_to_trip_complete',
}

export enum PRODUCTS_DISPLAY_NAME {
  AIRHELP_PLUS_PRO = 'AirHelp+ Pro',
  AIRHELP_PLUS_SMART = 'AirHelp+ Smart',
  AIRHELP_PLUS_LITE = 'AirHelp+ Lite',
}
