import { plainToClass } from 'class-transformer';

export interface IConsents {
  marketingConsent: boolean;
  whatsappConsent: boolean;
}

export class Consents implements IConsents {
  marketingConsent: boolean;
  whatsappConsent: boolean;
}

export function transformToConsentsClass(consents: IConsents): Consents {
  return plainToClass(Consents, consents);
}
