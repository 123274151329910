import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  Box,
  type BoxProps,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { slice } from 'lodash';

import tripsRoute from 'assets/images/trips-route@2x.png';
import { LIMIT_JOURNEYS_ITEMS_HOME_PAGE } from 'components/Journeys/JourneysList/config';
import { PAGE_PATHS } from 'config/routes';
import LargeBlankTile from 'elements/LargeBlankTile';
import LargeUpsellTile from 'elements/LargeUpsellTile';
import { type UserPolicy } from 'models/UserPolicy';
import { journeyUtils } from 'utils';
import { useTrackEvent } from 'utils/tracking/hooks';

import Heading from './Heading';
import JourneyTiles from './JourneyTiles';
import { Journey } from '@airhelp/plus';

type IComponent = {
  journeys: Journey[];
  userPolicy: UserPolicy;
} & BoxProps;

export const JourneysLatest: React.FC<IComponent> = ({
  journeys,
  userPolicy,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const activeJourneys = journeyUtils.filteredAndSortedJourneys(
    journeys,
    true,
    LIMIT_JOURNEYS_ITEMS_HOME_PAGE,
  );
  const pastJourneys = journeyUtils.filteredAndSortedJourneys(
    journeys,
    false,
    LIMIT_JOURNEYS_ITEMS_HOME_PAGE,
  );

  const journeysToShow = slice(
    [...activeJourneys, ...pastJourneys],
    0,
    LIMIT_JOURNEYS_ITEMS_HOME_PAGE,
  );
  const canAddJourney = userPolicy.dashboard?.canAddTrip;

  const numberOfJourneys = journeysToShow.length;
  const hasJourneys = numberOfJourneys > 0;
  const hasLessThanTreeJourneys = numberOfJourneys < 3;
  const showBlankTile =
    canAddJourney &&
    hasLessThanTreeJourneys &&
    numberOfJourneys <= LIMIT_JOURNEYS_ITEMS_HOME_PAGE;

  const showUpsell = !userPolicy.coverage.active;

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isMiddleDesktop = useBreakpointValue({ base: false, xl: true });

  // the number 3 is intentional because for a certain number of journeys
  // we do not want to count the number of columns based on the number of journeys
  const numberOfGridColumns =
    numberOfJourneys < 3 && isMiddleDesktop
      ? 3
      : showBlankTile
        ? numberOfJourneys + 1
        : numberOfJourneys;

  const onSeeAllButtonClick = () => {
    trackCtaClick('see all trips', 'home page');
    navigate(generatePath(PAGE_PATHS.JOURNEYS_PAGE));
  };

  const onAddTripButtonClick = () => {
    trackCtaClick('add trip button', 'home page');
    navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE));
  };

  return (
    <Box
      overflow={{ base: 'hidden', md: 'unset' }}
      mx={{ base: -4, md: 'unset' }}
      px={{ base: 4, md: 'unset' }}
      {...props}
    >
      <Box
        p={{ base: 4, md: 0 }}
        bg={{ base: 'greyscale.100', md: 'transparent' }}
        borderRadius="lg"
      >
        <Heading
          canAddJourney={canAddJourney}
          onSeeAllButtonClick={onSeeAllButtonClick}
          showSeeAllButton={hasJourneys}
          userPolicy={userPolicy}
        />
        {hasJourneys ? (
          <JourneyTiles
            journeys={journeysToShow}
            numberOfGridColumns={numberOfGridColumns}
            showBlankTile={showBlankTile}
          />
        ) : canAddJourney ? (
          <LargeBlankTile
            image={tripsRoute}
            header={t('journeys.protect_future_trip')}
            description={t('journeys.enjoy_peace_of_mind')}
            buttonLabel={t('dashboard.add_trip')}
            onClick={onAddTripButtonClick}
            mt={4}
            border={{ base: '1px solid', md: 'unset' }}
            borderColor={{ base: 'greyscale.400', md: 'unset' }}
            data-testid="large-blank-tile-trips"
          />
        ) : (
          showUpsell && (
            <LargeUpsellTile
              onClick={() => trackCtaClick('see more plans', 'home page')}
              mt={4}
            />
          )
        )}
        {isMobile && hasJourneys ? (
          <Box width="100%" mt={hasJourneys ? 10 : 4}>
            <Button
              size="md"
              onClick={onSeeAllButtonClick}
              variant="secondary"
              width="100%"
              data-testid="btn-see-all-trips"
            >
              {t('common.see_all')}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default JourneysLatest;
