import React, { Fragment, useRef } from 'react';

import Slider from 'react-slick';
import { spacing16x, spacing8x } from '@airhelp/design-tokens';
import { Box, useBreakpointValue, Flex } from '@chakra-ui/react';
import PerkBanner from '../PerkBanner';
import PerkDrawer from '../PerkDrawer';
import ArrowButton from 'elements/ArrowButton';

import { PerkServicesConfig } from '../PerkServices';

interface IComponent {
  config: PerkServicesConfig;
  noOfAvailablePerks: number;
}

const PerkCarousel = ({ config, noOfAvailablePerks }: IComponent) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const slidesToShow = useBreakpointValue({
    base: 2,
    sm: 3,
    md: 2,
    lg: 2,
    xlg: 3,
    xl: 3,
  });

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: Math.min(noOfAvailablePerks, slidesToShow || 0),
    adaptiveHeight: false,
    arrows: false,
  };

  const slider = useRef<Slider>(null);

  const nextSlide = () => {
    slider?.current?.slickNext();
  };
  const prevSlide = () => {
    slider?.current?.slickPrev();
  };

  const perksContent = Object.values(config).map(
    (perk) =>
      perk && (
        <Fragment key={`perk-service-${perk.service}`}>
          <Box pr={{ base: 0, md: 4 }} flex="1">
            <PerkBanner
              imageSrc={perk.imageSmallSrc}
              imageText={perk.imageText}
              logoSrc={perk.logoSrc}
              description={perk.description}
              onClick={perk.disclosureHook.onOpen}
              discount={perk.discount}
              service={perk.service}
              provider={perk.provider}
              key={`${perk.service}-perk-banner`}
            />
            <PerkDrawer
              isOpen={perk.disclosureHook.isOpen}
              onClose={perk.disclosureHook.onClose}
              content={perk.drawerContent}
              imageText={perk.imageText}
              imageTextColor={perk.imageTextColor}
              imageSrc={perk.imageSrc}
              service={perk.service}
              provider={perk.provider}
              key={`${perk.service}-perk-drawer`}
            />
          </Box>
        </Fragment>
      ),
  );

  return isMobile ? (
    <Flex
      order={4}
      overflowX="auto"
      alignItems="stretch"
      width={`calc(100% + ${spacing8x})`}
      mx={-4}
      gap={2}
      px={4}
      sx={{
        scrollbarWidth: 'none',
        msOverflowStyle: '-ms-autohiding-scrollbar',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      {perksContent}
    </Flex>
  ) : (
    <Box order={4} position="relative" data-testid="perk-carousel">
      <ArrowButton
        onClick={nextSlide}
        position="absolute"
        top="-48px"
        right="0px"
        background="greyscale.100"
        type="forward"
        boxShadow="0px 1px 2px 0px rgba(30, 41, 54, 0.06), 0px 4px 8px -2px rgba(30, 41, 54, 0.08)"
        _hover={{ bgColor: 'greyscale.200' }}
        _active={{ bgColor: 'greyscale.300' }}
      />
      <ArrowButton
        onClick={prevSlide}
        position="absolute"
        top="-48px"
        right="40px"
        background="greyscale.100"
        type="back"
        boxShadow="0px 1px 2px 0px rgba(30, 41, 54, 0.06), 0px 4px 8px -2px rgba(30, 41, 54, 0.08)"
        _hover={{ bgColor: 'greyscale.200' }}
        _active={{ bgColor: 'greyscale.300' }}
      />
      <Box
        width={{
          base: `calc(100% + ${spacing8x})`,
          lg: `calc(100% + ${spacing16x})`,
          xlg: '100%',
        }}
        overflow="hidden"
        mx={{ base: -4, md: -4, lg: -8, xlg: 0 }}
        px={{ base: 4, md: 4, lg: 8, xlg: 0 }}
        sx={{
          '.slick-list': { margin: '0 -16px 0 0', overflow: 'unset' },
          '.slick-track': { display: 'flex' },
          '.slick-slide': {
            display: 'flex',
            height: 'unset',
            '> div': { width: '100%' },
          },
        }}
      >
        <Slider ref={slider} {...sliderSettings}>
          {perksContent}
        </Slider>
      </Box>
    </Box>
  );
};

export default PerkCarousel;
