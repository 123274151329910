import config from 'config/config';

const { zowieChat: zowieChatConfig } = config;

const loadScript = () => {
  if (zowieChatConfig.enabled) {
    const zowieScript = document.createElement('script');
    const zowieContainer = document.createElement('div');
    zowieContainer.setAttribute('id', 'chatbotize');
    zowieScript.src = `${zowieChatConfig.url}/chatbotize-entrypoint.min.js`;

    document.head.appendChild(zowieScript);
    document.body.appendChild(zowieContainer);
  }
};

export { loadScript };
