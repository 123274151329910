import { VStack } from '@chakra-ui/react';
import Questions from './Questions';
import PlanUpsell from './PlanUpsell';
import PlanComparison from './PlanComparison';
import NewBenefits from './NewBenefits';
import PartnerDeals from './PartnerDeals';
import { usePreferencesStore } from 'stores/preferences';

export default function SpecialOfferView() {
  const currency = usePreferencesStore((state) => state.currency);

  return (
    <VStack
      bg="greyscale.100"
      px={{ base: 4, md: 10 }}
      py={{ base: 8, md: 10 }}
      borderRadius="lg"
      alignItems="center"
      justifyContent="center"
      spacing={{ base: 10 }}
      data-testid="special-offer-view"
    >
      <PlanComparison currency={currency} />
      <NewBenefits />
      <PartnerDeals />
      <Questions />
      <PlanUpsell />
    </VStack>
  );
}
