import React from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import track from 'react-tracking';

import CheckoutView from 'components/Membership/CheckoutView';
import { PAGE_PATHS } from 'config/routes';
import StripePaymentContextProvider from 'contexts/StripePaymentContext/StripePaymentContext';
import { PageLoader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePreferencesStore } from 'stores/preferences';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CurrencyCode } from 'stores/preferences/currencies';

const goToEmailStep = (searchParams: URLSearchParams, replace: boolean) => {
  return (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY_PAGE),
        search: searchParams.toString(),
      }}
      replace={replace}
    />
  );
};

const CheckoutPage = () => {
  usePageTracking();

  const [searchParams] = useSearchParams();
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useFetchMyProfile();
  const { isLoading: isLoadingOffers, data } = useComputedOffers();
  const setCurrency = usePreferencesStore((state) => state.setCurrency);

  if (!userProfile && !isLoadingUserProfile) {
    return goToEmailStep(searchParams, false);
  }

  if (isLoadingOffers || isLoadingUserProfile) {
    return <PageLoader />;
  }

  if (data) {
    const offers = data.offers;
    const renewOffer = data.renewOffer;
    const upgradeOffer = data.upgradeOffer;

    const renew = searchParams.get('renew');
    const upgrade = searchParams.get('upgrade');

    const isManualRenew = Boolean(renew);
    const isUpgradeOffer = Boolean(upgrade);

    const renewPrices = renewOffer ? Object.values(renewOffer.prices) : [];

    // if membership expired, renew offers has more than one price,
    // so we redirect the user to regular flow with the possibility of changing the currency
    if (isManualRenew && !!renewPrices && renewPrices.length > 1) {
      const redirectedSearch = new URLSearchParams(searchParams.toString());

      redirectedSearch.delete('renew');

      return goToEmailStep(redirectedSearch, true);
    }

    // if renew offer exist then set currency from renew offer
    if (isManualRenew && !!renewPrices) {
      setCurrency(renewPrices[0].code as CurrencyCode);
    }

    return (
      <StripePaymentContextProvider>
        <CheckoutView
          offers={offers}
          renewOffer={renewOffer}
          isManualRenew={isManualRenew}
          upgradeOffer={upgradeOffer}
          isUpgradeOffer={isUpgradeOffer}
        />
      </StripePaymentContextProvider>
    );
  }

  return goToEmailStep(searchParams, false);
};

export default track({ page_type: 'Memberships.Buy.Checkout' })(CheckoutPage);
