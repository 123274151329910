import React from 'react';
import {
  useParams,
  Navigate,
  useSearchParams,
  generatePath,
} from 'react-router-dom';
import track from 'react-tracking';

import ProcessingPaymentView from 'components/ProcessingPaymentView';
import { PAGE_PATHS } from 'config/routes';
import { usePageTracking } from 'utils/tracking';

interface PageParams extends Record<string, string | undefined> {
  token: string;
}

const ProcessingPaymentPage = () => {
  const [searchParams] = useSearchParams();
  const { token } = useParams<PageParams>();

  usePageTracking();

  const paymentIntentId = searchParams.get('payment_intent') as string;
  const succeededPath = generatePath(
    PAGE_PATHS.UPSELL.TRIPS.PAYMENT_SUCCEEDED_PAGE,
    {
      token: token?.toString() || '',
    },
  );
  const tryAgainPath = generatePath(PAGE_PATHS.UPSELL.TRIPS.CHECKOUT_PAGE, {
    token: token?.toString() || '',
  });

  return paymentIntentId ? (
    <ProcessingPaymentView
      paymentIntentId={paymentIntentId}
      succeededPath={succeededPath}
      tryAgainPath={tryAgainPath}
    />
  ) : (
    <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />
  );
};

export default track({ page_type: 'Upsell.Trips.ProcessingPayment' })(
  ProcessingPaymentPage,
);
