import React from 'react';
import {
  useParams,
  Navigate,
  useSearchParams,
  generatePath,
} from 'react-router-dom';
import track from 'react-tracking';

import ProcessingPaymentView from 'components/ProcessingPaymentView';
import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { usePageTracking } from 'utils/tracking';

interface PageParams extends Record<string, string | undefined> {
  token: string;
}

const ProcessingPaymentPage = () => {
  usePageTracking();

  const { setActiveStep } = useLayout();
  const [searchParams] = useSearchParams();
  const { token } = useParams<PageParams>();

  useEffectOnce(() => {
    setActiveStep(3);
  });

  const paymentIntentId = searchParams.get('payment_intent') as string;
  const succeededPath = generatePath(
    PAGE_PATHS.TRIPS.UPGRADE.PAYMENT_SUCCEEDED_PAGE,
    { token },
  );
  const tryAgainPath = generatePath(PAGE_PATHS.TRIPS.UPGRADE.CHECKOUT_PAGE, {
    token,
  });
  return paymentIntentId ? (
    <ProcessingPaymentView
      paymentIntentId={paymentIntentId}
      succeededPath={succeededPath}
      tryAgainPath={tryAgainPath}
      // TODO: remove after migration to new funnel
      noLayoutWrap
    />
  ) : (
    <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />
  );
};

export default track({ page_type: 'Trips.Upgrade.ProcessingPayment' })(
  ProcessingPaymentPage,
);
