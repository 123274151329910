import { Expose } from 'class-transformer';

import { type CurrencyCode } from 'stores/preferences/currencies';

export interface IOrder {
  productName: string;
  identifier: string;
  totalAmount: string;
  discountAmount: string;
  paidAmount: string;
  currency: CurrencyCode;
}

export class Order implements IOrder {
  @Expose() productName: string;
  @Expose() identifier: string;
  @Expose() totalAmount: string;
  @Expose() discountAmount: string;
  @Expose() paidAmount: string;
  @Expose() currency: CurrencyCode;
}
