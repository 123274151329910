import React from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Box, Heading, VStack } from '@chakra-ui/react';

import { BottomBanner as AddToHomeScreenShortcutBottomBanner } from 'components/HomeView/AddToHomeScreenShortcut';
import JourneysLatest from 'components/Journeys/JourneysLatest';
import { PAGE_PATHS } from 'config/routes';
import { type ClaimEnquiry } from 'models/Claim';
import { type LoungePass } from 'models/LoungePass';
import type UserProfile from 'models/UserProfile';
import { useTrackEvent } from 'utils/tracking/hooks';

import ClaimsLatest from './ClaimsLatest';
import HowCanWeHelp from './HowCanWeHelp';
import LoungeExperienceTile from './LoungeExperienceTile';
import PerkServices from './PerksServices';
import UpsellSmart from './UpsellSmart';
import { Journey } from '@airhelp/plus';
import { Services } from './PerksServices/PerkServices';

interface IComponent {
  userProfile: UserProfile;
  journeys: Journey[];
  loungePasses: LoungePass[];
  claims: ClaimEnquiry[];
}

const perkServices: Services[] = [
  'esim',
  'carRental',
  'airportTransfer',
  'fastTrack',
];

const HomeView: React.FC<IComponent> = ({
  userProfile,
  journeys,
  claims,
  loungePasses,
}) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const navigate = useNavigate();

  const { policy: userPolicy } = userProfile;

  const { dashboard, membership, trip, perks, coverage } = userPolicy;

  const showMembershipSmartBanner =
    dashboard?.upsellEnabled && Boolean(!membership) && Boolean(trip);

  const showLoungeExperienceTile = loungePasses.length > 0;

  const showHomeScreenBottomBanner = isMobile && (isAndroid || isIOS);

  const canAddJourney = dashboard?.canAddTrip;

  const showPerkServices =
    Object.keys(perks).filter((perk) => {
      return Boolean(perks[perk]) && perkServices.includes(perk as Services);
    }).length > 0;

  const handleLoungeExperienceTileClick = () => {
    trackCtaClick('your available lounge passes', 'home page');
    navigate(generatePath(PAGE_PATHS.LOUNGES_PAGE));
  };

  return (
    <Box width="100%" mb={{ base: 10, md: 'unset' }}>
      <Heading
        fontSize={{ base: '2xl', md: '3xl' }}
        mt={{ base: 4, md: 2 }}
        mb={{ base: 2, md: 12 }}
      >
        {userProfile.firstName
          ? t('dashboard.welcome_message', { name: userProfile.firstName })
          : t('dashboard.welcome_message_no_name')}
      </Heading>
      <HowCanWeHelp userProfile={userProfile} canAddJourney={canAddJourney} />
      <VStack width="100%" spacing={12} align="stretch">
        {showLoungeExperienceTile ? (
          <LoungeExperienceTile
            loungePasses={loungePasses}
            handleClick={handleLoungeExperienceTileClick}
          />
        ) : null}
        <JourneysLatest
          journeys={journeys}
          userPolicy={userPolicy}
          order={coverage.covered ? 1 : 2}
        />
        <ClaimsLatest
          claimEnquiries={claims}
          order={coverage.covered ? 2 : 1}
        />

        {showPerkServices ? (
          <PerkServices
            userPolicy={userPolicy}
            showUpsellBanner={showMembershipSmartBanner}
            perkServices={perkServices}
          />
        ) : showMembershipSmartBanner ? (
          <UpsellSmart showPerkServices={showPerkServices} />
        ) : null}

        {showHomeScreenBottomBanner ? (
          <Box order={5}>
            <AddToHomeScreenShortcutBottomBanner />
          </Box>
        ) : null}
      </VStack>
    </Box>
  );
};

export default HomeView;
