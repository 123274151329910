import config, { WebsitePaths } from 'config/config';
import { Locale } from 'contexts/LocaleContext/locales';

import { convertDomainUrl } from './domains';

function getDashboardUrl(): string {
  return convertDomainUrl(window.location.origin, '');
}

function getWebappUrl(): string {
  return convertDomainUrl(window.location.origin, config.webappUrl);
}

function getWebsiteUrl(): string {
  return convertDomainUrl(window.location.origin, config.websiteUrl);
}

function getWebappClaimUrl(): string {
  return `${getWebappUrl()}/claims/`;
}

function getWebappNewClaimUrl(): string {
  return `${getWebappUrl()}/claims/new`;
}

// TODO; use website regional domain when website is available in those regions
function getWebsitePageUrl(path: keyof WebsitePaths, locale: Locale): string {
  const localizedPath = config.websitePaths[path][locale];
  const defaultPath = config.websitePaths[path].en;

  return localizedPath
    ? `${config.websiteUrl}/${locale}/${localizedPath}`
    : `${config.websiteUrl}/en/${defaultPath}`;
}

// TODO; use website regional domain when website is available in those regions
function getWebsiteAhPlusPageUrl(hash: string, locale: Locale = 'en'): string {
  return hash
    ? `${config.websiteUrl}/${locale}/airhelp-plus#${hash}`
    : `${config.websiteUrl}/${locale}/airhelp-plus`;
}

export {
  getDashboardUrl,
  getWebappUrl,
  getWebsiteUrl,
  getWebappClaimUrl,
  getWebappNewClaimUrl,
  getWebsitePageUrl,
  getWebsiteAhPlusPageUrl,
};
