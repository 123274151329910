import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@chakra-ui/react';

import airPayout from 'assets/images/air-bundle/airPayout.png';
import InfoModal from 'components/Journey/AirBundle/InfoModal';

import HeaderContent from '../HeaderContent';
import { AirPayoutJourney } from '@airhelp/plus';

const Header = ({
  airPayoutJourney,
  linkToFile,
}: {
  airPayoutJourney: AirPayoutJourney;
  linkToFile: string;
}) => {
  const { t } = useTranslation();

  const { payoutAmount, computed } = airPayoutJourney;
  const { outboundOnly, cancelationOnly } = computed;

  const airPayoutPayout = payoutAmount.toString();

  const airPayoutModalContent = () => {
    const isCancelationOnly = cancelationOnly;
    const isOutboundOnly = outboundOnly;
    const airPayoutPayout = payoutAmount.toString();
    const getAirPayoutDescription =
      isOutboundOnly && isCancelationOnly
        ? t('air_payout.modal.main.description_outbound', {
            amount: airPayoutPayout,
          })
        : t('air_payout.modal.main.description', { amount: airPayoutPayout });
    return [
      {
        title: t('air_payout.title'),
        description: getAirPayoutDescription,
        benefits: [
          t('air_payout.modal.main.no_forms'),
          t('air_payout.modal.main.no_signatures'),
        ],
      },
      {
        title: t('air_payout.modal.main.process'),
        benefits: [
          t('air_payout.modal.main.detect_and_notify'),
          t('air_payout.modal.main.provide_bank_details'),
        ],
      },
    ];
  };

  const getAirPayoutDescription =
    outboundOnly && cancelationOnly
      ? t('air_payout.modal.main.description_outbound', {
          amount: airPayoutPayout,
        })
      : t('air_payout.modal.main.description', { amount: airPayoutPayout });

  const readMoreModal = (
    <InfoModal
      modalContent={airPayoutModalContent()}
      image={airPayout}
      render={(openModalHandler) => (
        <Link
          fontSize="md"
          fontWeight="normal"
          color="primary.500"
          onClick={openModalHandler}
          _visited={{ color: 'greyscale.100' }}
          _focus={{ boxShadow: 'none' }}
          data-testid="air-payout-read-more-button"
        >
          {t('common.more')}
        </Link>
      )}
    />
  );

  return (
    <HeaderContent
      readMoreModal={readMoreModal}
      description={getAirPayoutDescription}
      linkToFile={linkToFile}
      title={t('air_payout.title')}
    />
  );
};

export default Header;
