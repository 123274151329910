import { useTranslation } from 'react-i18next';

import { Check } from '@airhelp/icons';
import { AirPayoutStatus, AirPayoutStatusType } from '@airhelp/plus';

const useGetAirPayoutStatusBadge = (status?: AirPayoutStatusType) => {
  const { t } = useTranslation();
  switch (status) {
    case AirPayoutStatus.enum.active:
      return {
        label: t('common.active'),
      };
    case AirPayoutStatus.enum.payout_successful:
      return {
        label: t('common.paid'),
        icon: Check,
        style: {
          color: 'greyscale.100',
          backgroundColor: 'success.500',
        },
      };
    case AirPayoutStatus.enum.eligible:
      return {
        label: t('air_payout.card.badge.eligible'),
        style: {
          color: 'success.500',
        },
      };
    case AirPayoutStatus.enum.payout_failed:
    case AirPayoutStatus.enum.payout_returned:
      return {
        label: t('air_payout.card.badge.resubmit_bank_details'),
        style: {
          color: 'warning.500',
        },
      };
    case AirPayoutStatus.enum.ineligible:
    case AirPayoutStatus.enum.unregistered:
      return {
        label: t('air_payout.card.badge.not_eligible'),
      };
    case AirPayoutStatus.enum.processing_payment:
    case AirPayoutStatus.enum.manual_verification_pending:
      return {
        label: t('common.processing_payment'),
      };
    case AirPayoutStatus.enum.expired:
      return {
        label: t('air_payout.card.badge.claim_expired'),
      };
    default:
      return {
        label: t('common.active'),
      };
  }
};

export default useGetAirPayoutStatusBadge;
