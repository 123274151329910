import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { type IconType } from '@airhelp/icons';
import { CurrencySwitcherModal } from '@airhelp/react';
import { useDisclosure } from '@chakra-ui/react';

import { TrackingContext } from 'contexts';
import { usePreferencesStore } from 'stores/preferences';
import {
  type CurrencyCode,
  type CurrencyData,
  currencies,
} from 'stores/preferences/currencies';
import { useTrackEvent } from 'utils/tracking/hooks';

interface CurrencyListItem {
  id: string;
  name: string;
  icon: IconType;
  code: string;
  symbol: string;
}

interface Props {
  render: (openModalHandler: () => void) => void;
  onCurrencyChange?: (currency: CurrencyCode) => void;
}

const CurrencySwitcher: React.FC<Props> = ({ render, onCurrencyChange }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currency = usePreferencesStore((state) => state.currency);
  const setCurrency = usePreferencesStore((state) => state.setCurrency);

  const { trackCtaClick } = useTrackEvent();
  const { subPageType } = useContext(TrackingContext);

  const getCurrencyListItems = (): CurrencyListItem[] => {
    const currenciesArray: CurrencyData[] = Object.values(currencies);

    return currenciesArray.map(
      (currencyData: CurrencyData): CurrencyListItem => {
        return {
          id: currencyData.id,
          name: t(currencyData.name),
          icon: currencyData.icon,
          code: currencyData.code,
          symbol: currencyData.symbol,
        };
      },
    );
  };

  const currencyListItems = getCurrencyListItems();

  const currencyList = { listItems: currencyListItems };

  const onItemClick = async (data: CurrencyListItem): Promise<void> => {
    const newCode = data.code as CurrencyCode;
    setCurrency(newCode);
    onCurrencyChange?.(newCode);
    onClose();
  };

  const onOpenCallback = () => {
    trackCtaClick('currency symbol', subPageType);

    onOpen();
  };

  return (
    <CurrencySwitcherModal
      heading={t('common.select_currency')}
      selectedCurrency={currency}
      currencies={currencyList}
      onItemClick={onItemClick}
      onClose={onClose}
      onOpen={onOpenCallback}
      isOpen={isOpen}
      render={render}
    />
  );
};

export default CurrencySwitcher;
