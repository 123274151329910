import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import track from 'react-tracking';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { PAGE_PATHS } from 'config/routes';
import {
  useLayout,
  usePageNavigationHeader,
} from 'contexts/LayoutContext/LayoutContext';
import { PageLoader } from 'elements';
import { type ComponentPageProps } from 'funnels/AirBundleFunnel';
import useEffectOnce from 'hooks/useEffectOnce';
import { useJourney, useCreateVerificationSession } from '@airhelp/plus/react';
import { getVeriffUrl, removeVeriffUrl, setVeriffUrl } from 'utils/veriff';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { mapLocaleToVeriff } from 'contexts/LocaleContext/locales';
import { VerificationSession } from '@airhelp/plus';
import { pascalCase } from 'utils/pascalCase';

const VeriffPage: React.FC<ComponentPageProps> = ({
  journeyId,
  insuranceType,
}) => {
  const { setActiveStep } = useLayout();
  const { t } = useTranslation();
  const { data, isLoading, isError } = useJourney({
    params: { id: parseInt(journeyId) },
  });

  const [searchParams] = useSearchParams();
  const paramsAirRequestId = searchParams.get('airRequestId');
  const airRequestId =
    paramsAirRequestId != null ? parseInt(paramsAirRequestId) : undefined;
  const initialized = useRef(false);

  const [isDoneOrSkipped, setIsDoneOrSkipped] = useState(false);
  const { locale } = useLocale();
  const createSession = useCreateVerificationSession();

  const id = parseInt(journeyId);

  usePageNavigationHeader({
    title: t('navigation.your_trip'),
    backPath: generatePath(PAGE_PATHS.JOURNEY_PAGE, { id }),
  });

  useEffectOnce(() => {
    setActiveStep(3);
  });

  useEffect(() => {
    if (!data || initialized.current) {
      return;
    }
    initialized.current = true;
    const url = getVeriffUrl(id, insuranceType, airRequestId);
    if (url && airRequestId) {
      handleVeriff(url, airRequestId);
    } else {
      getNewVeriffUrl(airRequestId);
    }
  }, [data]);

  const getNewVeriffUrl = async (airRequestId?: number) => {
    createSession.mutate(
      {
        journeyId: id,
        airRequestId,
        productType: insuranceType,
      },
      {
        onSuccess: (data: VerificationSession) => {
          if (data?.sessionUrl && data.airRequestId) {
            handleVeriff(data.sessionUrl, data.airRequestId);
          } else {
            setIsDoneOrSkipped(true);
          }
        },
        onError: () => {
          setIsDoneOrSkipped(true);
        },
      },
    );
  };

  const handleVeriff = (url: string, airRequestId: number) => {
    setVeriffUrl(id, url, insuranceType, airRequestId);
    const lang = mapLocaleToVeriff(locale);

    createVeriffFrame({
      url,
      lang,
      onEvent(msg) {
        switch (msg) {
          case MESSAGES.CANCELED: {
            setIsDoneOrSkipped(true);
            break;
          }
          case MESSAGES.FINISHED: {
            removeVeriffUrl(id, insuranceType, airRequestId);
            setIsDoneOrSkipped(true);
            break;
          }
          case MESSAGES.STARTED:
            break;
          case MESSAGES.RELOAD_REQUEST:
            break;
        }
      },
    });
  };

  if (isDoneOrSkipped) {
    const confirmationPath = PAGE_PATHS.JOURNEY[insuranceType.toUpperCase()]
      .PAYOUT_CONFIRMATION_PAGE as string;
    const pathname = generatePath(confirmationPath, { id });
    return <Navigate to={{ pathname }} />;
  }

  if (isLoading) {
    return <PageLoader />;
  }
  if (!isLoading && (!data || isError)) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return <PageLoader />;
};

export default track({
  page_type: (props: ComponentPageProps) =>
    `Journey.${pascalCase(props.insuranceType)}.Veriff`,
})(VeriffPage);
