import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

const BANK_NAME_INPUTS_NAME_MAX_LENGTH = 50;

const bankName = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  return (
    <Controller
      name="bankName"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: { value: /^\S+/, message: t('errors.required') },
        maxLength: {
          value: BANK_NAME_INPUTS_NAME_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.bank_name'),
            max: BANK_NAME_INPUTS_NAME_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.bank_name')}
            value={value}
            placeholder={t('common.bank_name')}
            onChange={onChange}
            data-testid="bank-name"
          />
        </Box>
      )}
    />
  );
};

export default bankName;
