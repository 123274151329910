import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import DashboardLogoLink from 'elements/DashboardLogoLink';
import LanguageSwitcher from 'elements/LanguageSwitcher';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useFeatureFlags } from '@airhelp/plus/react';

import UserAction from './UserAction';
import TopBanners from 'components/Layouts/Default/TopBanners';

const Header: React.FC = () => {
  const { data: featureFlags } = useFeatureFlags();
  const { userProfile } = useMyProfile();

  const showTopBannerEssentialUpgrade =
    Boolean(featureFlags?.essential_upgrade) &&
    Boolean(userProfile?.policy?.membership?.isEssential);

  return (
    <Box position="relative" width="100%">
      {showTopBannerEssentialUpgrade ? <TopBanners /> : null}
      <Box
        as="header"
        height={{ base: '48px', lg: '64px' }}
        position={{ base: 'fixed', lg: 'absolute' }}
        width="100%"
        zIndex="10"
        backgroundColor={{ base: 'greyscale.300', lg: 'transparent' }}
        mt={{
          base: showTopBannerEssentialUpgrade ? '48px' : 0,
          md: showTopBannerEssentialUpgrade ? '64px' : 0,
          lg: showTopBannerEssentialUpgrade ? '64px' : 0,
        }}
      >
        <Box
          position={{ base: 'absolute', lg: 'fixed' }}
          top={0}
          ml={{ base: 4, lg: 10 }}
          mt={{ base: 2, lg: showTopBannerEssentialUpgrade ? 20 : 10 }}
        >
          <DashboardLogoLink />
        </Box>
        <Flex
          height="100%"
          alignItems="center"
          justifyContent="flex-end"
          pr={{ base: 4, lg: 8 }}
          pl={4}
        >
          <LanguageSwitcher />
          <UserAction />
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
