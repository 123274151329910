import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import { Airplane, AirplaneMultipleTrips } from '@airhelp/icons';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';

import { getSmartProduct } from 'components/Membership/membershipHelper';
import TripCard from 'components/Trips/TripCard';
import { TRIP_ACTIVATION } from 'config/channels';
import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { ErrorBadge, Loader } from 'elements';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useEffectOnce from 'hooks/useEffectOnce';
import { type Offer } from 'models/Offers';
import { usePreferencesStore } from 'stores/preferences';
import {
  formatValueToCurrency,
  getLocalPriceValue,
} from 'stores/preferences/currencies';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useTrip } from '@airhelp/plus/react';

enum STEPS {
  CHECKOUT = 'checkout',
  COMPLETE = 'complete',
  HOME = 'home',
}

const AnnualView = ({
  offers,
  token,
}: {
  offers: Record<string, Offer>;
  token: string;
}) => {
  const { userProfile } = useMyProfile();
  const policy = userProfile?.policy;
  const { setActiveStep } = useLayout();
  const currency = usePreferencesStore((state) => state.currency);
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [searchParams] = useSearchParams();
  const [nextStep, setNextStep] = useState<STEPS | null>(null);
  const { trackUpsellOption, trackCtaClick } = useTrackEvent();

  const {
    isLoading: isLoadingTrip,
    isError: isErrorFetchTrip,
    data,
  } = useTrip({
    params: {
      uuid: token,
    },
  });

  const isEntitledToUpgradeToComplete = data?.trip?.upgradeable;
  const product = getSmartProduct(offers);

  useEffectOnce(() => {
    setActiveStep(1);
  });

  const handleCheckOut = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_trip_upgrade_get_annual_clicked',
    });
    setNextStep(STEPS.CHECKOUT);

    const upsellOption = 'get membership smart';
    trackUpsellOption(upsellOption, 'activation email');
  };

  const upsellComplete = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_trip_upgrade_get_annual_skip_clicked',
    });
    const ctaPlacement = 'b2b activation email upgrade to membership smart';

    trackCtaClick('no thanks', ctaPlacement);

    if (isEntitledToUpgradeToComplete) {
      setNextStep(STEPS.COMPLETE);
    } else {
      setNextStep(STEPS.HOME);
    }
  };

  const singleTripBenefits = [
    { text: t('trips.upgrade.annual.benefits.expenses_claims') },
    { text: t('trips.upgrade.annual.benefits.luggage_claims') },
    { text: t('trips.upgrade.annual.benefits.compensation') },
    { text: t('trips.upgrade.annual.benefits.assistance_with_refunds') },
    { text: t('trips.upgrade.annual.benefits.chat_support') },
    {
      text: t('trips.upgrade.annual.benefits.single_trip_coverage'),
      weight: 'bold',
    },
  ];

  const annualBenefits = [
    {
      text: t('trips.upgrade.smart.benefits.essential_benefits'),
      color: 'success.600',
      weight: 'bold',
    },
    {
      text: t('trips.upgrade.smart.benefits.additional_protection_3'),
      weight: 'bold',
    },
    {
      text: t('trips.upgrade.smart.benefits.disrupted_flight', {
        amount: 100,
      }),
      weight: 'bold',
    },
    {
      text: t('trips.upgrade.smart.benefits.delayed_bag', { amount: 100 }),
      weight: 'bold',
    },
    {
      text: t('trips.upgrade.smart.benefits.lounge_access'),
      weight: 'bold',
    },
  ];
  if (isLoadingTrip) {
    return <Loader dataTestId="loader" />;
  }

  if (!product || isErrorFetchTrip) {
    return (
      <Box maxWidth="90%">
        <ErrorBadge />
      </Box>
    );
  }

  const { prices } = product;
  const localPrice = getLocalPriceValue(prices, currency);

  const monthPrice = (Number(localPrice) / 12).toFixed(2);

  if (nextStep) {
    let search: URLSearchParams;

    switch (nextStep) {
      case STEPS.CHECKOUT:
        search = new URLSearchParams(searchParams.toString());
        search.set('product-name', product.name);
        search.set('channel', TRIP_ACTIVATION);

        return (
          <Navigate
            to={{
              pathname: generatePath(PAGE_PATHS.TRIPS.UPGRADE.CHECKOUT_PAGE, {
                token: token?.toString() || '',
              }),
              search: search.toString(),
            }}
          />
        );
      case STEPS.COMPLETE:
        return (
          <Navigate
            to={{
              pathname: generatePath(PAGE_PATHS.TRIPS.UPGRADE.COMPLETE_PAGE, {
                token: token?.toString() || '',
              }),
              search: searchParams.toString(),
            }}
          />
        );
      case STEPS.HOME:
        return (
          <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />
        );
    }
  }

  const subscriptionType = policy?.trip
    ? t(`pricing.plans.${policy.trip.tier}`)
    : null;

  return (
    <Box maxWidth="720px">
      <Heading variant="h2" mb={3}>
        {t('trips.upgrade.annual.annual_title')}
      </Heading>
      <Text fontSize="lg" fontWeight="normal">
        {t('trips.upgrade.annual.annual_subtitle', { subscriptionType })}
      </Text>
      <Flex
        mt={8}
        flexDirection={{ base: 'column', xlg: 'row' }}
        justifyContent={{ base: 'initial', xlg: 'space-between' }}
        alignItems={{ base: 'center', xlg: 'initial' }}
      >
        <TripCard
          title={t('trips.upgrade.annual.single_trip')}
          Icon={Airplane}
          benefits={singleTripBenefits}
          tag={t('common.added')}
          benefitLabel={t('trips.upgrade.annual.essential')}
        />
        <TripCard
          title={t('trips.upgrade.annual.annual')}
          Icon={AirplaneMultipleTrips}
          iconSize={12}
          benefits={annualBenefits}
          action={handleCheckOut}
          tag={t('common.add')}
          benefitLabel={t('trips.upgrade.smart.smart')}
          actionButtonLabel={t(
            'trips.upgrade.annual.button_buy_essential_annual',
            {
              value: formatValueToCurrency(localPrice, currency),
            },
          )}
          actionDescription={t(
            'trips.upgrade.annual.buy_essential_annual_description',
            {
              value: formatValueToCurrency(monthPrice, currency),
            },
          )}
          cardColor="primary.500"
          tagBackgroundColor="primary.200"
          dataTestId="trip-card-membership-upgrade"
        />
      </Flex>
      <Flex justifyContent="center" mt={12}>
        <Button
          size="m"
          p={1}
          onClick={upsellComplete}
          variant="link"
          data-testid="trip-card-membership-no-thanks"
        >
          {t('trips.upgrade.annual.thanks')}
        </Button>
      </Flex>
    </Box>
  );
};

export default AnnualView;
