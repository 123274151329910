import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BoxImportant } from '@airhelp/icons';
import {
  Button,
  Divider,
  Stack,
  VStack,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';

import { EditedJourneyContext } from 'contexts';
import type UserProfile from 'models/UserProfile';
import { ERROR_CODES } from 'types/api';
import { useTrackEvent } from 'utils/tracking/hooks';

import FellowPassengers from './FellowPassengers';
import FlightDetails from './FlightDetails';
import ContentBox from '../ContentBox';

interface IComponent {
  onNextStep: () => void;
  onPreviousStep: () => void;
  isSavingJourney: boolean;
  userProfile?: UserProfile;
  airLuggageAccess: boolean;
  firstFlightInPast: boolean;
  apiErrorCode: ERROR_CODES | null;
}

const FlightsDetailsView: React.FC<IComponent> = ({
  onNextStep,
  onPreviousStep,
  isSavingJourney,
  airLuggageAccess,
  userProfile,
  firstFlightInPast,
  apiErrorCode,
}) => {
  const policy = userProfile?.policy;
  const { t } = useTranslation();
  const { editedItinerary, isReturnTrip } = useContext(EditedJourneyContext);
  const { trackPageInteractions } = useTrackEvent();
  const { flights } = editedItinerary;

  const canAddFellowPassengersToFlight =
    policy?.dashboard?.canAddFellowPassengersToFlight;

  const onSubmit = () => {
    onNextStep();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const formHasErrors = Object.keys(errors).length > 0;
  const shouldContinue = !isReturnTrip;
  const isDisabled = Boolean(apiErrorCode) || formHasErrors || isSavingJourney;

  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getButtonLabel = () => {
    if ((airLuggageAccess && !firstFlightInPast) || shouldContinue) {
      return t('common.continue');
    }
    return t('funnels.add_new_flight.submit_trip');
  };

  useEffect(() => {
    if (apiErrorCode) {
      const addJourneyBlocker =
        apiErrorCode === ERROR_CODES.ADD_JOURNEY_BLOCKER;

      toast({
        title: t(
          addJourneyBlocker
            ? 'errors.sorry_you_cant_add_trip'
            : 'errors.something_went_wrong',
        ),
        description: addJourneyBlocker
          ? t('errors.please_contact_our_team')
          : null,
        status: 'error',
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: (
          <BoxImportant boxSize={5} alignSelf="center" color="greyscale.100" />
        ),
        containerStyle: {
          fontSize: 'sm',
          fontWeight: '500',
        },
      });

      if (addJourneyBlocker && Boolean(userProfile?.policy.membership)) {
        trackPageInteractions(
          'show',
          `add trip error message ${userProfile?.policy.membership?.tier}`,
        );
      }
    }
  }, [apiErrorCode]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={6} width="100%">
        {/* flights details */}
        <ContentBox
          header={t('funnels.add_new_flight.flight_details_step.header')}
        >
          <VStack
            maxWidth={{ base: '100%', sm: '410px' }}
            width="100%"
            spacing={6}
            divider={<Divider />}
            alignItems="flex-start"
          >
            {flights.map((flight) => (
              <FlightDetails
                flight={flight}
                key={flight.id}
                control={control}
                errors={errors}
              />
            ))}
          </VStack>
        </ContentBox>

        {/* fellow passengers */}
        {canAddFellowPassengersToFlight ? (
          <ContentBox
            data-testid="fellow-passengers-container"
            header={t(
              'funnels.add_new_flight.follow_passengers.add_your_fellow_passengers',
            )}
          >
            <FellowPassengers />
          </ContentBox>
        ) : null}
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row-reverse' }}
        mt={6}
        justifyContent="end"
        spacing={5}
      >
        <Button
          type="submit"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          isDisabled={isDisabled}
          isLoading={shouldContinue ? false : isSavingJourney}
          data-testid="form-action-continue"
        >
          {getButtonLabel()}
        </Button>
        <Button
          variant="secondary"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          onClick={onPreviousStep}
          data-testid="form-action-back"
        >
          {t('funnels.back')}
        </Button>
      </Stack>
    </form>
  );
};

export default FlightsDetailsView;
