import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { AirhelpPlus } from '@airhelp/logos';
import { Box, Flex, Text } from '@chakra-ui/react';
import { getYear } from 'date-fns';

import LanguageSwitcher from 'elements/LanguageSwitcher';

import ImageContent from './ImageContent';

interface TwoColumnsImageLayoutType extends PropsWithChildren {
  imageSrc?: string;
  noFeaturedImages?: boolean;
}

const TwoColumnsImageLayout: React.FC<TwoColumnsImageLayoutType> = ({
  children,
  imageSrc,
  noFeaturedImages,
}) => {
  const { t } = useTranslation();
  const copyrightDate = getYear(new Date());

  return (
    <Box
      width="100%"
      height={{ base: 'auto', lg: '100%' }}
      minHeight="100vh"
      backgroundColor="greyscale.100"
    >
      <Flex
        height="100vh"
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
      >
        <Box
          // "42%" - in the feature will by grid - 5/12
          width={{ base: '100%', lg: '65%', '2xl': '42%' }}
          height="100%"
          backgroundColor="greyscale.100"
          overflow="auto"
        >
          <Flex
            height="100%"
            minHeight="100%"
            width="100%"
            px={{ base: 4, xlg: 8 }}
            pt={{ base: 0, lg: 12 }}
            flexDirection="column"
          >
            <AirhelpPlus
              width={{ base: '110px', lg: '150px' }}
              height="auto"
              data-testid="airhelp-logo"
              display={{ base: 'none', lg: 'inline-block' }}
            />
            <Flex flex={1} mt={{ base: 10, lg: '104px' }}>
              {children}
            </Flex>
            <Flex
              justifyContent={{ base: 'flex-end', lg: 'space-between' }}
              alignItems="center"
              pb={{ md: 4, xlg: 6 }}
              pt={4}
            >
              <Box display={{ base: 'none', lg: 'inline-block' }}>
                <LanguageSwitcher />
              </Box>
              <Text fontSize="sm" color="greyscale.500" py={{ base: 4, lg: 0 }}>
                {t('common.en_copyright', { date: copyrightDate })}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Flex
          height={{ base: 'auto', lg: '100%' }}
          position="sticky"
          display="flex"
          width="100%"
          justifyContent="center"
          backgroundColor="greyscale.100"
        >
          {imageSrc ? (
            <ImageContent
              imageSrc={imageSrc}
              noFeaturedImages={noFeaturedImages}
            />
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
};

export default TwoColumnsImageLayout;
