import React from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import track from 'react-tracking';

import JourneyView from 'components/Journey/JourneyView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import { useJourney } from '@airhelp/plus/react';

interface Params extends Record<string, string | undefined> {
  id: string;
}

const JourneyPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  const { id } = useParams<Params>();

  if (!id) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  const {
    data: journeyData,
    isPending,
    isFetching,
    isError,
  } = useJourney({
    params: {
      id: parseInt(id),
    },
  });

  if (isPending || isFetching) {
    return <PageLoader />;
  } else if (isError || !journeyData) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }
  return <JourneyView journey={journeyData} />;
};

export default track({ page_type: 'Journey' })(JourneyPage);
