import { useTranslation } from 'react-i18next';

import { Check } from '@airhelp/icons';
import { AirLuggageStatus, AirLuggageStatusType } from '@airhelp/plus';

const useGetAirLuggageStatusBadge = (status?: AirLuggageStatusType) => {
  const { t } = useTranslation();
  switch (status) {
    case AirLuggageStatus.enum.active:
      return {
        label: t('common.active'),
      };
    case AirLuggageStatus.enum.payout_successful:
      return {
        label: t('common.paid'),
        icon: Check,
        style: {
          color: 'greyscale.100',
          backgroundColor: 'success.500',
        },
      };
    case AirLuggageStatus.enum.eligible:
      return {
        label: t('air_luggage.card.badge.eligible'),
        style: {
          color: 'success.500',
        },
      };
    case AirLuggageStatus.enum.payout_failed:
    case AirLuggageStatus.enum.payout_returned:
      return {
        label: t('air_luggage.card.badge.resubmit_bank_details'),
        style: {
          color: 'warning.500',
        },
      };
    case AirLuggageStatus.enum.ineligible:
    case AirLuggageStatus.enum.unregistered:
      return {
        label: t('air_luggage.card.badge.claim_rejected'),
      };
    case AirLuggageStatus.enum.processing_payment:
    case AirLuggageStatus.enum.manual_verification_pending:
      return {
        label: t('air_luggage.card.badge.checking_eligibility'),
      };
    case AirLuggageStatus.enum.expired:
      return {
        label: t('air_payout.card.badge.claim_expired'),
      };
    default:
      return {
        label: t('common.active'),
      };
  }
};

export default useGetAirLuggageStatusBadge;
