import React from 'react';
import { generatePath } from 'react-router';

import { buildSteps, step } from '@airhelp/funnel';
import { ReactRouterFunnel, useFunnelInit } from '@airhelp/funnel/react-router';
import { ContentSpinner } from '@airhelp/react';

import { PAGE_PATHS } from 'config/routes';
import { NotFoundPage } from 'pages';
import {
  PayoutConfirmationPage,
  PayoutPage,
  PayoutUpdatePage,
  VeriffPage,
} from 'pages/Journey/AirBundlePages';
import { InsuranceType } from '@airhelp/plus';

export interface ComponentPageProps {
  insuranceType: InsuranceType;
  journeyId: string;
}

export const useInitAirBundleFunnel = (
  props: Omit<ComponentPageProps, 'defaultActiveStep'>,
) => {
  return useFunnelInit(() => {
    const { journeyId, insuranceType } = props;

    const airBundlePath = PAGE_PATHS.JOURNEY[insuranceType.toUpperCase()]
      .FUNNEL_PAYOUT as string;

    const airBundleBasePath = generatePath(airBundlePath, { id: journeyId });

    return {
      basePath: airBundleBasePath,
      steps: buildSteps([
        step({
          name: 'payout',
          uri: '/payout',
          Component: () => (
            <PayoutPage journeyId={journeyId} insuranceType={insuranceType} />
          ),
        }),
        step({
          name: 'veriff',
          uri: '/veriff',
          Component: () => (
            <VeriffPage journeyId={journeyId} insuranceType={insuranceType} />
          ),
        }),
        step({
          name: 'payout-update',
          uri: '/payout-update',
          Component: () => (
            <PayoutUpdatePage
              journeyId={journeyId}
              insuranceType={insuranceType}
            />
          ),
        }),
        step({
          name: 'payout-confirmation',
          uri: '/payout-confirmation',
          Component: () => (
            <PayoutConfirmationPage
              journeyId={journeyId}
              insuranceType={insuranceType}
            />
          ),
        }),
      ]),
    };
  });
};

export const AirBundleFunnel = (
  props: Omit<ComponentPageProps, 'defaultActiveStep'>,
) => {
  const definition = useInitAirBundleFunnel(props);
  return (
    <ReactRouterFunnel
      definition={definition}
      loadingIndicator={<ContentSpinner />}
      notFoundStep={<NotFoundPage />}
    />
  );
};
