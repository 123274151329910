import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

const ABA_CODE_LENGTH_NUMBER = 9;

const aba = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  return (
    <Controller
      name="aba"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: { value: /^[0-9]+$/, message: t('errors.aba_invalid') },
        minLength: {
          value: ABA_CODE_LENGTH_NUMBER,
          message: t('errors.too_short', {
            attribute: t('common.en_aba'),
            min: ABA_CODE_LENGTH_NUMBER,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.en_aba')}
            value={value}
            placeholder={t('common.en_aba')}
            onChange={onChange}
            maxLength={ABA_CODE_LENGTH_NUMBER}
            data-testid="aba-number"
          />
        </Box>
      )}
    />
  );
};

export default aba;
