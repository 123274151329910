import { useCallback, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useTracking } from 'react-tracking';

import { TrackingContext, EditedJourneyContext } from 'contexts';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useEffectOnce from 'hooks/useEffectOnce';

import { getPageViewedData, getUserData } from './props/ga4';
import {
  createCheckoutEcommerce,
  productTierForOffer,
  productTierForChannel,
  productTier,
} from './props/ga4/checkout';
import {
  getUniversalPageViewedData,
  getUniversalUserData,
} from './props/universal';

export enum CheckoutEvent {
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase_ga',
  ADD_PAYMENT_INFO = 'add_payment_info',
}

const useTrackOnce = (
  callback: (setTracked: () => void) => void,

  effectDependencies: unknown[],
) => {
  const tracked = useRef(false);

  const setTracked = () => {
    tracked.current = true;
  };

  return useEffect(() => {
    if (!tracked.current) {
      callback(setTracked);
    }
  }, effectDependencies);
};

const usePageTracking = () => {
  const { setSubPageType } = useContext(TrackingContext);
  const { userProfile, queryState: userProfileQueryState } = useMyProfile();
  const { i18n } = useTranslation();
  const { trackEvent, getTrackingData } = useTracking();
  const location = useLocation();

  const isLoadingUserProfile = userProfileQueryState?.status == 'pending';
  const pageReferrer = sessionStorage.getItem('pageReferrer') || '';

  useEffect(() => {
    if (pageReferrer !== window.location.href) {
      sessionStorage.setItem('pageReferrer', window.location.href);
    }
  }, [location.pathname]);

  // tracking "ga4" page_view event
  useTrackOnce(
    (setTracked) => {
      if (!isLoadingUserProfile) {
        trackEvent({
          ...getPageViewedData(i18n.language, pageReferrer),
          ...getUserData(userProfile),
        });

        setTracked();
      }
    },
    [isLoadingUserProfile, userProfile],
  );

  // tracking "universal" pageViewed event
  useEffectOnce(() => {
    trackEvent({
      ...getUniversalPageViewedData(i18n.language),
      ...getUniversalUserData(userProfile),
    });
  });

  interface TrackingData {
    page_type?: string;
  }

  // set subPageType on TrackingContext based on page_type set on a pages/ component
  useEffect(() => {
    const pageType = (getTrackingData() as TrackingData).page_type || '';

    setSubPageType(pageType);
  }, [location.pathname, getTrackingData]);
};

const useTrackEvent = () => {
  const { userProfile } = useMyProfile();
  const { journey, isReturnTrip } = useContext(EditedJourneyContext);
  const { trackEvent } = useTracking();

  const trackCtaClick = useCallback(
    (label: string, placement: string) => {
      trackEvent({
        name: 'cta_click',
        label,
        placement,
      });
    },
    [trackEvent],
  );

  const trackPageInteractions = useCallback(
    (action: string, label: string, placement?: string) => {
      trackEvent({
        name: 'page_interactions',
        action,
        label,
        placement,
      });
    },
    [trackEvent],
  );

  const trackUpsellOption = useCallback(
    (label: string, option: string) => {
      trackEvent({
        name: 'upsell_option',
        label,
        option,
      });
    },
    [trackEvent, userProfile],
  );

  const trackOnboardingFunnel = useCallback(
    (label: string) => {
      trackEvent({
        name: 'onboarding_funnel',
        label,
      });
    },
    [trackEvent, userProfile],
  );

  const trackAddFlightFunnel = useCallback(
    (label: string) => {
      const departureAirport =
        journey?.itineraries[0]?.flights[0]?.departureAirport;

      const departureAirportIata = departureAirport?.iata;
      const departureAirportName = departureAirport?.name;

      const arrivalAirport = journey?.itineraries
        ?.slice(-1)[0]
        ?.flights?.slice(-1)[0]?.arrivalAirport;

      const arrivalAirportIata = arrivalAirport?.iata;
      const arrivalAirportName = arrivalAirport?.name;

      const flightsQuantity = journey.itineraries.reduce(
        (noOfFlights, itinerary) => noOfFlights + itinerary.flights.length,
        0,
      );

      trackEvent({
        name: 'add_trip',
        trip_departure: `${departureAirportName}, ${departureAirportIata}`,
        trip_destination: `${arrivalAirportName}, ${arrivalAirportIata}`,
        label,
        departure_date: journey?.itineraries[0]?.flights[0]?.localDepartureDate,
        trip_type: isReturnTrip ? 'return' : 'one way',
        flights_quantity: flightsQuantity,
      });
    },
    [trackEvent, userProfile],
  );

  return {
    trackCtaClick,
    trackPageInteractions,
    trackOnboardingFunnel,
    trackUpsellOption,
    trackAddFlightFunnel,
  };
};

const useTrackCheckout = () => {
  const { checkoutData } = useContext(TrackingContext);
  const { trackEvent } = useTracking();

  const isLocalStorageAvailable = useCallback(() => {
    try {
      localStorage.setItem('ahplus-local-storage-test', JSON.stringify({}));
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const trackCheckout = useCallback(
    (eventType: CheckoutEvent) => {
      // skip tracking if local storage is not available
      // checkout events tracking is based on data stored in local storage
      if (!isLocalStorageAvailable) {
        return;
      }

      const checkoutEvent = {
        name: eventType,
        ecommerce: createCheckoutEcommerce(checkoutData),
      };

      trackEvent(checkoutEvent);
    },
    [checkoutData],
  );

  const trackSubscriptionFunnel = useCallback(
    (pageType: 'Email' | 'Checkout' | 'Processing' | 'Failed' | 'Succeed') => {
      const urlSearchParams = new URLSearchParams(location.search);

      // skip tracking if local storage is not available
      // checkout events tracking is based on data stored in local storage
      if (!isLocalStorageAvailable) {
        return;
      }

      trackEvent({
        name: 'subscription_funnel',
        type: productTierForOffer(checkoutData.productDetails)
          ? productTier(urlSearchParams.get('tier') || '')
          : productTierForChannel(urlSearchParams.get('channel') || ''),
        label: `Membership - ${pageType}`,
      });
    },
    [checkoutData],
  );

  const trackPaymentFailed = useCallback(() => {
    trackEvent({ name: 'failed_payment' });
  }, [checkoutData]);

  return { trackCheckout, trackPaymentFailed, trackSubscriptionFunnel };
};

export { usePageTracking, useTrackEvent, useTrackCheckout, useTrackOnce };
