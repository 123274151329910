import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

import config from 'config/config';
import { createCookieOptions } from 'utils/cookies';

// bump version if you want to purge "Browser cache"
// it's temporary, we need smarter solution to change this value (file last mod date or VERSION from github/TC)
const version = '1.0';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: 'currentOnly',
    react: {
      useSuspense: false,
    },
    returnNull: false,
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie'],

      // keys or params to lookup language from (webapp behaviour)
      lookupQuerystring: 'lang',
      // start using cookie user_language to find default language (webapp behaviour)
      lookupCookie: config.languageCookie,

      // optional expire and domain for set cookie
      cookieMinutes: 525600, // 365 days
      cookieDomain: createCookieOptions().domain,

      // cache user language on
      caches: ['cookie'],
    },

    debug: import.meta.env.NODE_ENV !== 'production',

    supportedLngs: [
      'cs',
      'da',
      'de',
      'el',
      'en',
      'es',
      'fi',
      'fr',
      'it',
      'nb',
      'nl',
      'pl',
      'pt',
      'pt-BR',
      'ro',
      'ru',
      'tr',
      'sv',
      'zh-CN',
    ],

    backend: {
      loadPath: (lngs: string[]) => {
        const lng = lngs[0] === 'zh-CN' ? 'zh' : lngs[0];
        return `${import.meta.env.BASE_URL}locales/${lng}.json`;
      },
      queryStringParams: { v: version },
    },
  });

export default i18n;
