import { type TranslationKey } from 'types/i18n';

export const pluralVersion = (
  key: string,
  credits: number,
  unlimited = false,
) => {
  let tKey;

  if (unlimited) {
    tKey = `${key}_unlimited`;
  } else if (credits == 1) {
    tKey = `${key}_1`;
  } else if (credits >= 2 && credits <= 4) {
    tKey = `${key}_2_4`;
  } else {
    tKey = `${key}_5_12`;
  }

  return tKey as TranslationKey;
};
