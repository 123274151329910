import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useLocation,
  useNavigate,
  generatePath,
  Link as ReactLink,
} from 'react-router-dom';

import { AltArrowBack } from '@airhelp/icons';
import {
  Box,
  Flex,
  Heading,
  Link,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';

import JourneyActions from 'components/Journey/JourneyActions';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';

import CustomTab from './CustomTab';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot';
import { generateDisplayName } from 'utils/journey/journey';
import { Journey } from '@airhelp/plus';

export enum JourneyTab {
  FLIGHTS = 'flights',
  INSURANCE = 'insurance',
}

interface Tab {
  eventName: JourneyTab;
  key: JourneyTab;
  label: string;
  isActive: boolean;
  path: string;
  notification?: boolean;
}

const JourneyView = ({ journey }: { journey: Journey }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageInteractions } = useTrackEvent();

  usePageNavigationHeader(
    {
      title: t('navigation.your_trip'),
      backPath: generatePath(PAGE_PATHS.JOURNEYS_PAGE),
      actionSpace: <JourneyActions journey={journey} />,
    },
    [journey.id],
  );

  const hasAirPayout = journey.computed.hasAirPayout;
  const hasAirLuggage = journey.computed.hasAirLuggage;
  const showInsuranceTab = hasAirPayout || hasAirLuggage;

  const tabs: Tab[] = useMemo(() => {
    const tabsArray: Tab[] = [];
    if (showInsuranceTab) {
      tabsArray.push({
        eventName: JourneyTab.FLIGHTS,
        key: JourneyTab.FLIGHTS,
        label: t('common.flights'),
        isActive: location.pathname.includes(JourneyTab.FLIGHTS),
        path: PAGE_PATHS.JOURNEY.FLIGHTS_PAGE,
      });
      tabsArray.push({
        eventName: JourneyTab.INSURANCE,
        key: JourneyTab.INSURANCE,
        label: t('common.insurance'),
        isActive: location.pathname.includes(JourneyTab.INSURANCE),
        path: PAGE_PATHS.JOURNEY.INSURANCE_PAGE,
        notification: journey.displayAirBundleActionNotification,
      });
    }

    return tabsArray;
  }, [locale, location]);

  const onTabClick = (tab: Tab) => {
    trackPageInteractions('clicked', tab.eventName);

    navigate(generatePath(tab.path, { id: journey.id.toString() }));
  };

  const isFitted = useBreakpointValue({ base: true, lg: undefined });
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const journeyName = generateDisplayName(journey);

  return (
    <Box width="100%">
      <Flex
        mt={{ base: 4, md: 10 }}
        mb={8}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          {isDesktop ? (
            <Link
              as={ReactLink}
              to={generatePath(PAGE_PATHS.JOURNEYS_PAGE)}
              _hover={{ textUnderline: 'none' }}
              _visited={{}}
            >
              <AltArrowBack
                width={5}
                height={5}
                mr={2}
                color="primary.500"
                _hover={{ color: 'primary.600' }}
              />
            </Link>
          ) : null}
          <Heading variant="h4" data-testid="journey-name">
            {journeyName}
          </Heading>
        </Flex>
        {isDesktop ? <JourneyActions journey={journey} /> : null}
      </Flex>
      <Tabs
        mb={showInsuranceTab ? 4 : 0}
        isFitted={isFitted}
        index={tabs.findIndex((tab) => tab.isActive)}
      >
        <TabList border="none">
          {tabs.map((tab) => (
            <CustomTab
              key={`journey-details-tab-${tab.key}`}
              onClick={() => onTabClick(tab)}
              dataTestId={`journey-details-tab-${tab.key}`}
            >
              {tab.label}
              {tab.notification ? <PulsatingNotificationDot /> : null}
            </CustomTab>
          ))}
        </TabList>
      </Tabs>
      <Outlet />
    </Box>
  );
};

export default JourneyView;
