import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import processingPaymentImage from 'assets/images/processing-payment.jpg';
import { TwoColumnsImageLayout } from 'components/Layouts';
import { Loader } from 'elements';

const Processing = ({ noLayoutWrap }: { noLayoutWrap?: boolean }) => {
  const { t } = useTranslation();

  const Component = () => {
    return (
      <Box width="100%">
        <Heading variant="h1">{t('payment.processing.title')}</Heading>
        <Text fontSize="lg" fontWeight="medium" mt={3}>
          {t('payment.processing.description')}
        </Text>
        <Flex alignItems="center" justifyContent="center" mt={9}>
          <Loader size={40} />
        </Flex>
      </Box>
    );
  };

  if (noLayoutWrap) {
    return <Component />;
  }

  return (
    <TwoColumnsImageLayout imageSrc={processingPaymentImage} noFeaturedImages>
      <Component />
    </TwoColumnsImageLayout>
  );
};

export default Processing;
