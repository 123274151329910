import React from 'react';
import { useTranslation } from 'react-i18next';

import Section from 'components/MyAccountView/BenefitsView/Section';

import TripsList from './TripsList';
import { Journey } from '@airhelp/plus';

const YourTrips: React.FC<{ journeys: Journey[] }> = ({ journeys }) => {
  const { t } = useTranslation();

  return (
    <Section
      header={t('my_account.my_benefits.your_trips.header')}
      description={t('my_account.my_benefits.your_trips.description')}
    >
      <TripsList journeys={journeys} />
    </Section>
  );
};

export default YourTrips;
