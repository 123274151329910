import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';

import perkLogo from 'assets/images/perks/logo-suntransfers.svg';
import { useTrackEvent } from 'utils/tracking/hooks';

import BenefitsList from '../BenefitsList';
import GoToSiteButton from '../GoToSiteButton';

const TRACKING_PROVIDER_NAME = 'suntransfers';

const AirportTransfer: React.FC = () => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();

  const benefits = [
    {
      content: t('dashboard.perks.airport_transfer.easy_booking'),
      key: 'easy_booking',
    },
    { content: t('dashboard.perks.airport_transfer.no_fees'), key: 'no_fees' },
    {
      content: t('dashboard.perks.airport_transfer.great_value'),
      key: 'great_value',
    },
    {
      content: t('dashboard.perks.airport_transfer.free_cancellation'),
      key: 'free_cancellation',
    },
    {
      content: t('dashboard.perks.airport_transfer.tolls_included'),
      key: 'tolls_included',
    },
    {
      content: t('dashboard.perks.airport_transfer.customer_service'),
      key: 'customer_service',
    },
  ];

  const siteUrl = 'https://www.tkqlhce.com/click-101115961-12613932';

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(siteUrl, '_blank');
  };

  return (
    <VStack
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      minHeight="100%"
    >
      {/* top section */}
      <Box>
        <HStack
          pb={{ base: 4, md: 8 }}
          justifyContent="space-between"
          alignItems="center"
          mb={{ base: 2, md: 0 }}
          width="100%"
        >
          <Image src={perkLogo} h={{ base: 4, md: 6 }} />
        </HStack>

        <Heading variant={{ base: 'h5', md: 'h4' }} mb={4}>
          {t('dashboard.perks.airport_transfer.low_cost_transfers')}
        </Heading>

        <Text
          fontWeight="medium"
          fontSize={{ base: 'sm', md: 'md' }}
          pb={{ base: 4, md: 8 }}
        >
          {t('dashboard.perks.airport_transfer.book_hassle_free')}
        </Text>

        <Text fontSize={{ base: 'sm', md: 'md' }} color="greyscale.600">
          {t('dashboard.perks.airport_transfer.count_on_timely_pick_ups')}
        </Text>

        <Box my={{ base: 4, md: 8 }}>
          <BenefitsList benefits={benefits} />
        </Box>
      </Box>

      {/* bottom section */}
      <Box p={4} background="greyscale.300" borderRadius="xl" width="100%">
        <GoToSiteButton
          onClick={goToSite}
          label={t('dashboard.perks.airport_transfer.get_airport_transfer')}
        />
      </Box>
    </VStack>
  );
};

export default AirportTransfer;
