import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Select } from '@airhelp/react';
import { Box, Button, Flex, HStack, Link, Text } from '@chakra-ui/react';

import config from 'config/config';
import AirDocumentsUploader from 'elements/AirDocumentsUploader/AirDocumentsUploader';
import useEffectOnce from 'hooks/useEffectOnce';
import { type TranslationKey } from 'types/i18n';
import { itineraryUtils } from 'utils';
import {
  AirLuggageJourney,
  AirLuggagePaymentPax,
  AirBundleDocumentFile,
} from '@airhelp/plus';

const AIR_LUGGAGE_DOCUMENTS: { name: string; tKey: TranslationKey }[] = [
  {
    name: `${AirBundleDocumentFile.PIR}File`,
    tKey: 'air_luggage.luggage_details.documents.pir_reference',
  },
  {
    name: `${AirBundleDocumentFile.BOARDING}File`,
    tKey: 'air_luggage.luggage_details.documents.boarding_pass_or_booking_reference',
  },
  {
    name: `${AirBundleDocumentFile.PASSPORT}File`,
    tKey: 'air_luggage.luggage_details.documents.passport_or_id',
  },
  {
    name: `${AirBundleDocumentFile.BAGGAGE_TAG}File`,
    tKey: 'air_luggage.luggage_details.documents.baggage_tag',
  },
];

type IReducerDataById = Record<number, AirLuggagePaymentPax>;

const DetailsForm = ({
  form,
  airLuggageJourney,
  handleAddPax,
  canEdit,
  reducerDataById,
  setIsValidDataDetailsForm,
}: {
  form: UseFormReturn;
  airLuggageJourney: AirLuggageJourney;
  handleAddPax: (data) => void;
  canEdit: boolean;
  reducerDataById: IReducerDataById;
  setIsValidDataDetailsForm: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, clearErrors } = form;
  const { id, maxBags, availableItineraries } = airLuggageJourney;

  const handleDocument = (type: string, files: File[]) => {
    setValue(type, files[0]);
    clearErrors(type);
  };
  const handleDocumentDelete = (type: string): void => {
    setValue(type, null);
    setIsValidDataDetailsForm(false);
  };

  const handleSelect = (
    item: { value: string; label: string },
    onChange: (item: { value: string; label: string }) => void,
  ) => {
    onChange(item);
  };

  const itineraryOptions =
    itineraryUtils.formatItineraryOptions(availableItineraries);

  const luggagesOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];

  useEffectOnce(() => {
    if (reducerDataById[id]) {
      const { payoutEligibleItineraryId, numberOfLuggages } =
        reducerDataById[id];
      setValue(
        'payoutEligibleItineraryId',
        itineraryOptions.find(
          (itinerary) => itinerary.value === payoutEligibleItineraryId,
        ),
      );
      setValue(
        'numberOfLuggages',
        luggagesOptions.find((luggage) => luggage.value === numberOfLuggages),
      );
      setValue('pirReferenceFile', reducerDataById[id].pirReferenceFile);
      setValue(
        'boardingPassOrBookingReferenceFile',
        reducerDataById[id].boardingPassOrBookingReferenceFile,
      );
      setValue('passportOrIdFile', reducerDataById[id].passportOrIdFile);
      setValue('baggageTagFile', reducerDataById[id].baggageTagFile);
    }
  });

  return (
    <form onSubmit={handleSubmit(handleAddPax)}>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="greyscale.100"
        borderBottomRadius="xl"
      >
        <Box p={{ base: 4, md: 5 }} width="100%">
          {maxBags === 4 && (
            <Text
              fontSize="md"
              fontWeight="normal"
              mb={1}
              data-testid="air-luggage-payout-luggage-details-4-bags-info"
            >
              <Trans
                i18nKey="air_luggage.luggage_details.contact_mail_more_than_two_luggage"
                values={{ contactEmail: config.contactEmail, bags: 2 }}
                components={{
                  a: <Link fontSize="md" fontWeight="normal" isExternal />,
                }}
              />
            </Text>
          )}

          <HStack
            alignItems="flex-start"
            spacing={6}
            flexDirection="column"
            pt={2}
          >
            <Controller
              name="payoutEligibleItineraryId"
              control={control}
              rules={{
                required: { value: true, message: t('errors.required') },
              }}
              defaultValue={null}
              render={({
                field: { onChange, name, value },
                fieldState: { invalid, error },
              }) => (
                <Select
                  name={name}
                  isInvalid={invalid}
                  errorMessage={error?.message || ''}
                  label={t('air_luggage.luggage_details.choose_itinerary')}
                  caption={t(
                    'air_luggage.luggage_details.itinerary_where_had_the_luggage_issue',
                  )}
                  value={value}
                  options={itineraryOptions}
                  placeholder={t(
                    'air_luggage.luggage_details.choose_itinerary',
                  )}
                  handleChange={(value) => handleSelect(value, onChange)}
                  dataTestId={`payout-eligible-itinerary-id-${id}`}
                />
              )}
            />
            {maxBags > 1 && (
              <Controller
                name="numberOfLuggages"
                control={control}
                rules={{
                  required: { value: true, message: t('errors.required') },
                }}
                defaultValue={null}
                render={({
                  field: { onChange, name, value },
                  fieldState: { invalid, error },
                }) => (
                  <Select
                    name={name}
                    isInvalid={invalid}
                    errorMessage={error?.message || ''}
                    label={t(
                      'air_luggage.luggage_details.number_of_luggage_delayed_or_lost',
                    )}
                    caption={t('air_luggage.luggage_details.under_your_name')}
                    value={value}
                    options={luggagesOptions}
                    placeholder="1"
                    handleChange={(value) => handleSelect(value, onChange)}
                    dataTestId={`number-of-luggages-${id}`}
                  />
                )}
              />
            )}
          </HStack>
        </Box>
        {AIR_LUGGAGE_DOCUMENTS.map((document) => {
          const { name, tKey: label } = document;
          return (
            <Controller
              name={name}
              key={name}
              control={control}
              rules={{
                required: { value: true, message: t('errors.required') },
              }}
              defaultValue={null}
              render={({
                field: { value },
                fieldState: { invalid, error },
              }) => {
                return (
                  <Box
                    width="100%"
                    px={{ base: 4, md: 5 }}
                    pb={{ base: 4, md: 5 }}
                  >
                    <AirDocumentsUploader
                      documents={value ? [value] : []}
                      onDrop={(files) => handleDocument(name, files)}
                      onDelete={() => handleDocumentDelete(name)}
                      isInvalid={invalid}
                      multipleFiles={false}
                      error={error}
                      label={t(label)}
                      dataTestId={`${name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}-${id}-input`}
                    />
                  </Box>
                );
              }}
            />
          );
        })}

        {canEdit ? (
          <Flex
            px={{ base: 4, md: 5 }}
            pb={{ base: 4, md: 5 }}
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant="primary"
              size="s"
              onClick={handleSubmit(handleAddPax)}
              width={{ base: '100%', md: 'initial' }}
              data-testid={`details-add-button-${id}`}
            >
              {t('common.add')}
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </form>
  );
};

export default DetailsForm;
