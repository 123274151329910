import React, { forwardRef } from 'react';

import { Info, Check } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import {
  Center,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';

import { type ModalRef, useModalHandle } from 'elements/Modal/Modal';

interface IModalContent {
  title: string;
  description?: string;
  benefits: string[];
  infoBoxText?: string;
}

interface Props {
  modalContent: IModalContent[];
  image: string;
  render?: (openModalHandler: () => void) => void;
}

const InfoModal: React.ForwardRefRenderFunction<ModalRef, Props> = (
  { render, modalContent, image },
  ref,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModalHandler = () => {
    onOpen();
  };

  useModalHandle(ref, onOpen, onClose);

  return (
    <>
      {render ? render(openModalHandler) : null}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="600px" backgroundColor="greyscale.100">
          <Center
            h="240px"
            background="greyscale.400"
            w="100%"
            position="relative"
            borderTopRadius="md"
          >
            <ModalCloseButton
              data-testid="modal-close-button"
              borderRadius="sm"
              boxSize={2}
              top={6}
              right={6}
              backgroundColor="greyscale.300"
              sx={{
                svg: { boxSize: 2.5 },
                _hover: { backgroundColor: 'primary.200' },
                _active: {
                  backgroundColor: 'primary.800',
                  color: 'greyscale.200',
                },
              }}
              position="absolute"
              zIndex="1000"
            />
            <Image src={image} position="absolute" bottom={0} height="100%" />
          </Center>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            mt={4}
            mb={4}
            mx={6}
          >
            {modalContent.map((item) => (
              <React.Fragment key={item.title}>
                <Flex>
                  <Text fontWeight="700" fontSize="md" textAlign="left" mb={2}>
                    {item.title}
                  </Text>
                </Flex>
                {item.description ? (
                  <Flex>
                    <Text
                      fontSize="md"
                      mb={3}
                      data-testid="air-bundle-modal-description"
                    >
                      {item.description}
                    </Text>
                  </Flex>
                ) : null}
                {item.benefits.map((benefit) => (
                  <Flex key={benefit} alignItems="flex-start" mb={4}>
                    <Check color="success.600" mr={3} mt={1} />
                    <Text fontSize="sm">{benefit}</Text>
                  </Flex>
                ))}
                {item.infoBoxText ? (
                  <InfoBox
                    isChat
                    backgroundColor="greyscale.100"
                    icon={
                      <Info color="primary.500" display="inline-flex" mb={1} />
                    }
                    variant="secondary"
                    mb={4}
                  >
                    {item.infoBoxText}
                  </InfoBox>
                ) : null}
              </React.Fragment>
            ))}
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default forwardRef(InfoModal);
