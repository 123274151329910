import React from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import track from 'react-tracking';

import EmailView from 'components/Membership/EmailView';
import { PAGE_PATHS } from 'config/routes';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';
import { useTrackCheckout, useTrackOnce } from 'utils/tracking/hooks';

const EmailPage = () => {
  usePageTracking();

  const { userProfile } = useMyProfile();

  const [searchParams] = useSearchParams();
  const { trackSubscriptionFunnel } = useTrackCheckout();

  useTrackOnce(
    (setTracked) => {
      if (!userProfile) {
        trackSubscriptionFunnel('Email');
        setTracked();
      }
    },
    [userProfile, trackSubscriptionFunnel],
  );

  if (!userProfile) {
    return <EmailView />;
  }
  return (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY_PAGE),
        search: searchParams.toString(),
      }}
    />
  );
};
export default track({ page_type: 'Memberships.Buy.Email' })(EmailPage);
