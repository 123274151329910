import { useQuery } from '@tanstack/react-query';
import { plainToClass } from 'class-transformer';

import { paymentsApi } from 'api';
import { Order } from 'models/Order';

const useStripeOrder = (paymentIntentId: string) => {
  return useQuery({
    queryKey: ['stripeOrder', paymentIntentId],
    queryFn: () => paymentsApi.getStripeOrder(paymentIntentId),
    select: (order) => plainToClass(Order, order[1].order),
  });
};

export default useStripeOrder;
