import React from 'react';
import { useTranslation } from 'react-i18next';

import { AltArrowForward } from '@airhelp/icons';
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Flex,
  HStack,
  Spacer,
  useBreakpointValue,
  FlexProps,
} from '@chakra-ui/react';

import loungeFinder from 'assets/images/lounge-finder.png';

type IComponent = {
  handleClick: () => void;
} & FlexProps;

const SearchLoungesImage: React.FC<IComponent> = ({
  handleClick,
  ...props
}) => {
  const { t } = useTranslation();

  const handleImageClick = useBreakpointValue({
    base: handleClick,
    md: undefined,
  });

  return (
    <Flex
      height={{ base: 'auto', md: '281px' }}
      backgroundImage={`url(${loungeFinder})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="left 25%"
      justifyContent="center"
      alignItems="center"
      borderRadius="lg"
      px={{ base: 5, md: '80px' }}
      py={{ base: 8, md: '80px' }}
      onClick={handleImageClick}
      cursor={{ base: 'pointer', md: 'default' }}
      data-testid="lounge-finder-search-image"
      {...props}
    >
      <VStack
        justifyItems="flex-start"
        alignItems="center"
        borderRadius="lg"
        width="100%"
      >
        <HStack width="100%">
          <Box>
            <Heading
              variant={{ base: 'h5', md: 'h2' }}
              color="greyscale.100"
              mb={{ base: 2, md: 0 }}
            >
              {t('lounges.lounge_finder.search')}
            </Heading>
            <Text color="greyscale.100" fontSize="md">
              {t('lounges.lounge_finder.find')}
            </Text>
          </Box>
          <Spacer />
          <AltArrowForward
            color="greyscale.100"
            boxSize={6}
            display={{ base: 'block', md: 'none' }}
            data-testid="lounge-finder-arrow"
          />
        </HStack>
        <Button
          size="s"
          mt={2}
          onClick={handleClick}
          rightIcon={<AltArrowForward boxSize={5} />}
          display={{ base: 'none', md: 'inherit' }}
          alignSelf="flex-start"
          data-testid="lounge-finder-button"
        >
          {t('lounges.lounge_finder.cta')}
        </Button>
      </VStack>
    </Flex>
  );
};
export default SearchLoungesImage;
