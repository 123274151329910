import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, generatePath, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { postDashboardVisited } from '@airhelp/plus';
import { useAhPlusHTTPClient, useFeatureFlags } from '@airhelp/plus/react';

import { refreshAccessToken } from 'api/auth/auth';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { PageLoader } from 'elements';
import {
  prefetchMyProfile,
  useMyProfile,
} from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { setStatusBarColorByPathname } from 'utils/setStatusBarColorByPathname';
import { PAGE_PATHS } from 'config/routes';

enum UserInitializeState {
  Initial,
  Initializing,
  Initialized,
}

const AppInitializer: React.FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const [userInitializeState, setUserInitializeState] =
    useState<UserInitializeState>(UserInitializeState.Initial);
  const location = useLocation();
  const { userProfile } = useMyProfile();
  const { isError, isLoading: isLoadingFeatureFlags } = useFeatureFlags();
  const navigate = useNavigate();
  const ahplusClient = useAhPlusHTTPClient();

  const { displayAddToHomeScreenBanner } = useLayout();

  const isLoading =
    isLoadingFeatureFlags ||
    userInitializeState !== UserInitializeState.Initialized;

  useEffect(() => {
    async function initialize() {
      if (userInitializeState === UserInitializeState.Initial) {
        setUserInitializeState(UserInitializeState.Initializing);

        await refreshAccessToken();
        await prefetchMyProfile(queryClient);

        setUserInitializeState(UserInitializeState.Initialized);
      }
    }

    initialize();
  }, [userInitializeState, setUserInitializeState]);

  useEffect(() => {
    setStatusBarColorByPathname(
      location.pathname,
      displayAddToHomeScreenBanner,
    );
  }, [location.pathname, displayAddToHomeScreenBanner]);

  useEffect(() => {
    if (isError) {
      navigate(generatePath(PAGE_PATHS.ERROR_PAGE));
    }
  }, [isError]);

  useEffect(() => {
    if (userProfile && !userProfile.policy.dashboard?.dashboardVisited) {
      postDashboardVisited({ client: ahplusClient });
    }
  }, [userProfile]);

  if (isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};

export default AppInitializer;
