import { type UseQueryResult } from '@tanstack/react-query';

import { claimsApi } from 'api';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import {
  type ClaimEnquiry,
  type IClaimEnquiry,
  type IClaimsAndFlightsResponse,
  sortByCreatedAt,
  transformToClaimEnquiryClass,
} from 'models/Claim';

import { useProtectedQuery } from '../useProtectedQuery';

const fetchClaimsAndFlights = async () => {
  const [error, response] = await claimsApi.fetchClaimsAndFlights();

  if (error) {
    throw error;
  } else {
    return response as IClaimsAndFlightsResponse;
  }
};

const transformClaims = (data: IClaimsAndFlightsResponse): ClaimEnquiry[] => {
  const claims: ClaimEnquiry[] = data.claimEnquiries.map((claimEnquiry) => {
    const normalizedClaimEnquiry: IClaimEnquiry = {
      id: claimEnquiry.id,
      claimType: claimEnquiry.claimType,
      createdAt: claimEnquiry.createdAt,
      claimId: claimEnquiry.claimId,
      uuid: claimEnquiry.uuid,
      flights: claimEnquiry.itinerary.flights.map((flight) => {
        return {
          id: flight.id,
          departureAirportCode: flight.departureAirport.iata,
          departureCity: flight.departureAirport.city,
          arrivalAirportCode: flight.arrivalAirport.iata,
          arrivalCity: flight.arrivalAirport.city,
          localDepartureDate: flight.localDepartureDate,
          disrupted: flight.disrupted,
          airline: flight.marketingAirlineName,
        };
      }),
    };

    return transformToClaimEnquiryClass(normalizedClaimEnquiry);
  });

  return sortByCreatedAt(claims);
};

const useFetchClaimsEnquiries = (options: ApiQueryOptions = {}) => {
  return useProtectedQuery({
    queryKey: ['claims'],
    queryFn: () => fetchClaimsAndFlights(),
    select: (data) => transformClaims(data as IClaimsAndFlightsResponse),
    ...options,
  }) as UseQueryResult<ClaimEnquiry[]>;
};

export default useFetchClaimsEnquiries;
