import { useMutation, useQueryClient } from '@tanstack/react-query';

import { userProfileApi } from 'api';
import {
  type IConsents,
  transformToConsentsClass,
} from 'models/userProfile/Consents';

import { useMyProfile } from '../useFetchMyProfile/useFetchMyProfile';

const updateConsents = async (consents: IConsents) => {
  const [error, response] = await userProfileApi.updateConsents({
    ...consents,
  });

  if (error) {
    throw error;
  } else {
    return response;
  }
};

const useUpdateConsents = () => {
  const queryClient = useQueryClient();
  const { userProfile } = useMyProfile();

  const updateConsentsMutation = useMutation({
    mutationFn: (params: { consents: IConsents }) =>
      updateConsents(params.consents),
    onSuccess: (data: { consents: IConsents }) => {
      queryClient.setQueryData(['userProfile', 'consents'], data);

      const updatedConsents = transformToConsentsClass(data.consents);

      if (userProfile) {
        userProfile.consents.marketingConsent =
          updatedConsents.marketingConsent;
      }
    },
  });

  return updateConsentsMutation;
};

export default useUpdateConsents;
