import React, {
  type PropsWithChildren,
  useState,
  useContext,
  createContext,
  useEffect,
} from 'react';
import cookie from 'react-cookies';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import { useSteps } from '@chakra-ui/react';

import { HOMESCREEN_BANNER_DISCARDED_COOKIE } from 'config/cookies';
import useEffectOnce from 'hooks/useEffectOnce';
import { createCookieOptions } from 'utils/cookies';

interface MobileHeaderConfig {
  title: string | null;
  backPath: string | null;
  actionSpace?: React.ReactNode | null;
}

interface ILayoutContext {
  displayAddToHomeScreenBanner?: boolean;
  hideAddToHomeScreenBanner: () => void;
  mobileHeaderConfig: null | MobileHeaderConfig;
  setMobileHeaderConfig: (data: MobileHeaderConfig | null) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
  setStepsCount: (count: number) => void;
  stepsCount: number | undefined;
}

export const LayoutContext = createContext<ILayoutContext>({
  displayAddToHomeScreenBanner: false,
  hideAddToHomeScreenBanner: () => null,
  mobileHeaderConfig: null,
  setMobileHeaderConfig: () => null,
  activeStep: 0,
  setActiveStep: () => null,
  stepsCount: undefined,
  setStepsCount: () => null,
});

const initializeLayout = async (setDisplayAddToHomeScreenBanner) => {
  const showAddToHomeScreenBanner =
    !cookie.load(HOMESCREEN_BANNER_DISCARDED_COOKIE) &&
    isMobile &&
    (isAndroid || isIOS);

  setDisplayAddToHomeScreenBanner(showAddToHomeScreenBanner);
};

const LayoutContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mobileHeaderConfig, setMobileHeaderConfig] =
    useState<null | MobileHeaderConfig>(null);
  const [displayAddToHomeScreenBanner, setDisplayAddToHomeScreenBanner] =
    useState<boolean | undefined>(undefined);

  //For Funnels steps
  const [stepsCount, setStepsCount] = useState<number | undefined>(undefined);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: stepsCount,
  });

  const hideAddToHomeScreenBanner = () => {
    cookie.save(
      HOMESCREEN_BANNER_DISCARDED_COOKIE,
      '1',
      createCookieOptions({
        maxAge: 36500 * 24 * 60 * 60, // 100 years in seconds
      }),
    );

    setDisplayAddToHomeScreenBanner(false);
  };

  useEffectOnce(() => {
    initializeLayout(setDisplayAddToHomeScreenBanner);
  });

  return (
    <LayoutContext.Provider
      value={{
        // home banner
        displayAddToHomeScreenBanner,
        hideAddToHomeScreenBanner,

        // mobile header
        mobileHeaderConfig,
        setMobileHeaderConfig,

        // funnel steps
        activeStep,
        setActiveStep,
        setStepsCount,
        stepsCount,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;

export const useLayout = () => useContext(LayoutContext);

export const usePageNavigationHeader = (
  header: null | MobileHeaderConfig,
  deps: React.DependencyList = [],
) => {
  const { setMobileHeaderConfig } = useLayout();

  useEffect(() => {
    setMobileHeaderConfig(header);
  }, deps);
};
