import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AltArrowBack } from '@airhelp/icons';
import { Flex, IconButton, Box } from '@chakra-ui/react';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useFeatureFlags } from '@airhelp/plus/react';

import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';

import TopBanners from 'components/Layouts/Default/TopBanners';

const MobileHeader: React.FC = () => {
  const { data: featureFlags } = useFeatureFlags();
  const { userProfile } = useMyProfile();

  const { mobileHeaderConfig } = useLayout();
  const navigate = useNavigate();

  const showTopBannerEssentialUpgrade =
    Boolean(featureFlags?.essential_upgrade) &&
    Boolean(userProfile?.policy?.membership?.isEssential);

  const handleBackButtonClick = () => {
    const backRoute = mobileHeaderConfig?.backPath || PAGE_PATHS.HOME_PAGE;

    navigate(backRoute);
  };

  return (
    <>
      {showTopBannerEssentialUpgrade ? (
        <Box position="relative">
          <TopBanners />
        </Box>
      ) : null}
      <Flex
        as="header"
        minHeight="56px"
        position="relative"
        width="100%"
        px={4}
        zIndex="10"
        backgroundColor="greyscale.300"
        mt={
          showTopBannerEssentialUpgrade ? { base: '48px', md: '64px' } : 'unset'
        }
      >
        <Flex width="20%" alignItems="center" justifyContent="flex-start">
          <IconButton
            aria-label="back"
            icon={<AltArrowBack fontSize="xl" />}
            onClick={handleBackButtonClick}
            size="md"
            color="primary.500"
            borderRadius="50%"
            bg="greyscale.100"
            _active={{ bgColor: 'primary.200', boxShadow: 'none' }}
            _hover={{ bgColor: 'primary.200' }}
            data-testid="back-to"
          />
        </Flex>
        <Flex
          width="60%"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          color="primary.900"
          fontWeight="semibold"
        >
          {mobileHeaderConfig?.title}
        </Flex>
        {mobileHeaderConfig?.actionSpace ? (
          <Flex
            width="20%"
            alignItems="center"
            justifyContent="flex-end"
            mr={3}
          >
            {mobileHeaderConfig?.actionSpace}
          </Flex>
        ) : null}
      </Flex>
    </>
  );
};

export default MobileHeader;
