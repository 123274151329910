import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tag } from '@chakra-ui/react';

import config from 'config/config';
import { TranslationKey } from 'types/i18n';
import { FlightStatus } from '@airhelp/plus';

const { flightStatuses } = config;

const getBadgeBackgroundColor = (status: string): string => {
  if (status === 'canceled') {
    return 'danger.500';
  }

  if (status === 'delayed') {
    return 'warning.500';
  }

  return 'transparent';
};

const FlightStatusBadge = ({
  flightStatus,
}: {
  flightStatus?: FlightStatus;
}) => {
  const { t } = useTranslation();

  const flightStatusToShow =
    flightStatus?.departureStatus || flightStatus?.arrivalStatus;

  if (!(flightStatusToShow && flightStatuses[flightStatusToShow])) {
    return null;
  }

  const statusName = flightStatuses[flightStatusToShow];

  return (
    <Box>
      <Tag
        size="s"
        backgroundColor={getBadgeBackgroundColor(statusName)}
        data-testid={`${statusName}-badge`}
      >
        {t(
          `flights_list.flight_status.${flightStatuses[flightStatusToShow]}` as TranslationKey,
        )}
      </Tag>
    </Box>
  );
};

export default FlightStatusBadge;
