import React from 'react';

import {
  Drawer,
  Text,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react';

interface IComponent {
  onClose: () => void;
  isOpen: boolean;
  header?: string;
  content: React.ReactNode;
}

const DrawerComponent: React.FC<IComponent> = ({
  onClose,
  isOpen,
  header,
  content,
}) => {
  const placement: 'bottom' | 'right' =
    useBreakpointValue({ base: 'bottom', md: 'right' }) || 'bottom';

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      placement={placement}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay zIndex={100} />
      <DrawerContent
        m={{ base: 0, md: 4 }}
        borderTopRadius="3xl"
        borderBottomRadius={{ base: 0, md: '3xl' }}
        height={{ base: '98dvh', md: 'auto' }}
        top={{ base: 4, md: 8 }}
      >
        <DrawerBody
          sx={{
            overflowY: 'overlay',
            '::-webkit-scrollbar': {
              width: 2,
            },
            '::-webkit-scrollbar-track': {
              background: 'transparent',
              borderRadius: 'md',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'greyscale.400',
              borderRadius: '3xl',
              height: '100px',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: { md: 'greyscale.400' },
            },
            scrollbarWidth: 0,
          }}
          pl={{ base: 4, md: 8 }}
          pr={{ base: 2, md: 6 }}
          py={0}
          my={{ base: 4, md: 8 }}
        >
          <Flex justifyContent="space-between">
            <Text
              fontWeight="medium"
              fontSize={{ base: 'sm', md: 'md' }}
              mb={{ base: 4, md: 8 }}
            >
              {header || ''}
            </Text>
            <DrawerCloseButton
              borderRadius="sm"
              boxSize={6}
              backgroundColor="greyscale.300"
              sx={{
                svg: { boxSize: 2.5 },
                _hover: { backgroundColor: 'primary.200' },
                _active: {
                  backgroundColor: 'primary.800',
                  color: 'greyscale.200',
                },
              }}
              position="initial"
              data-testid="btn-drawer-close"
            />
          </Flex>
          {content}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerComponent;
