import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker, Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { subYears } from 'date-fns';

import config from 'config/config';
import { dateFnLocaleHelper } from 'contexts/LocaleContext/LocaleContextProvider';
import paymentsFieldsConfig from 'utils/airBundle/paymentsFieldsConfig';

import AddressFields from './AddressFields';
import BankFields from './BankFields';

const BankDetailsForm = ({
  form,
  countryCode,
  beneficiaryFullName,
}: {
  form: UseFormReturn;
  countryCode: string;
  beneficiaryFullName?: string;
}) => {
  const { t, i18n } = useTranslation();

  const { control } = form;
  const { maxPassengerAge, defaultPassengerAge } = config;

  const DEFAULT_MAX_LENGTH_BENEFICIARY_NAME = 50;

  const BENEFICIARY_NAME_MAX_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.beneficiaryName?.maxLength ||
    DEFAULT_MAX_LENGTH_BENEFICIARY_NAME;

  const adultBirthDate = subYears(new Date(), defaultPassengerAge);

  return (
    <>
      <Controller
        name="dateOfBirth"
        control={control}
        rules={{
          required: { value: true, message: t('errors.required') },
        }}
        defaultValue=""
        render={({
          field: { onChange, value },
          fieldState: { invalid, error },
        }) => (
          <Box width="100%" minHeight="106px" data-testid="dateOfBirth">
            <DatePicker
              invalid={invalid}
              errorMessage={error?.message || ''}
              label={t('form.date_of_birth')}
              value={value || null}
              onChange={onChange}
              openToDate={value ? value : adultBirthDate}
              dateFormat="dd/MM/yyyy"
              placeholder={t('form.date_placeholder')}
              locale={dateFnLocaleHelper(i18n?.language)}
              maxDate={new Date()}
              minDate={subYears(new Date(), maxPassengerAge)}
              isReadOnly
            />
          </Box>
        )}
      />
      <Controller
        name="beneficiaryName"
        control={control}
        defaultValue={beneficiaryFullName || ''}
        render={({
          field: { onChange, name, value },
          fieldState: { invalid, error },
        }) => (
          <Box width="100%" minHeight="106px">
            <Input
              isInvalid={invalid}
              errorMessage={error?.message || ''}
              name={name}
              label={t('common.beneficiary_name_full_name')}
              value={value}
              placeholder={t('common.beneficiary_name')}
              onChange={onChange}
              maxLength={BENEFICIARY_NAME_MAX_LENGTH}
              disabled
              data-testid="beneficiary-name"
            />
          </Box>
        )}
      />
      <AddressFields form={form} />
      <BankFields form={form} country={countryCode} />
    </>
  );
};

export default BankDetailsForm;
