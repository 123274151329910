import React from 'react';
import { Trans } from 'react-i18next';

import { Heading, Text } from '@chakra-ui/react';

import { Card } from 'elements';
import { type LoungePass } from 'models/LoungePass';

import PassDetails from './PassDetails';

interface IComponent {
  loungePasses: LoungePass[];
}

const PassesList: React.FC<IComponent> = ({ loungePasses }) => {
  return (
    <Card
      maxWidth={{ base: '100%', lg: '785px' }}
      px={{ base: 4, md: 6, xl: 8 }}
      pt={{ base: 5, md: 6, xl: 8 }}
      pb={{ base: 0, md: 3, lg: 5 }}
      data-testid="lounge-passes-container"
    >
      <Heading variant="h5" pb={{ base: 4, md: 3 }}>
        <Trans
          i18nKey="lounges.your_available_lounge_passes"
          values={{ count: loungePasses.length }}
          components={{
            strong: (
              <Text
                as="span"
                fontSize="inherit"
                fontWeight="bold"
                color="danger.500"
                display="inline-block"
              />
            ),
          }}
        />
      </Heading>
      {loungePasses.map((loungePass, key) => (
        <PassDetails
          loungePass={loungePass}
          ordinalNumber={key + 1}
          key={`lounge-pass-${loungePass.passCode}}-${loungePass.validUntil}`}
        />
      ))}
    </Card>
  );
};

export default PassesList;
