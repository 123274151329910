import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

const IFSC_CODE_MAX_LENGTH = 11;
const IFSC_CODE_MIN_LENGTH = 0;

const ifscCode = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  return (
    <Controller
      name="ifscCode"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: { value: /^\S+/, message: t('errors.required') },
        minLength: {
          value: IFSC_CODE_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.ifsc_code'),
            min: IFSC_CODE_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: IFSC_CODE_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.ifsc_code'),
            max: IFSC_CODE_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.ifsc_code')}
            value={value}
            placeholder={t('common.ifsc_code')}
            onChange={onChange}
            minLength={IFSC_CODE_MIN_LENGTH}
            maxLength={IFSC_CODE_MAX_LENGTH}
            data-testid="ifsc-code"
          />
        </Box>
      )}
    />
  );
};

export default ifscCode;
