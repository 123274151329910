import { useImperativeHandle } from 'react';

export interface ModalRef {
  openModal: () => void;
  closeModal: () => void;
}

export const useModalHandle = (ref, openModal, closeModal) => {
  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
  }));
};
