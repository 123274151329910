import { useTranslation } from 'react-i18next';
import { Box, VStack, Divider, Flex, Text } from '@chakra-ui/react';
import { PlanBoxHeader } from './PlanBoxHeader';
import { AnnualSupport } from './AnnualSupport';
import { TripProtection } from './TripProtection';
import { TravelPerks } from './TravelPerks';
import {
  CurrencyCode,
  formatValueToCurrency,
} from 'stores/preferences/currencies';

interface EssentialPlanProps {
  price: string;
  currency: CurrencyCode;
}

export function EssentialPlan({ price, currency }: EssentialPlanProps) {
  const { t } = useTranslation();

  const monthPrice = (Number(price) / 12).toFixed(2);

  return (
    <VStack
      border="1px solid #DEE5ED"
      borderRadius="lg"
      maxW="384px"
      gap={0}
      backgroundColor="greyscale.200"
    >
      <PlanBoxHeader
        planName="Essential"
        subheading={t('special_offer.your_plan')}
        version="primary"
      />
      <Box p="0 32px 32px 32px" w="100%">
        <Divider mb={2} />
        <TripProtection
          lostBagProtectionEnabled={false}
          loungeEnabled={false}
        />
        <AnnualSupport />
        <TravelPerks additionalSavingsEnabled={false} />
        <Flex
          w="100%"
          flexDirection="column"
          alignItems="center"
          mt={{
            base: '20px',
            md: '32px',
          }}
        >
          <Flex
            w="100%"
            h="48px"
            bg="primary.100"
            alignItems="center"
            borderRadius="md"
            justifyContent="center"
          >
            <Text color="primary.300" fontSize="small" fontWeight="500">
              {t('special_offer.subscribe_for', {
                price: formatValueToCurrency(price, currency),
              })}
            </Text>
          </Flex>
          <Text as="span" color="greyscale.600" fontSize="small" mt="4">
            {t('special_offer.price_per_month', {
              price: formatValueToCurrency(monthPrice, currency),
            })}
          </Text>
        </Flex>
      </Box>
    </VStack>
  );
}
