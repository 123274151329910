import { Expose, plainToClass, Type } from 'class-transformer';

import { getWebappClaimUrl, getWebappUrl } from 'utils/sites';

export interface IClaimEnquiryFlight {
  id: number;
  departureAirportCode: string;
  departureCity: string;
  arrivalAirportCode: string;
  arrivalCity: string;
  localDepartureDate: Date;
  disrupted: boolean;
  airline: string;
}

export type ClaimType = 'cancelled' | 'delayed' | 'overbooked';

export interface IClaimEnquiry {
  id: number;
  claimType: ClaimType;
  claimId: number;
  createdAt: string;
  uuid: string;
  flights: IClaimEnquiryFlight[];
}

// type for resource from /api/claims_and_flights
// defined type is not full, for all properties check the API response or webapp serializer
export interface IClaimEnquiryResponse {
  arrival: string;
  claimId: number;
  claimType: ClaimType;
  claimValid: boolean;
  compensationInCurrencies: Record<string, string>;
  createdAt: string;
  delay: string;
  delayMins: number;
  departure: string;
  id: number;
  itinerary: {
    flights: {
      id: number;
      departureAirport: {
        city: string;
        iata: string;
      };
      arrivalAirport: {
        city: string;
        iata: string;
      };
      localDepartureDate: Date;
      disrupted: boolean;
      marketingAirlineName: string;
    }[];
  };
  userPreferredCurrency: string;
  uuid: string;
}

// type for response from /api/claims_and_flights
export interface IClaimsAndFlightsResponse {
  claimEnquiries: IClaimEnquiryResponse[];
}

export class ClaimEnquiryFlight implements IClaimEnquiryFlight {
  @Expose() id: number;
  @Expose() departureAirportCode: string;
  @Expose() departureCity: string;
  @Expose() arrivalAirportCode: string;
  @Expose() arrivalCity: string;
  @Expose() localDepartureDate: Date;
  @Expose() disrupted: boolean;
  @Expose() airline: string;
}

export class ClaimEnquiry implements IClaimEnquiry {
  @Expose() id: number;
  @Expose() createdAt: string;
  @Expose() claimType: ClaimType;
  @Expose() claimId: number;
  @Expose() uuid: string;

  @Type(() => ClaimEnquiryFlight)
  @Expose()
  flights: ClaimEnquiryFlight[];

  @Expose()
  get isClaimed(): boolean {
    return this.claimId !== null;
  }

  @Expose()
  get claimOrEnquiryUrl(): string {
    return this.isClaimed
      ? `${getWebappClaimUrl()}${this.claimId}`
      : `${getWebappUrl()}/claim_enquiries/${this.uuid}`;
  }

  @Expose()
  get disruptedFlight(): ClaimEnquiryFlight {
    return this.flights.find((flight) => flight.disrupted) || this.flights[0];
  }
}

export function transformToClaimEnquiryClass(
  claim: IClaimEnquiry,
): ClaimEnquiry {
  return plainToClass(ClaimEnquiry, claim);
}

export function sortByCreatedAt(claims: ClaimEnquiry[]): ClaimEnquiry[] {
  return [...claims].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
}
