import React from 'react';
import { useTranslation } from 'react-i18next';

import { Check } from '@airhelp/icons';
import { Heading } from '@chakra-ui/react';

import PaymentWrapper from '../PaymentWrapper';

const PaymentSuccess = () => {
  const { t } = useTranslation();

  return (
    <PaymentWrapper dataTestId="payment-success">
      <Check w={7} h="auto" color="primary.500" />
      <Heading variant="h4" mt={10}>
        {t('payment.payment_confirmed')}
      </Heading>
    </PaymentWrapper>
  );
};

export default PaymentSuccess;
