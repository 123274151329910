import React from 'react';
import { useTranslation } from 'react-i18next';

import { shadowsMd } from '@airhelp/design-tokens/dist/js/design-tokens';
import { Box, Heading, Text } from '@chakra-ui/react';

import { type AirportLounge } from 'models/AirportLounge';
import { type TranslationKey } from 'types/i18n';

import Carousel from './Carousel';
import Section from './Section';

interface IComponent {
  lounge: AirportLounge;
}

interface ISection {
  tKey: TranslationKey;
  content: string;
  key: string;
}

const LoungeDetails: React.FC<IComponent> = ({ lounge }) => {
  const { t } = useTranslation();

  const terminalFullName = `${lounge.airportName} (${lounge.airportCode}), ${lounge.terminal}`;

  const sections: ISection[] = [
    {
      tKey: 'lounge_finder.en_opening_hours',
      content: lounge.openingHours,
      key: 'lounge_finder_opening_hours',
    },
    {
      tKey: 'lounge_finder.en_location',
      content: lounge.location,
      key: 'lounge_finder_location',
    },
    {
      tKey: 'lounge_finder.en_conditions',
      content: lounge.conditions,
      key: 'lounge_finder_conditions',
    },
    {
      tKey: 'lounge_finder.en_additional_info',
      content: lounge.additional,
      key: 'lounge_finder_additional_info',
    },
  ];

  return (
    <Box
      w={{ base: '100%', lg: '670px' }}
      py={{ base: 4, sm: 6, md: 10 }}
      px={{ base: 0, sm: 4, md: 10 }}
      m="0 auto"
      bg={{ base: 'none', sm: 'greyscale.100' }}
      borderRadius={{ base: 'none', sm: 'xl' }}
      boxShadow={{ base: 'none', sm: shadowsMd }}
    >
      <Heading variant="h2" mb={2}>
        {lounge.loungeName}
      </Heading>

      <Text mb={10}>{terminalFullName}</Text>

      {lounge.images.length > 0 && (
        <Box mb={20}>
          <Carousel images={lounge.images} />
        </Box>
      )}

      <Box>
        {sections.map(
          (section) =>
            section.content && (
              <Section
                title={t(section.tKey)}
                content={section.content}
                key={section.key}
              />
            ),
        )}
      </Box>
    </Box>
  );
};

export default LoungeDetails;
