import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Delete, ArrowLeft } from '@airhelp/icons';
import { Airhelp, AirhelpPlus } from '@airhelp/logos';
import {
  Box,
  Grid,
  GridItem,
  Image,
  Button,
  VStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import brandingSignBackground from 'assets/images/branding-sign.svg';
import loungeKeyLogoColor from 'assets/images/lounges/lounge-key-logo-color.svg';
import loungeKeyLogoWhite from 'assets/images/lounges/lounge-key-logo-white.svg';
import { PAGE_PATHS } from 'config/routes';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

import Footer from './Footer';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { userProfile } = useMyProfile();
  const policy = userProfile?.policy;
  const withBackToDashboard = Boolean(userProfile);

  const onBackToLounges = () => {
    const canEnterToLounges = Boolean(
      policy?.membership?.active || policy?.trip?.isComplete,
    );

    const loungesBackPath = searchParams.get('lounge-passes-back')
      ? PAGE_PATHS.LOUNGES.PASSES_PAGE
      : PAGE_PATHS.LOUNGES_PAGE;

    navigate({
      pathname: generatePath(
        canEnterToLounges ? loungesBackPath : PAGE_PATHS.HOME_PAGE,
      ),
    });
  };

  const backToDashboardButton = useBreakpointValue({
    base: (
      <Button
        size="xs"
        background="greyscale.300"
        leftIcon={<Delete boxSize={5} color="primary.900" />}
        p={1}
        iconSpacing={0}
        _active={{
          background: 'greyscale.200',
        }}
        onClick={onBackToLounges}
        data-testid="go-to-dashboard-button"
      />
    ),
    lg: (
      <Button
        variant="secondary"
        size="xs"
        mb={5}
        alignSelf="flex-start"
        leftIcon={<ArrowLeft />}
        iconSpacing={3}
        onClick={onBackToLounges}
        border="none"
        data-testid="go-to-dashboard-button"
      >
        {t('common.back_to_dashboard')}
      </Button>
    ),
  });

  return (
    <Grid height="100%" gridTemplateRows="1fr auto">
      <GridItem
        position="relative"
        backgroundColor={{ base: 'greyscale.100', sm: 'greyscale.300' }}
        mb={4}
      >
        <Image
          w={{ base: '100%', md: '717px' }}
          h={{ base: '240px', md: 'calc(100% - 270px)' }}
          maxH="756px"
          src={brandingSignBackground}
          alt=""
          objectPosition="center top"
          objectFit="cover"
          position="absolute"
          bottom="0"
          left="-138px"
          zIndex="0"
        />
        <VStack
          position="absolute"
          left="64px"
          bottom="48px"
          spacing={3}
          display={{ base: 'none', md: 'block' }}
          pt={4}
        >
          <Text fontSize="sm" color="primary.200" alignSelf="flex-start">
            {t('lounge_finder.en_provided_by')}
          </Text>
          <Image src={loungeKeyLogoWhite} w="auto" h="auto" alt="LoungeKey" />
        </VStack>

        <Box mb={32} zIndex="1" position="relative">
          <Grid
            templateColumns={{
              base: '1fr auto 1fr',
              md: 'auto auto',
              lg: 'auto',
            }}
            px={{ base: 4, sm: 4, md: 16 }}
            py={{ base: 4, sm: 4, md: 8 }}
            borderBottom={{ base: '1px solid #DEE8F0', lg: 'none' }}
            flexDirection={{ base: 'row', lg: 'column' }}
            data-testid="layout-header"
            alignItems="center"
          >
            {withBackToDashboard ? (
              <GridItem>{backToDashboardButton}</GridItem>
            ) : null}
            {policy?.coverage?.active ? (
              <AirhelpPlus
                data-testid="airhelp-logo"
                width={{ base: '110px', lg: '150px' }}
                height="auto"
                mt={2}
                justifySelf={{
                  base: 'center',
                  md: 'flex-end',
                  lg: 'flex-start',
                }}
                alignSelf={{
                  base: 'center',
                  md: 'center',
                  lg: 'flex-start',
                }}
              />
            ) : (
              <Airhelp
                data-testid="airhelp-logo"
                width={{ base: '94px', lg: '150px' }}
                height="auto"
                mt={0.5}
                justifySelf={{
                  base: 'center',
                  md: 'flex-end',
                  lg: 'flex-start',
                }}
                alignSelf={{
                  base: 'center',
                  md: 'center',
                  lg: 'flex-start',
                }}
              />
            )}
            <VStack
              spacing={3}
              display={{ base: 'block', md: 'none' }}
              justifySelf="flex-end"
            >
              <Text fontSize="2xs" color="greyscale.600" textAlign="right">
                {t('lounge_finder.en_provided_by')}
              </Text>
              <Image
                src={loungeKeyLogoColor}
                h="28px"
                alt="LoungeKey"
                color="primary.700"
              />
            </VStack>
          </Grid>
          <Box data-testid="layout-content">{children}</Box>
        </Box>
      </GridItem>
      <GridItem>
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default Layout;
