import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { AirLuggageJourney, AirLuggagePaymentPax } from '@airhelp/plus';

import UserCard from 'components/Journey/AirBundle/UserCard';
import {
  type Action,
  AirLuggagePaymentTypes,
} from 'reducers/airLuggagePaymentReducer/airLuggagePaymentReducer';

import DetailsForm from '../DetailsForm';
import useGetAirLuggageStatusBadge from '../getAirLuggageStatusBadge';

interface IComponent {
  airLuggageJourney: AirLuggageJourney;
  dispatch: React.Dispatch<Action>;
  reducerDataById: Record<number, AirLuggagePaymentPax>;
  paxFilled: boolean;
  setIsValidDataDetailsForm: (value: boolean) => void;
}

const LuggageDetails: React.FC<IComponent> = ({
  airLuggageJourney,
  dispatch,
  reducerDataById,
  paxFilled,
  setIsValidDataDetailsForm,
}) => {
  const form = useForm({ mode: 'onSubmit' });
  const { handleSubmit } = form;

  const { payoutAllowed, status, id, maxBags } = airLuggageJourney;
  const { isOpen, onToggle, onClose } = useDisclosure({
    defaultIsOpen: !paxFilled,
  });
  const statusBox = useGetAirLuggageStatusBadge(status);
  const oneMaxBag = maxBags === 1;

  const handleAddPax = (data) => {
    const { numberOfLuggages, payoutEligibleItineraryId } = data;
    dispatch({
      type: AirLuggagePaymentTypes.EditPassenger,
      payload: {
        ...data,
        id,
        numberOfLuggages: oneMaxBag ? 1 : numberOfLuggages.value,
        payoutEligibleItineraryId: payoutEligibleItineraryId.value,
      },
    });
    onClose();
    setIsValidDataDetailsForm(true);
  };

  if (!payoutAllowed) {
    return (
      <Box mt={4}>
        <UserCard
          airBundleJourney={airLuggageJourney}
          payoutAllowed={!!payoutAllowed}
          statusBox={statusBox}
        />
      </Box>
    );
  }

  return (
    <Flex
      flexDirection="column"
      border={isOpen ? '1px solid' : 'none'}
      borderColor="primary.500"
      borderRadius="xl"
      mt={4}
      data-testid="luggage-details-container"
    >
      <UserCard
        airBundleJourney={airLuggageJourney}
        payoutAllowed={payoutAllowed}
        isOpen={isOpen}
        handleEdit={onToggle}
        statusBox={statusBox}
        canEdit
      />
      <Collapse in={isOpen} animateOpacity key={airLuggageJourney.id}>
        <DetailsForm
          form={form}
          airLuggageJourney={airLuggageJourney}
          reducerDataById={reducerDataById}
          handleAddPax={handleSubmit(handleAddPax)}
          setIsValidDataDetailsForm={setIsValidDataDetailsForm}
          canEdit
        />
      </Collapse>
    </Flex>
  );
};

export default LuggageDetails;
