import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Collapsible } from '@airhelp/react';
import {
  Divider,
  HStack,
  Image,
  Spacer,
  Heading,
  VStack,
  FlexProps,
} from '@chakra-ui/react';

import faqFormal from 'assets/images/faq-formal@2x.png';
import { Card } from 'elements';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { TranslationKey } from 'types/i18n';

export interface IFaq {
  id: string;
  question: TranslationKey;
  answer: TranslationKey;
  value?: number;
}

type IComponent = {
  onToggle: (isToggle: boolean) => void;
} & FlexProps;

const FAQs: React.FC<IComponent> = ({ onToggle, ...props }) => {
  const { t } = useTranslation();

  const { userProfile } = useMyProfile();

  const benefits = userProfile?.policy.benefits;

  const loungesCommunicatedDepartureTreshold = benefits?.loungeAccessEnabled
    ? benefits?.loungeAccessCommunicatedDepartureThresholdHours
    : benefits?.journeyLoungeAccessCommunicatedDepartureThresholdHours;

  const FAQsList: IFaq[] = [
    {
      id: 'how_do_i_get_lounge_access',
      question: 'lounges.faqs.how_do_i_get_lounge_access.question',
      answer: 'lounges.faqs.how_do_i_get_lounge_access.answer',
    },
    {
      id: 'add_trip_time_threshold',
      question: 'lounges.faqs.add_trip_time_threshold.question',
      answer: 'lounges.faqs.add_trip_time_threshold.answer',
      value: loungesCommunicatedDepartureTreshold,
    },
    {
      id: 'use_with_a_fellow_passenger',
      question: 'lounges.faqs.use_with_a_fellow_passenger.question',
      answer: 'lounges.faqs.use_with_a_fellow_passenger.answer',
    },
    {
      id: 'use_more_than_once',
      question: 'lounges.faqs.use_more_than_once.question',
      answer: 'lounges.faqs.use_more_than_once.answer',
    },
    {
      id: 'dont_use_a_pass',
      question: 'lounges.faqs.dont_use_a_pass.question',
      answer: 'lounges.faqs.dont_use_a_pass.answer',
    },
    {
      id: 'lounge_not_in_a_lounge_finder',
      question: 'lounges.faqs.lounge_not_in_a_lounge_finder.question',
      answer: 'lounges.faqs.lounge_not_in_a_lounge_finder.answer',
    },
  ];

  return (
    <Card
      py={{ base: 1, md: 6 }}
      px={{ base: 5, md: 10 }}
      data-testid="lounges-faqs"
      {...props}
    >
      <HStack fontSize="md" fontWeight="medium" my={4}>
        <Heading variant="h5" mx={4}>
          {t('lounges.faqs.header')}
        </Heading>
        <Spacer />
        <Image
          src={faqFormal}
          height="48px"
          mr={4}
          display={{ base: 'none', md: 'block' }}
        />
      </HStack>
      <VStack
        mb={4}
        mt={{ base: 1, md: 0 }}
        spacing={0}
        alignItems="flex-start"
        w="100%"
      >
        {FAQsList.map((item) => (
          <VStack
            key={`faqs-${item.id}`}
            w="100%"
            spacing={{ base: 0, md: 2 }}
            alignItems="flex-start"
            mx={{ base: -4, md: 0 }}
          >
            <Collapsible title={t(item.question)} onToggle={onToggle}>
              {item.value ? (
                <Trans
                  i18nKey={item.answer as unknown as TranslationKey[]}
                  values={{ treshold: item.value }}
                />
              ) : (
                t(item.answer)
              )}
            </Collapsible>
            <Divider ml={4} w="calc(100% - 32px)" />
          </VStack>
        ))}
      </VStack>
    </Card>
  );
};

export default FAQs;
