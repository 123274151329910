import { useEffect, type EffectCallback, useRef } from 'react';

const useEffectOnce = (callback: EffectCallback) => {
  const didRunEffect = useRef(false);

  return useEffect(() => {
    if (!didRunEffect.current) {
      didRunEffect.current = true;

      callback();
    }
  });
};

export default useEffectOnce;
