import { get, post, patch, AHPLUS_API_URL } from 'api/ky';
import { type CurrencyCode } from 'stores/preferences/currencies';
import { type PRODUCTS_NAME } from 'types/products';

export const fetchStripeClientSecret = async ({
  email,
  productName,
  currency,
  recurring,
  channel,
  locale,
  tripUuid,
  upgrade = false,
}: {
  email: string;
  productName?: PRODUCTS_NAME;
  currency: CurrencyCode;
  recurring: boolean;
  channel: string;
  locale: string;
  tripUuid?: string;
  upgrade?: boolean;
}) => {
  return await post(`${AHPLUS_API_URL}/api/v2/stripe_checkouts`, {
    email,
    productName,
    currency,
    recurring,
    channel,
    locale,
    upgrade,
    tripUuid,
  });
};

export const changeCurrency = async (
  currency: CurrencyCode,
  intentId: string | null,
  upgrade = false,
) => {
  return await patch(`${AHPLUS_API_URL}/api/v2/stripe_checkouts/${intentId}`, {
    currency,
    upgrade,
  });
};

export const getStripePaymentStatus = async (intentId: string) => {
  return await get(`${AHPLUS_API_URL}/api/v2/stripe_checkouts/${intentId}`);
};

export const getStripeOrder = async (intentId: string) => {
  return await get(
    `${AHPLUS_API_URL}/api/v2/payments_confirmations/${intentId}`,
  );
};
