import React from 'react';

import { Flex } from '@chakra-ui/react';

const PassNumeration: React.FC<{ number: number }> = ({ number }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position={{ base: 'absolute', md: 'unset' }}
      top={{ base: '194px', md: 'unset' }}
      right={{ base: 6, md: 'unset' }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        fontSize={{ base: 'sm', md: 'md' }}
        fontWeight="medium"
        borderRadius="50%"
        backgroundColor="danger.500"
        color="greyscale.100"
        width={{ base: 7, md: 8 }}
        height={{ base: 7, md: 8 }}
      >
        {number}
      </Flex>
    </Flex>
  );
};

export default PassNumeration;
