import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Autocomplete } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction, type i18n } from 'i18next';

import { getCountriesPayoutSelectOptions } from 'utils/airBundle/airBundle';

const nationality = ({
  form,
  t,
  i18n,
}: {
  form: UseFormReturn;
  t: TFunction;
  i18n: i18n;
}) => {
  const nationalityOptions = getCountriesPayoutSelectOptions(i18n.language);

  return (
    <Controller
      name="nationality"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
      }}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Autocomplete
            name={name}
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            label={t('common.nationality')}
            placeholder={t('common.nationality')}
            value={value}
            isSearchable
            options={nationalityOptions}
            handleChange={onChange}
            dataTestId="nationality"
          />
        </Box>
      )}
    />
  );
};

export default nationality;
