import { get, put, patch, WEBAPP_API_URL, webappGateway, del } from 'api/ky';
import { setupCsrfToken } from 'api/ky/csrf';
import { type IUserUpdateData } from 'models/User';

export const SESSION_URL = `${WEBAPP_API_URL}/api/users/me`;
export const CREATE_SESSION_URL = `${WEBAPP_API_URL}/users/sign_in`;
export const DESTROY_SESSION_URL = `${WEBAPP_API_URL}/api/session`;
export const SET_PASSWORD_URL = `${WEBAPP_API_URL}/users/password`;
export const UPDATE_DATA_URL = `${WEBAPP_API_URL}/api/users/update_data`;
export const ACCEPT_MARKETING_CONSENT = `${WEBAPP_API_URL}/api/users/accept_marketing_consent`;

export const fetchSession = async (authRetryEnabled = true) => {
  return await get(SESSION_URL, {
    options: {
      hooks: {
        afterResponse: [
          async (_request, _options, response) => {
            if (response.headers.get('X-Csrf-token')) {
              setupCsrfToken(response.headers.get('X-Csrf-token'), 'webapp');
            }
            return response;
          },
        ],
      },
    },
    authRetryEnabled,
    kyInstance: webappGateway,
  });
};

export const destroySession = async () => {
  return await del(DESTROY_SESSION_URL, { kyInstance: webappGateway });
};

export const setPassword = async (passwordData) => {
  return await put(
    SET_PASSWORD_URL,
    { user: passwordData },
    { kyInstance: webappGateway },
  );
};

export const updateData = async (userData: IUserUpdateData) => {
  return await put(
    UPDATE_DATA_URL,
    { user: userData },
    { kyInstance: webappGateway },
  );
};

export const acceptMarketingConsent = async () => {
  return await patch(
    ACCEPT_MARKETING_CONSENT,
    {},
    { kyInstance: webappGateway },
  );
};
