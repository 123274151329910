import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const StripePaymentHeader = ({
  showAddressForm,
}: {
  showAddressForm?: boolean;
}) => {
  const { t } = useTranslation();

  const getStep = showAddressForm ? 2 : 1;

  return (
    <Box mb={{ base: 4, md: 8 }} px={{ base: -4, md: -8 }}>
      <Text fontSize="sm" color="greyscale.600" textAlign="center">
        {t('common.step')} {getStep} {t('common.of')} 2
      </Text>
      <Text fontWeight="500" fontSize="md" textAlign="center">
        {showAddressForm
          ? t('payment_management.billing_address')
          : t('payment_management.choose_payment_method')}
      </Text>
      <Box
        ml="-32px"
        width={{ base: 'calc(100% + 40px)', md: 'calc(100% + 56px)' }}
        my={4}
      >
        <MotionBox
          height={1}
          width={showAddressForm ? '100%' : '50%'}
          bg={`linear-gradient(89deg, #0D7CFF ${showAddressForm ? '0%' : '50%'}, #8C62FC ${showAddressForm ? '50%' : '100%'}, #FF6B73 100%)`}
          animate={{
            width: showAddressForm ? '100%' : '50%',
            background: `linear-gradient(89deg, #0D7CFF ${showAddressForm ? '0%' : '50%'}, #8C62FC ${showAddressForm ? '50%' : '100%'}, #FF6B73 100%)`,
          }}
          transition={{ duration: 0.5 }}
        />
      </Box>
    </Box>
  );
};

export default StripePaymentHeader;
