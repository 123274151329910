import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@chakra-ui/react';

import airLuggage from 'assets/images/air-bundle/airLuggage.png';
import InfoModal from 'components/Journey/AirBundle/InfoModal';

import HeaderContent from '../HeaderContent';
import { AirLuggageJourney } from '@airhelp/plus';

const Header = ({
  airLuggageJourney,
  linkToFile,
}: {
  airLuggageJourney: AirLuggageJourney;
  linkToFile: string;
}) => {
  const { t } = useTranslation();

  const { payoutAmount, maxBags } = airLuggageJourney;

  const airLuggagePayout = payoutAmount.toString();
  const airLuggageDescriptionKey = maxBags === 1 ? 'subtitle' : 'title';

  const airLuggageModalContent = () => {
    const airLuggageBenefitsKey =
      maxBags === 1 ? 'extra_money' : 'if_your_checked_in_luggage';
    return [
      {
        title: t('air_luggage.title'),
        description: t(`air_luggage.modal.main.${airLuggageDescriptionKey}`, {
          amount: airLuggagePayout,
          bags: maxBags,
        }),
        benefits: [
          t(`air_luggage.modal.main.${airLuggageBenefitsKey}`, {
            amount: airLuggagePayout,
            bags: maxBags,
          }),
          t('air_luggage.modal.main.no_proof'),
        ],
      },
      {
        title: t('air_payout.modal.main.process'),
        benefits: [
          t('air_luggage.modal.main.if_your_checked_in_bag'),
          t('air_luggage.modal.main.submit_the_pir'),
        ],
        infoBoxText: t('air_luggage.modal.main.do_not_leave_the_luggage'),
      },
    ];
  };

  const readMoreModal = (
    <InfoModal
      modalContent={airLuggageModalContent()}
      image={airLuggage}
      render={(openModalHandler) => (
        <Link
          fontSize="md"
          fontWeight="normal"
          color="primary.500"
          onClick={openModalHandler}
          _visited={{ color: 'greyscale.100' }}
          _focus={{ boxShadow: 'none' }}
          data-testid="air-luggage-read-more-button"
        >
          {t('common.more')}
        </Link>
      )}
    />
  );

  return (
    <HeaderContent
      readMoreModal={readMoreModal}
      description={t(`air_luggage.modal.main.${airLuggageDescriptionKey}`, {
        amount: airLuggagePayout,
        bags: maxBags,
      })}
      linkToFile={linkToFile}
      title={t('air_luggage.title')}
    />
  );
};

export default Header;
