import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  NavLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useTracking } from 'react-tracking';

import { AuthFormOAuth, AuthSignupForm } from '@airhelp/funnel';
import { Link, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { SSO_PROVIDER, oAuthLink } from 'api/auth/auth';
import membershipActivationImage from 'assets/images/membership-activation@1x.jpg';
import { TwoColumnsImageLayout } from 'components/Layouts';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import {
  marketingConsentLabel,
  privacyConsentLabel,
} from 'elements/UserConsentsForm/common';
import { prefetchMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type ISignupSubmitData } from 'models/User';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useSignUp } from '@airhelp/session';

const EmailView: React.FC = () => {
  const queryClient = useQueryClient();
  const { setLoginData } = useContext(TrackingContext);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();
  const [apiError, setApiError] = useState<string | null>();

  const { mutate: authSignUp, isPending } = useSignUp({
    onError: (error) => {
      const errorMessage = handleErrorMessage(error);
      setApiError(errorMessage);
    },
    onSuccess: async () => {
      const userProfile = await prefetchMyProfile(queryClient);

      if (userProfile) {
        setLoginData({ method: 'email', user: userProfile });
      }

      navigate({
        pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY_PAGE),
        search: searchParams.toString(),
      });
    },
  });

  const handleErrorMessage = (errorResponse) => {
    const errorBody = errorResponse?.response?.json();
    let errorMessage;

    if (errorResponse?.response?.status === 409) {
      errorMessage = t('common.account_already_exist');
    } else if (errorBody?.errors) {
      const errors = errorBody.errors;
      const arrayMessages = Object.keys(errors).map((key) => {
        return `${key} ${errors[key]}`;
      });

      errorMessage =
        arrayMessages.join(', ') || t('errors.something_went_wrong');
    } else {
      errorMessage = errorBody?.error || t('errors.something_went_wrong');
    }

    return errorMessage;
  };

  const onSubmit = async (data: ISignupSubmitData) => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_membership_buy_email_continue_clicked',
    });

    const { email, password, privacyConsent, marketingConsent } = data;

    authSignUp({
      user: { email, password, privacyConsent, marketingConsent },
    });
  };

  return (
    <TwoColumnsImageLayout imageSrc={membershipActivationImage}>
      <AuthSignupForm
        isFullWidth
        withPassword
        headersAlign="left"
        formError={apiError ? apiError : undefined}
        i18n={{
          title: t('common.get_airhelp_plus'),
          subtitle: t('memberships.we_will_send_confirmation_to_this_email'),
          actionText: t('authentication.create_an_account'),
          confirmPasswordPlaceholder: t('authentication.password_confirmation'),
          divider: t('authentication.create_an_account_via'),
          emailPlaceholder: t('common.email'),
          passwordPlaceholder: t('authentication.password'),
          marketingConsentLabel: marketingConsentLabel(),
          privacyConsentLabel: privacyConsentLabel(),
          selectAllLabel: t('common.select_all'),
          signinCallout: (
            <Text as="span" fontSize="sm" color="greyscale.600">
              {t('authentication.already_member')}
              <Link
                as={NavLink}
                to={generatePath(PAGE_PATHS.LOGIN_PAGE)}
                onClick={() => {
                  trackCtaClick('login', 'login page');
                }}
                pl={1}
                fontSize="sm"
                fontWeight="normal"
                color="primary.500"
                data-testid="already_member"
              >
                <Text as="span" fontSize="sm" color="primary.500">
                  {t('authentication.login_form.log_in_here')}
                </Text>
              </Link>
            </Text>
          ),
        }}
        oAuth={
          <AuthFormOAuth
            facebookLink={oAuthLink(SSO_PROVIDER.FACEBOOK)}
            onFacebookClick={() => {
              trackCtaClick('facebook sso', 'register via facebook sso');
            }}
            googleLink={oAuthLink(SSO_PROVIDER.GOOGLE)}
            onGoogleClick={() => {
              trackCtaClick('google sso', 'register via google sso');
            }}
            microsoftLink={oAuthLink(SSO_PROVIDER.MICROSOFT)}
            onMicrosoftClick={() => {
              trackCtaClick('microsoft sso', 'register via microsoft sso');
            }}
          />
        }
        onSubmit={(data) => {
          trackCtaClick('email', 'email checkout page');

          onSubmit(data);
        }}
        isSubmitting={isPending}
      />
    </TwoColumnsImageLayout>
  );
};

export default EmailView;
