import React from 'react';

import { type IconType } from '@airhelp/icons';
import { Center, Text, VStack } from '@chakra-ui/react';

interface IComponent {
  primarylabel: string;
  secondaryLabel: string;
  Icon: IconType;
}

const ClaimDetailsBox: React.FC<IComponent> = ({
  primarylabel,
  secondaryLabel,
  Icon,
}) => {
  return (
    <VStack
      spacing={3}
      bgColor="primary.100"
      align="start"
      flex="1 1"
      p={3}
      borderRadius="lg"
    >
      <Center boxSize={6} borderRadius="sm" bgColor="greyscale.100">
        <Icon as={Icon} color="primary.500" boxSize="16px !important" />
      </Center>
      <VStack spacing={0} align="start">
        <Text
          fontSize="sm"
          noOfLines={1}
          color="greyscale.600"
          width={{ base: 'calc(50vw - 84px)', sm: 'unset' }}
        >
          {primarylabel}
        </Text>
        <Text
          fontSize="sm"
          noOfLines={1}
          color="primary.900"
          fontWeight="semibold"
        >
          {secondaryLabel}
        </Text>
      </VStack>
    </VStack>
  );
};

export default ClaimDetailsBox;
