import { type TDispatcher } from './dispatcher';

/* eslint-disable @typescript-eslint/no-explicit-any */
type TDataLayer = TDispatcher & {
  dataLayer: any;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const DataLayer: TDataLayer = {
  track(name: string, props: object = {}) {
    DataLayer.dataLayer?.push({ event: name, ...props });
  },

  /* eslint-disable @typescript-eslint/no-explicit-any */
  get dataLayer(): any {
    return (window as any).dataLayer;
  },
  /* eslint-enable @typescript-eslint/no-explicit-any */
};
