import { Expose, plainToClass } from 'class-transformer';

import { PRODUCTS_NAME } from 'types/products';
import { TransformStringToEnum } from './transformers';

export enum PRODUCT_PERKS_NAME {
  ESIM = 'Esim',
  AIR_PAYOUT = 'AirPayout',
  AIR_LUGGAGE = 'AirLuggage',
  AIRPORT_TRANSFER = 'AirportTransfer',
  LOUNGE_ACCESS = 'LoungeAccess',
  CAR_RENTAL = 'CarRental',
  FLIGHT_NOTIFICATION = 'FlightNotification',
  JOURNEY_LOUNGE_ACCESS = 'JourneyLoungeAccess',
}

export interface IOffer {
  id: number | null;
  currency?: string | null;
  name: string | PRODUCTS_NAME;
  displayName?: string;
  paymentFrequency?: string | null;
  prices: Record<Lowercase<string>, IPrice>;
  productPerks: IProductPerk[] | null;
}

export interface IPrice {
  code: string;
  symbol?: string;
  label?: string;
  price: number;
  discountedPrice?: number | null;
}

export interface IOffersData {
  canBuyPlus: boolean;
  offers: IOffer[];
  renewOffers: IOffer[];
  upgradeOffers: IOffer[];
}

export interface IProductPerkBase {
  credits: number;
  unlimited: boolean;
  type: string | PRODUCT_PERKS_NAME;
}

export interface IAirLuggagePerk extends IProductPerkBase {
  config: {
    maxBags: number;
    payout: number;
    ipidVersion: string;
  };
}

export interface IAirPayoutPerk extends IProductPerkBase {
  config: {
    payout: number;
    ipidVersion: string;
  };
}

export interface IEsimPerk extends IProductPerkBase {
  config: {
    discount: number;
  };
}

export interface ILoungeAccessPerk extends IProductPerkBase {
  config: {
    fellowsEnabled: boolean;
  };
}

export interface IProductPerk {
  config: object;
  credits: number;
  unlimited: boolean;
  type: PRODUCT_PERKS_NAME | string;
}

const getPerkByType = (
  productPerks: IProductPerk[],
  type: PRODUCT_PERKS_NAME | string,
) => {
  if (productPerks) {
    return productPerks.find((perk) => perk.type === type) || null;
  }
  return null;
};

export class Offer implements IOffer {
  @Expose() id: number | null;
  @TransformStringToEnum(PRODUCTS_NAME) @Expose() name: PRODUCTS_NAME;
  @Expose() displayName?: string;
  @Expose() paymentFrequency?: string | null;
  @Expose() prices: Record<Uppercase<string>, IPrice>;
  @Expose() productPerks: IProductPerk[] | [];

  @Expose()
  get esimPerk(): IEsimPerk | null {
    return getPerkByType(
      this.productPerks,
      PRODUCT_PERKS_NAME.ESIM,
    ) as IEsimPerk;
  }

  @Expose()
  get airPayoutPerk(): IAirPayoutPerk | null {
    return getPerkByType(
      this.productPerks,
      PRODUCT_PERKS_NAME.AIR_PAYOUT,
    ) as IAirPayoutPerk;
  }

  @Expose()
  get airLuggagePerk(): IAirLuggagePerk | null {
    return getPerkByType(
      this.productPerks,
      PRODUCT_PERKS_NAME.AIR_LUGGAGE,
    ) as IAirLuggagePerk;
  }

  @Expose()
  get loungeAccessPerk(): ILoungeAccessPerk | null {
    return getPerkByType(
      this.productPerks,
      PRODUCT_PERKS_NAME.AIR_LUGGAGE,
    ) as ILoungeAccessPerk;
  }
}

export class Offers {
  @Expose() offers: Record<string, Offer>;
  @Expose() renewOffer: Offer | undefined;
  @Expose() upgradeOffer: Offer | undefined;
}

export function transformToOfferClass(offers: IOffer): Offer {
  return plainToClass(Offer, offers);
}
