import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';

import { GOODWILL_FUNNEL_CHANNEL } from 'config/channels';
import { PAGE_PATHS } from 'config/routes';
import ContactLink from 'elements/ContactLink/ContactLink';
import type UserProfile from 'models/UserProfile';
import chakraStyles from 'utils/styles/chakra';
import { getWebappClaimUrl, getWebappUrl } from 'utils/sites';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

const Side: React.FC<{
  channel?: string;
  claimId?: string;
  claimEnquiryUuid?: string;
  userProfile: UserProfile;
}> = ({ channel, claimId, claimEnquiryUuid, userProfile }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { reloadProfile } = useMyProfile();

  const dashboardVisited = userProfile?.policy.dashboard?.dashboardVisited;

  const isUserArrivedFromGoodwill =
    claimEnquiryUuid && channel === GOODWILL_FUNNEL_CHANNEL;
  const isUserArrivedFromClaimSubmissionFunnel = Boolean(claimId);

  const goToDashboard = async () => {
    await reloadProfile();
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_membership_buy_summary_go_to_dashboard_clicked',
    });
    navigate(generatePath(PAGE_PATHS.HOME_PAGE));
  };

  const goToClaim = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_membership_buy_summary_go_to_claim_clicked',
    });
  };

  const backToClaimFunnel = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel:
        'ahplus_membership_buy_summary_go_back_to_claim_funnel_clicked',
    });
  };

  const getDescription = () => {
    if (dashboardVisited) {
      return t(
        'payment.succeeded.start_using_airhelp_plus_air_payout_essential',
      );
    }
    return t(`payment.succeeded.membership_description_with_air_payout`);
  };

  const getHeader = () => {
    if (dashboardVisited) {
      return t('payment.succeeded.membership_title');
    }
    return t('common.airhelp_member');
  };

  return (
    <Box>
      <Heading variant="h1">{getHeader()}</Heading>
      <Text fontSize="lg" fontWeight="medium" mt={3}>
        <ContactLink
          tKey="payment.succeeded.you_will_receive_confirmation_email"
          fontSize="lg"
          fontWeight="medium"
        />
      </Text>
      <Heading variant="h4" mt={10} mb={2}>
        {t('common.get_started')}
      </Heading>
      <Text fontSize="lg">{getDescription()}</Text>
      <Heading variant="h4" mt={10} mb={2}>
        {t('common.flying_somewhere')}
      </Heading>
      <Text fontSize="lg">
        {' '}
        {t('insurance.upsell.upcoming_flights_reminder')}
      </Text>
      <Flex mt={8}>
        {isUserArrivedFromGoodwill ? (
          <Button
            as={Link}
            href={`${getWebappUrl()}/claim_enquiries/${claimEnquiryUuid}`}
            size="m"
            onClick={backToClaimFunnel}
            width={{ base: '100%', lg: 'initial' }}
            data-testid="goto-claim-funnel"
            {...chakraStyles.buttonLinkStyles}
          >
            {t('common.continue_with_my_claim')}
          </Button>
        ) : null}
        {isUserArrivedFromClaimSubmissionFunnel ? (
          <Button
            as={Link}
            href={`${getWebappClaimUrl()}${claimId}`}
            size="m"
            onClick={goToClaim}
            isExternal
            width={{ base: '100%', lg: 'initial' }}
            data-testid="goto-claim-details"
            {...chakraStyles.buttonLinkStyles}
          >
            {t('common.continue')}
          </Button>
        ) : null}
        {!isUserArrivedFromGoodwill &&
          !isUserArrivedFromClaimSubmissionFunnel && (
            <Button
              size="m"
              onClick={goToDashboard}
              width={{ base: '100%', lg: 'initial' }}
              data-testid="goto-ahplus-dashboard"
            >
              {t('common.get_started')}
            </Button>
          )}
      </Flex>
    </Box>
  );
};

export default Side;
