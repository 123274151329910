import React, { useRef } from 'react';
import cookie from 'react-cookies';

import { type TurnstileInstance, Turnstile } from '@marsidev/react-turnstile';

import config from 'config/config';
import { TURNSTILE_COOKIE } from 'config/cookies';

const { turnstileSiteKey, cookieOptions } = config;

const TurnstileWidget = () => {
  const ref = useRef<TurnstileInstance>(null);

  if (!turnstileSiteKey) {
    return null;
  }

  return (
    <Turnstile
      ref={ref}
      siteKey={turnstileSiteKey}
      options={{
        size: 'invisible',
        refreshExpired: 'auto',
      }}
      onSuccess={(token) => cookie.save(TURNSTILE_COOKIE, token, cookieOptions)}
    />
  );
};

export default TurnstileWidget;
