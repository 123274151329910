import ky, { type HTTPError } from 'ky';

import { convertDomainUrl } from 'utils/domains';

import { getCsrfTokenMetaTag, setupCsrfToken } from './csrf';

import config from 'config/config';

export const WEBAPP_API_URL = convertDomainUrl(
  window.location.origin,
  import.meta.env.VITE_WEBAPP_BACKEND_URL || '',
);
export const AHPLUS_API_URL = convertDomainUrl(
  window.location.origin,
  import.meta.env.VITE_AHPLUS_API_URL || '',
);
export const AUTH_API_URL = convertDomainUrl(
  window.location.origin,
  import.meta.env.VITE_AUTH_URL || '',
);

function setupCsrfHeaders(request: Request, csrfName: string) {
  request.headers?.set('X-Requested-With', 'XMLHttpRequest');

  const csrfToken = getCsrfTokenMetaTag(csrfName)?.getAttribute('content');
  if (csrfToken) {
    request.headers.set('X-Csrf-Token', csrfToken);
  }
}

function handleError(error: HTTPError, csrfName: string) {
  if (error.response?.headers?.['X-Csrf-Token']) {
    setupCsrfToken(error.response.headers['X-Csrf-Token'], csrfName);
  }
}

const ahplusGateway = ky.extend({
  credentials: 'include',
  hooks: {
    beforeRequest: [
      (request) => {
        setupCsrfHeaders(request, 'ahplus');
        request.headers.set('x-caller', config.appName);
      },
    ],
    beforeError: [
      (error) => {
        handleError(error, 'ahplus');

        return error;
      },
    ],
  },
});

const webappGateway = ky.extend({
  credentials: 'include',
  hooks: {
    beforeRequest: [
      (request) => {
        setupCsrfHeaders(request, 'webapp');
        request.headers.set('x-caller', config.appName);
      },
    ],
    beforeError: [
      (error) => {
        handleError(error, 'webapp');

        return error;
      },
    ],
  },
});

export { ahplusGateway, webappGateway };
