import React from 'react';
import track from 'react-tracking';

import HomeView from 'components/HomeView';
import Onboarding from 'components/HomeView/Onboarding';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { PageLoader } from 'elements';
import useFetchClaimsEnquiries from 'hooks/api/claims/useFetchClaimsEnquiries';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import { useFeatureFlags, useJourneys } from '@airhelp/plus/react';
import EssentialUpgrade from 'components/HomeView/EssentailUpgrade/EssentialUpgrade';

const HomePage: React.FC = () => {
  usePageTracking();
  useProtectedPage();
  usePageNavigationHeader(null);

  const { isLoading: isJourneysLoading, data: journeysData } = useJourneys();
  const { isLoading: isLoungePassesLoading, data: loungePassesData } =
    useFetchLoungePasses();
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();
  const { data: claimEnquiriesData, isLoading: isClaimEnquiriesLoading } =
    useFetchClaimsEnquiries();

  const { data: featureFlagsData } = useFeatureFlags();

  const isLoading =
    isUserProfileLoading ||
    isJourneysLoading ||
    isLoungePassesLoading ||
    isClaimEnquiriesLoading;

  const showUpgradeModal =
    featureFlagsData?.essential_upgrade &&
    userProfile?.policy?.membership?.isEssential;

  if (isLoading || !userProfile) {
    return <PageLoader />;
  }

  return (
    <>
      <EssentialUpgrade canLoadBanner={Boolean(showUpgradeModal)} />
      <Onboarding />
      <HomeView
        userProfile={userProfile}
        journeys={journeysData ?? []}
        loungePasses={loungePassesData ?? []}
        claims={claimEnquiriesData ?? []}
      />
    </>
  );
};

export default track({ page_type: 'Home' })(HomePage);
