import { AirLuggageJourney, AirLuggageStatus } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';

export const getTooltipStatus = (airLuggageJourney: AirLuggageJourney) => {
  const { t } = useTranslation();

  const { maxBags, status, payoutAmount } = airLuggageJourney;
  const defaultKey = maxBags === 1 ? 'active' : 'default';
  const airLuggagePayout = payoutAmount.toString();

  switch (status) {
    case AirLuggageStatus.enum.active:
      return t(`air_luggage.card.description.${defaultKey}`, {
        amount: airLuggagePayout,
        bags: maxBags,
      });

    case AirLuggageStatus.enum.eligible:
      return t('air_luggage.card.description.eligible');

    case AirLuggageStatus.enum.ineligible:
    case AirLuggageStatus.enum.unregistered:
      return t('air_luggage.card.description.claim_rejected');

    case AirLuggageStatus.enum.processing_payment:
    case AirLuggageStatus.enum.manual_verification_pending:
      return t('air_luggage.card.description.checking_eligibility');

    case AirLuggageStatus.enum.payout_failed:
    case AirLuggageStatus.enum.payout_returned:
      return t('air_luggage.card.description.payment_failed');

    case AirLuggageStatus.enum.payout_successful:
      return t('air_luggage.card.description.paid');

    case AirLuggageStatus.enum.expired:
      return t('air_payout.card.description.expired', { value: 28 });

    default:
      return t(`air_luggage.card.description.${defaultKey}`, {
        amount: airLuggagePayout,
        bags: maxBags,
      });
  }
};
