import { useTranslation } from 'react-i18next';

import { type Offer } from 'models/Offers';
import { PRODUCTS_NAME, PRODUCTS_DISPLAY_NAME } from 'types/products';

export const getSmartProduct = (offers: Record<string, Offer>) => {
  const name = PRODUCTS_NAME.AIRHELP_SMART_ANNUALLY;

  return offers[name];
};

export const getOfferByTier = (
  offers: Record<string, Offer>,
  tier?: string,
) => {
  switch (tier) {
    case 'pro':
      return offers[PRODUCTS_NAME.AIRHELP_PRO_ANNUALLY];
    case 'lite':
      return offers[PRODUCTS_NAME.AIRHELP_LITE_ANNUALLY];
    default:
      return offers[PRODUCTS_NAME.AIRHELP_SMART_ANNUALLY];
  }
};

export const getPlanByProductName = ({
  productName,
  shortName,
}: {
  productName: PRODUCTS_NAME;
  shortName?: boolean;
}) => {
  const { t } = useTranslation();

  switch (true) {
    case isProAnnualMembership(productName):
      return shortName
        ? PRODUCTS_DISPLAY_NAME.AIRHELP_PLUS_PRO
        : t('pricing.plans.pro');
    case isLiteAnnualMembership(productName):
      return shortName
        ? PRODUCTS_DISPLAY_NAME.AIRHELP_PLUS_LITE
        : t('pricing.plans.lite');
    default:
      return shortName
        ? PRODUCTS_DISPLAY_NAME.AIRHELP_PLUS_SMART
        : t('pricing.plans.smart');
  }
};

export const getMembershipPlan = ({
  offers,
  renewOffer,
  isManualRenew,
  isUpgradeOffer,
  upgradeOffer,
  tier,
}: {
  offers: Record<string, Offer>;
  renewOffer?: Offer;
  upgradeOffer?: Offer;
  isUpgradeOffer?: boolean;
  isManualRenew: boolean;
  tier?: string;
}) => {
  if (isUpgradeOffer && !!upgradeOffer) {
    return upgradeOffer;
  }
  if (isManualRenew && !!renewOffer) {
    return renewOffer;
  }

  return getOfferByTier(offers, tier);
};

export const isSmartAnnualMembership = (productName: PRODUCTS_NAME) => {
  return productName === PRODUCTS_NAME.AIRHELP_SMART_ANNUALLY;
};

export const isLiteAnnualMembership = (productName: PRODUCTS_NAME) => {
  return productName === PRODUCTS_NAME.AIRHELP_LITE_ANNUALLY;
};

export const isProAnnualMembership = (productName: PRODUCTS_NAME) => {
  return productName === PRODUCTS_NAME.AIRHELP_PRO_ANNUALLY;
};

export const getProductTitle = ({
  productName,
}: {
  productName: PRODUCTS_NAME;
}) => {
  const { t } = useTranslation();
  const plan = getPlanByProductName({ productName });

  return t('common.airhelp_plus_plan', { plan });
};

export const getProductShortTitle = ({
  productName,
}: {
  productName: PRODUCTS_NAME;
}) => {
  const { t } = useTranslation();

  const shortPlanName = getPlanByProductName({ productName, shortName: true });

  return `${shortPlanName} ${t('common.membership')}:`;
};

export const checkPromoCodeEntitlement = (
  isManualRenew: boolean,
  isUpgradeOffer: boolean,
  claimId?: string,
) => {
  return !isManualRenew && !claimId && !isUpgradeOffer;
};

export const getActionNameForProduct = (productName?: PRODUCTS_NAME) => {
  const { t } = useTranslation();

  if (!productName) {
    return t('payment.pay');
  }

  if (
    [
      PRODUCTS_NAME.AIRHELP_PRO_ANNUALLY,
      PRODUCTS_NAME.AIRHELP_SMART_ANNUALLY,
      PRODUCTS_NAME.AIRHELP_LITE_ANNUALLY,
    ].includes(productName)
  ) {
    const plan = getPlanByProductName({ productName });
    return t('payment.buy_airhelp', { plan });
  }

  if (productName === PRODUCTS_NAME.UPGRADE_TO_TRIP_COMPLETE) {
    return t('payment.checkout.upgrade_title', {
      plan: t('pricing.plans.complete'),
    });
  }

  return t('payment.pay');
};
