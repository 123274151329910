import React from 'react';

import { Flex } from '@chakra-ui/react';

import TopBannerEssentialUpgrade from 'components/HomeView/TopBannerEssentialUpgrade';

const TopBanners: React.FC = () => {
  return (
    <Flex flexDirection="column" position="fixed" zIndex="11" width="100%">
      <TopBannerEssentialUpgrade />
    </Flex>
  );
};

export default TopBanners;
