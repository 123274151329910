import React, {
  createContext,
  type PropsWithChildren,
  useReducer,
  useState,
} from 'react';

import type UserProfile from 'models/UserProfile';
import checkoutDataReducer, {
  type CheckoutData,
  type Action,
  initialCheckoutState,
} from 'utils/tracking/reducers/checkoutDataReducer';

interface TrackingContextInterface {
  subPageType: string;
  setSubPageType: (subPageType: string) => void;
  checkoutData: CheckoutData;
  checkoutDataDispatch: React.Dispatch<Action>;
  loginData: TrackingLoginData | null;
  setLoginData: (loginData: TrackingLoginData | null) => void;
}

export interface TrackingLoginData {
  user: UserProfile;
  method: 'email';
}

export const TrackingContext = createContext<TrackingContextInterface>({
  subPageType: '',
  setSubPageType: () => null,
  checkoutData: initialCheckoutState,
  checkoutDataDispatch: () => null,
  loginData: null,
  setLoginData: () => null,
});

const TrackingContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loginData, setLoginData] = useState<TrackingLoginData | null>(null);
  const [subPageType, setSubPageType] = useState<string>('');
  const [checkoutData, checkoutDataDispatch] = useReducer(checkoutDataReducer, {
    ...initialCheckoutState,
  });

  return (
    <TrackingContext.Provider
      value={{
        checkoutData,
        checkoutDataDispatch,
        subPageType,
        setSubPageType,
        loginData,
        setLoginData,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingContextProvider;
