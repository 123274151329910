import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import {
  Image,
  VStack,
  Text,
  Button,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent, useTrackOnce } from 'utils/tracking/hooks';
import { getWebsiteAhPlusPageUrl } from 'utils/sites';

interface ICompontent {
  imageUrl: string;
  header: string;
  description: string;
  eventPlacement: string;
}

const MembershipUpsell = ({
  imageUrl,
  header,
  description,
  eventPlacement,
}: ICompontent) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { trackPageInteractions, trackCtaClick } = useTrackEvent();

  const visibilityTreshhold = useBreakpointValue({ base: 0.75, lg: 0.5 });

  const { ref, inView } = useInView({
    threshold: visibilityTreshhold,
  });

  useTrackOnce(
    (setTracked) => {
      if (inView) {
        trackPageInteractions('show', 'plan upgrade non ahp', eventPlacement);
        setTracked();
      }
    },
    [inView],
  );

  return (
    <VStack
      width="100%"
      height="100%"
      minHeight={{ base: 'initial', lg: '100vh' }}
      justifyContent="center"
      spacing={4}
      ref={ref}
    >
      <Image
        src={imageUrl}
        alt=""
        w={{ base: '200px', md: '256px' }}
        height={{ base: '200px', md: '256px' }}
      />
      <VStack mt={6} spacing={1} textAlign="center">
        <Text
          fontSize="xs"
          color="primary.400"
          letterSpacing="1px"
          fontWeight="700"
          textTransform="uppercase"
        >
          {t('upgrade_account.upgrade_your_account')}
        </Text>
        <Heading variant="h5">{header}</Heading>
        <Text color="greyscale.600" fontSize="md">
          {description}
        </Text>
      </VStack>
      <Button
        width={{ base: '100%', md: 'auto' }}
        as="a"
        variant="primaryAlt"
        href={getWebsiteAhPlusPageUrl('pricing-plans', locale)}
        target="_blank"
        rel="noopener noreferrer"
        size="s"
        onClick={() => trackCtaClick('plan upgrade non ahp', eventPlacement)}
        _visited={{}}
        data-testid="btn-check-our-plans"
      >
        {t('common.check_our_plans')}
      </Button>
    </VStack>
  );
};

export default MembershipUpsell;
