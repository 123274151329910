import { Expose } from 'class-transformer';

export interface IAirBundleSubscription {
  airPayoutCreditsLeft: number;
  airLuggageCreditsLeft: number;
  airPayoutUnlimitedAccess: boolean;
  airLuggageUnlimitedAccess: boolean;
  airPayoutIpidVersion: string;
  airLuggageIpidVersion: string;
}

export class AirBundleSubscription implements IAirBundleSubscription {
  @Expose() airPayoutCreditsLeft: number;
  @Expose() airLuggageCreditsLeft: number;
  @Expose() airPayoutUnlimitedAccess: boolean;
  @Expose() airLuggageUnlimitedAccess: boolean;
  @Expose() airPayoutIpidVersion: string;
  @Expose() airLuggageIpidVersion: string;

  @Expose()
  get hasCredit(): boolean {
    return Boolean(
      this.airPayoutCreditsLeft > 0 || this.airLuggageCreditsLeft > 0,
    );
  }

  @Expose()
  get airLuggageAccess(): boolean {
    return Boolean(
      this.airLuggageUnlimitedAccess || this.airLuggageCreditsLeft > 0,
    );
  }
}
