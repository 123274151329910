import React, { useContext } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, DatePicker } from '@airhelp/react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { EditedJourneyContext } from 'contexts';
import { dateFnLocaleHelper } from 'contexts/LocaleContext/LocaleContextProvider';
import AirlineAutocomplete from 'elements/AirlineAutocomplete';
import { EditableTypes } from 'reducers/editableJourney';
import { journeyUtils } from 'utils';
import {
  flightNumberPattern,
  validateUniqueFlightDesignator,
} from 'utils/validations';
import { Airline, Airport } from '@airhelp/webapp';
import { EditableFlight } from '@airhelp/plus';

interface FlightDetailsProps {
  flight: EditableFlight;
  control: Control;
  errors: FieldErrors;
}

const FlightDetails: React.FC<FlightDetailsProps> = ({
  flight,
  control,
  errors,
}) => {
  const { editedItinerary, itineraryIndex, dispatch } =
    useContext(EditedJourneyContext);
  const itinerary = editedItinerary;

  const { departureAirport, arrivalAirport, id, localDepartureDate } = flight;
  const { t, i18n } = useTranslation();
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleDataChange = (dispatchValue, formValue, onFormChange) => {
    onFormChange(formValue);

    dispatch({
      type: EditableTypes.UpdateFlight,
      itineraryIndex,
      payload: {
        ...itinerary.flights.find((f) => f.id === id),
        ...dispatchValue,
      },
    });
  };

  const handleFlightDateChange = (
    departureDate: string | Date,
    onFormChange,
  ) => {
    onFormChange(departureDate);

    dispatch({
      type: EditableTypes.UpdateFlightDepartueDate,
      itineraryIndex,
      payload: {
        departureDate: departureDate as Date,
        id,
      },
    });
  };

  const createZonedDate = (date: string | Date | null) => {
    if (date === null) {
      return undefined;
    }

    return toZonedTime(date, clientTimeZone);
  };

  return (
    <Box w="100%" data-testid={`flight-box-${departureAirport?.iata}`}>
      <Text
        fontSize="md"
        fontWeight="medium"
        borderTop="1px solid"
        borderTopColor="greyscale.400"
        mb={4}
        color="greyscale.600"
        _first={{ pt: 0, mt: 0, borderTop: 0 }}
      >
        {journeyUtils.cityWithCode(departureAirport as Airport)} -{' '}
        {journeyUtils.cityWithCode(arrivalAirport as Airport)}
      </Text>

      <Controller
        name={`airline-${departureAirport?.iata}-${arrivalAirport?.iata}`}
        control={control}
        rules={{
          required: { value: true, message: t('errors.required') },
          validate: ({ value }) => {
            const iata = value?.iata;
            return validateUniqueFlightDesignator(
              { iata },
              itinerary,
              flight,
              t,
            );
          },
        }}
        defaultValue={flight.airline?.iata || null}
        render={({ field: { onChange }, fieldState: { invalid, error } }) => (
          <AirlineAutocomplete
            defaultAirline={flight.airline as Airline}
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            handleChange={(airline) =>
              handleDataChange(
                {
                  airline: airline?.value,
                  airlineIdentifier: airline?.value.uniqueIdentifier,
                },
                airline,
                onChange,
              )
            }
            formControlStyles={{ mb: 6 }}
            label={t(
              'funnels.add_new_flight.flight_details_step.airline_label',
            )}
            dataTestId={`airline-${departureAirport?.iata}-${arrivalAirport?.iata}`}
          />
        )}
      />
      <Flex
        justifyContent="flex-start"
        flexDirection={{ base: 'column', sm: 'row' }}
      >
        <Box
          mr={{ base: 0, sm: 8 }}
          width="100%"
          maxWidth={{ base: '100%', sm: '144px' }}
          data-testid={`flight-number-container-${id}`}
        >
          <Controller
            name={`flightNumber${id}`}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
              pattern: {
                value: flightNumberPattern,
                message: t('errors.flight_number_invalid'),
              },
              validate: (flightNumber) =>
                validateUniqueFlightDesignator(
                  { flightNumber },
                  itinerary,
                  flight,
                  t,
                ),
            }}
            defaultValue={flight.flightNumber}
            render={({ field: { onChange, name, value } }) => (
              <Input
                isInvalid={Boolean(errors[`flightNumber${id}`])}
                errorMessage={
                  errors[`flightNumber${id}`]?.message?.toString() || ''
                }
                placeholder="1234"
                label={t(
                  'funnels.add_new_flight.flight_details_step.flight_number_label',
                )}
                isDouble
                leftAddonText={flight.airline?.iata || ''}
                name={name}
                type="text"
                id={`flightNumbers-${id}`}
                value={value}
                onChange={(e) =>
                  handleDataChange(
                    { flightNumber: e.target.value },
                    e.target.value,
                    onChange,
                  )
                }
                data-testid={`flight-number-${id}`}
                maxWidth={{ base: '100%', sm: 'inherit' }}
                inputMode="numeric"
              />
            )}
          />
        </Box>
        <Box mt={{ base: 6, sm: 0 }}>
          <Controller
            name={`date${id}`}
            control={control}
            rules={{ required: { value: true, message: t('errors.required') } }}
            defaultValue={flight.localDepartureDate}
            render={({ field: { onChange } }) => (
              <DatePicker
                invalid={Boolean(errors[`date${id}`])}
                errorMessage={errors[`date${id}`]?.message?.toString() || ''}
                label={t(
                  'funnels.add_new_flight.flight_details_step.departure_date_label',
                )}
                onChange={(date) => {
                  const formattedDate = format(new Date(date), 'yyyy-MM-dd');
                  handleFlightDateChange(formattedDate, onChange);
                }}
                minDate={createZonedDate(flight?.metadata?.minDate)}
                maxDate={createZonedDate(flight?.metadata?.maxDate)}
                value={createZonedDate(localDepartureDate)}
                placeholder="dd/mm/yyyy"
                locale={dateFnLocaleHelper(i18n?.language)}
                isReadOnly
              />
            )}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default FlightDetails;
