import { useQuery } from '@tanstack/react-query';

import { offersApi } from 'api';

interface OfferItem {
  code: string;
  discountedPrice: string | null;
  productPrice: string;
  discountValue: string | null;
  productName: string;
}

interface UpgradeOffer {
  upgradeOffer: OfferItem;
  renewOffer: OfferItem;
}

export const fetchUpgradeOffer = async (currency = 'eur') => {
  const [error, response] = await offersApi.fetchUpgradeOffers(currency);

  if (error) {
    throw error;
  } else {
    return response as UpgradeOffer;
  }
};

const useFetchUpgradeOffer = (currency: string) => {
  return useQuery({
    queryKey: ['upgrade_offer', currency],
    queryFn: () => fetchUpgradeOffer(currency),
  });
};

export default useFetchUpgradeOffer;
