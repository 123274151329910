import cookie from 'react-cookies';

import { type UseQueryResult } from '@tanstack/react-query';

import { userProfileApi } from 'api';
import { TURNSTILE_COOKIE } from 'config/cookies';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import {
  type ILoungePass,
  type LoungePass,
  transformToLoungePassClass,
} from 'models/LoungePass';
import { resetTurnstileToken } from 'utils/turnstile';

const fetchLoungePasses = async () => {
  const token = cookie.load(TURNSTILE_COOKIE);

  const [error, response] = await userProfileApi.fetchLoungePasses(token);

  if (error) {
    throw error;
  }

  resetTurnstileToken();

  return response;
};

const useFetchLoungePasses = (options: ApiQueryOptions = {}) => {
  return useProtectedQuery({
    queryKey: ['userProfile', 'loungePasses'],
    queryFn: () => fetchLoungePasses(),
    select: (data) =>
      (data as { loungePasses: ILoungePass[] }).loungePasses.map(
        (loungePassData) => transformToLoungePassClass(loungePassData),
      ),
    ...options,
  }) as UseQueryResult<LoungePass[]>;
};

export default useFetchLoungePasses;
