import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataDownload } from '@airhelp/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

export const PolicyButton: React.FC<{ linkToFile: string }> = ({
  linkToFile,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      as={Link}
      href={linkToFile}
      variant="link"
      size="xs"
      aria-label="data download"
      fontWeight="normal"
      isExternal
      textColor="primary.500 !important"
      leftIcon={<DataDownload color="primary.500" width={4} height={4} />}
      _hover={{ backgroundColor: 'greyscale.100' }}
      data-testid="download-policy"
    >
      {t('common.policy')}
    </Button>
  );
};

interface IComponent {
  readMoreModal: JSX.Element;
  description: string;
  linkToFile: string;
  title: string;
}

const HeaderContent: React.FC<IComponent> = ({
  readMoreModal,
  description,
  linkToFile,
  title,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
      <Box width="100%" maxWidth={{ base: '100%', md: '640px' }}>
        <Heading variant="h5" mb={1}>
          {title}
        </Heading>
        <Flex alignItems="center">
          <Text fontSize="md" color="greyscale.600">
            {description} {readMoreModal}
          </Text>
        </Flex>
      </Box>
      {isMobile ? null : <PolicyButton linkToFile={linkToFile} />}
    </Flex>
  );
};

export default HeaderContent;
