import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';

interface Props extends PropsWithChildren {
  dataTestId?: string;
  mt?: number;
}

const ErrorBadge: React.FC<Props> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <InfoBox
      isChat
      icon={<Info color="danger.500" />}
      variant="danger"
      mt={4}
      width="100%"
      {...props}
    >
      {children || t('errors.something_went_wrong')}
    </InfoBox>
  );
};

export default ErrorBadge;
