import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import LoungeKey from 'assets/images/lounges/lounge-key-logo-color.svg';
import FAQs from 'components/LoungesView/FAQs';
import SearchLoungesImage from 'components/LoungesView/SearchLoungesImage';
import { PAGE_PATHS } from 'config/routes';
import { type LoungePass } from 'models/LoungePass';
import { useTrackEvent } from 'utils/tracking/hooks';

import Conditions from './Conditions';
import PassesList from './PassesList';

interface IComponent {
  loungePasses: LoungePass[];
}

const PassesView: React.FC<IComponent> = ({ loungePasses }) => {
  const { t } = useTranslation();
  const { trackPageInteractions, trackCtaClick } = useTrackEvent();
  const navigate = useNavigate();

  const onFaqCollapsibleToggle = (isToggle: boolean) => {
    isToggle && trackPageInteractions('clicked', 'FAQ item');
  };

  const onConditionsCollapsibleToggle = (isToggle: boolean) => {
    isToggle && trackPageInteractions('clicked', 't&c');
  };

  const handleSearchLoungesClick = () => {
    navigate(
      generatePath(`${PAGE_PATHS.LOUNGE_FINDER_PAGE}?lounge-passes-back=true`),
    );

    trackCtaClick('lounge finder', 'lounges pass page');
  };

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr',
        lg: '1fr',
        xl: 'minmax(500px, 785px) 360px',
      }}
      gap={4}
      columnGap={8}
      w="100%"
      maxW="1196px"
      gridTemplateAreas={{
        base: `
          "Header"
          "Conditions"
          "PassesList"
          "SearchLoungesImage"
          "Faq"
        `,
        md: `
          "Header"
          "PassesList"
          "Conditions"
          "SearchLoungesImage"
          "Faq"
        `,
        lg: `
          "Header"
          "PassesList"
          "Conditions"
          "SearchLoungesImage"
          "Faq"
        `,
        xl: `
          "Header Header"
          "PassesList Conditions"
          "SearchLoungesImage Conditions"
          "Faq Conditions"
        `,
      }}
      data-testid="lounge-passes"
    >
      <GridItem gridArea="Header">
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          maxW={{ base: '100%', lg: '785px', xl: '1192px' }}
          mt={{ base: 6, md: 4, lg: 0 }}
        >
          <Box width="100%" mr={{ base: 0, md: 8 }}>
            <Heading variant="h4" mb={2}>
              {t('lounges.passes.header')}
            </Heading>
            <Text fontSize="md" mb={{ base: 0, lg: 10 }} color="greyscale.700">
              {t('lounges.passes.subheader')}
            </Text>
          </Box>
          <Flex
            w={{ base: '100%', lg: '376px' }}
            my={{ base: 5, lg: 0 }}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Flex
              border="1px solid"
              borderColor="greyscale.400"
              borderRadius="lg"
              px={5}
              py={4}
              minWidth={{ base: '100%', lg: '220px' }}
              justifyContent="center"
              alignItems="center"
            >
              <Text
                color="greyscale.600"
                fontSize="sm"
                mr={2}
                verticalAlign="middle"
              >
                {t('lounges.provided_by')}:
              </Text>
              <Image src={LoungeKey} width="100px" />
            </Flex>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem gridArea="PassesList">
        <PassesList loungePasses={loungePasses} />
      </GridItem>
      <GridItem gridArea="SearchLoungesImage">
        <SearchLoungesImage
          handleClick={handleSearchLoungesClick}
          maxWidth={{ base: '100%', lg: '785px' }}
        />
      </GridItem>
      <GridItem gridArea="Faq">
        <FAQs
          onToggle={onFaqCollapsibleToggle}
          maxWidth={{ base: '100%', lg: '785px' }}
        />
      </GridItem>
      <GridItem gridArea="Conditions">
        <Conditions onToggle={onConditionsCollapsibleToggle} />
      </GridItem>
    </Grid>
  );
};

export default PassesView;
