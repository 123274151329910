import React from 'react';
import track from 'react-tracking';

import ResetPasswordView from 'components/ResetPasswordView';
import { usePageTracking } from 'utils/tracking';

const ResetPasswordPage: React.FC = () => {
  usePageTracking();

  return <ResetPasswordView />;
};

export default track({ page_type: 'ResetPasswordPage' })(ResetPasswordPage);
