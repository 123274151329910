import cookie from 'react-cookies';

import { create } from 'zustand';

import { AH_LANGUAGE_COOKIE, CURRENCY_COOKIE } from 'config/cookies';
import {
  mapLocaleToCurrency,
  type CurrencyCode,
} from 'stores/preferences/currencies';
import { createCookieOptions } from 'utils/cookies';

interface Store {
  currency: CurrencyCode;
  setCurrency: (value: CurrencyCode) => void;
}

const getInitialCurrency = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  return (
    cookie.load(CURRENCY_COOKIE) ||
    mapLocaleToCurrency(
      langParam || cookie.load(AH_LANGUAGE_COOKIE) || navigator.language,
    ) ||
    'EUR'
  );
};

export const usePreferencesStore = create<Store>((set) => {
  const initialCurrency = getInitialCurrency();

  return {
    currency: initialCurrency,
    setCurrency: (currency: CurrencyCode) => {
      set(() => ({ currency }));
      cookie.save(CURRENCY_COOKIE, currency, createCookieOptions());
    },
  };
});
