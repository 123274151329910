import { useMutation, useQueryClient } from '@tanstack/react-query';

import { claimsApi } from 'api';

const deleteClaimEnquiry = async (id: number) => {
  const [error, response] = await claimsApi.deleteClaimEnquiry(id);
  if (error) {
    throw error;
  } else {
    return response;
  }
};

const useDeleteClaimEnquiry = () => {
  const queryClient = useQueryClient();

  const deleteClaimEnquiryMutation = useMutation({
    mutationFn: (params: { id: number }) => deleteClaimEnquiry(params.id),
    onSuccess: (id: number) => {
      queryClient.removeQueries({ queryKey: ['claims', id] });
      queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });

  return deleteClaimEnquiryMutation;
};

export default useDeleteClaimEnquiry;
