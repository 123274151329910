export const CURRENCY_COOKIE = 'ahplus_currency';
export const AH_LANGUAGE_COOKIE = 'ah_language';
export const USER_LANGUAGE_COOKIE = 'user_language';
export const HOMESCREEN_BANNER_DISCARDED_COOKIE =
  'ahplus_dashboard_add_homescreen_banner_discarded';
export const USER_RETURN_AHPLUS_COOKIE = 'user_return_ahplus';
export const AHP_SIGNUP_EMAIL_COOKIE = 'ahplus_signup_email';
export const TURNSTILE_COOKIE = 'cf_trunstile';
export const UPGRADE_MODAL_DISMISSED_COOKIE = 'upgrade_modal_dismissed';
export const NOTIFICATION_DISMISSED_COOKIE = 'ah_notification_dismissed';
