function getCsrfTokenMetaTag(csrfName: string) {
  return document.querySelector(`meta[name="${csrfName}-csrf"]`);
}

function setupCsrfToken(token: string | null, csrfName: string): void {
  const appendCsrfTokenMetaTag = () => {
    const csrfMetaTag = document.createElement('meta');
    csrfMetaTag.setAttribute('name', `${csrfName}-csrf`);
    return document.head.appendChild(csrfMetaTag);
  };

  if (token) {
    const csrfMetaTag =
      getCsrfTokenMetaTag(csrfName) || appendCsrfTokenMetaTag();

    if (!csrfMetaTag.getAttribute('content')) {
      csrfMetaTag.setAttribute('content', token);
    }
  }
}

export { getCsrfTokenMetaTag, setupCsrfToken };
