import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { userProfileApi } from 'api';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { transformToUserProfileClass } from 'models/UserProfile';

const QUERY_KEY = ['userProfile', 'me'];

const transformResponse = (data) => {
  return transformToUserProfileClass(data.userProfile);
};

const fetchMyProfile = async () => {
  const [error, response] = await userProfileApi.fetchMyProfile();

  if (error) {
    throw error;
  }

  return response;
};

const prefetchMyProfile = async (queryClient: QueryClient) => {
  const currentQueryData = queryClient.getQueryData(QUERY_KEY);

  if (currentQueryData) {
    return transformResponse(currentQueryData);
  }

  try {
    const response = await fetchMyProfile();

    queryClient.setQueryData(QUERY_KEY, response);

    return transformResponse(response);
  } catch (error) {
    return null;
  }
};

const useFetchMyProfile = (options: ApiQueryOptions = {}) => {
  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => fetchMyProfile(),
    select: transformResponse,
    ...options,
  });
};

const useMyProfile = () => {
  const queryClient = useQueryClient();

  const { data: userProfile } = useFetchMyProfile({ enabled: false });
  const queryState = queryClient.getQueryState(QUERY_KEY);

  const clearProfile = () => {
    queryClient.removeQueries({ queryKey: QUERY_KEY });
  };

  const reloadProfile = async () => {
    queryClient.removeQueries({ queryKey: QUERY_KEY });
    await prefetchMyProfile(queryClient);
  };

  return {
    userProfile,
    queryState,
    clearProfile,
    reloadProfile,
  };
};

export default useFetchMyProfile;

export { useMyProfile, prefetchMyProfile };
