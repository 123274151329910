import {
  colorPrimary500,
  colorPrimary900,
  colorGreyscale200,
  colorGreyscale500,
  colorDanger700,
  fontSizeSm,
  fontSizeMd,
  fontWeightNormal,
  fontWeightMedium,
  borderRadiusMd,
  borderRadiusLg,
  spacing1x,
  spacing4x,
  spacing6x,
  lineHeightBase,
} from '@airhelp/design-tokens/dist/js/design-tokens';
import { type Appearance } from '@stripe/stripe-js';

export const theme: Appearance = {
  theme: 'stripe',
  variables: {
    colorText: colorPrimary900,
    colorTextPlaceholder: colorGreyscale500,
    colorDanger: colorDanger700,
    colorTextSecondary: colorGreyscale500,
    spacingGridColumn: spacing4x,
    borderRadius: borderRadiusLg,
    fontWeightMedium,
    fontWeightNormal,
    fontLineHeight: lineHeightBase,
    fontSizeXs: fontSizeMd,
    fontSizeSm,
    fontFamily: "'Lab Grotesque', sans-serif",
  },
  rules: {
    '.AccordionItem--selected': {
      backgroundColor: colorGreyscale200,
    },
    '.AccordionItem': {
      border: `1px solid ${colorGreyscale500}`,
      color: 'var(--colorText)',
      paddingTop: spacing6x,
      paddingBottom: spacing6x,
      paddingLeft: spacing6x,
      paddingRight: spacing6x,
      lineHeight: 'var(--fontLineHeight)',
    },
    '.Input': {
      borderRadius: borderRadiusMd,
      border: `1px solid ${colorGreyscale500}`,
      lineHeight: 'var(--fontLineHeight)',
    },
    '.Input:focus': {
      border: `1px solid ${colorPrimary500}`,
      outline: 'none',
    },
    '.Label': {
      marginTop: spacing1x,
      lineHeight: lineHeightBase,
    },
    '.Input--invalid': {
      borderColor: 'var(--colorDanger)',
      color: 'var(--colorDanger)',
      outline: 'var(--colorDanger)',
    },
  },
};
