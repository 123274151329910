import React from 'react';

import { PlusMath } from '@airhelp/icons';
import {
  Button,
  Text,
  VStack,
  Image,
  type StackProps,
  type ButtonProps,
} from '@chakra-ui/react';

import { LinkTarget } from 'types/utils';

type IComponent = {
  image: string;
  header: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
  externalLink?: string;
  showPlusIcon?: boolean;
  buttonProps?: ButtonProps;
} & StackProps;

const LargeBlankTile: React.FC<IComponent> = ({
  image,
  header,
  description,
  buttonLabel,
  onClick,
  externalLink,
  showPlusIcon = true,
  buttonProps,
  ...props
}) => {
  return (
    <VStack
      w="100%"
      background="greyscale.100"
      p={{ base: 6, md: 8 }}
      borderRadius="lg"
      spacing={3}
      {...props}
    >
      <VStack spacing={{ base: 0, md: 3 }}>
        <Image src={image} w="80px" />
        <VStack spacing={0}>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
            color="primary.900"
            fontWeight="medium"
          >
            {header}
          </Text>
          {description ? (
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              color="greyscale.600"
              textAlign="center"
            >
              {description}
            </Text>
          ) : null}
        </VStack>
      </VStack>
      {buttonLabel && onClick ? (
        <Button
          size="sm"
          variant="secondary"
          data-testid="btn-large-blank-tile"
          {...(externalLink && {
            as: 'a',
            href: externalLink,
            target: LinkTarget.BLANK,
            rel: 'noopener noreferrer',
          })}
          onClick={onClick}
          {...buttonProps}
        >
          {showPlusIcon ? <PlusMath mr={1} fontSize="md" /> : null}
          {buttonLabel}
        </Button>
      ) : null}
    </VStack>
  );
};

export default LargeBlankTile;
