import { get, WEBAPP_API_URL, del, webappGateway } from 'api/ky';

export const CLAIMS_AND_FLIGHTS_URL = `${WEBAPP_API_URL}/api/claims_and_flights`;
export const CLAIM_ENQUIRIES_URL = `${WEBAPP_API_URL}/api/claim_enquiries`;

export const fetchClaimsAndFlights = async () => {
  return await get(CLAIMS_AND_FLIGHTS_URL, { kyInstance: webappGateway });
};

// this function does not delete the claim enquiry from DB, it only marks it as dismissed
export const deleteClaimEnquiry = async (id: number) =>
  await del(`${CLAIM_ENQUIRIES_URL}/${id}`, { kyInstance: webappGateway });
