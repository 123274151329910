import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AltArrowForward } from '@airhelp/icons';
import { Box, HStack, Image, Text } from '@chakra-ui/react';

import noActiveLougePassesImage from 'assets/images/lounges/hassle-free-buka@2x.png';
import spotLoungeImage from 'assets/images/lounges/spot-lounges-buka@2x.png';
import { type LoungePass } from 'models/LoungePass';

import NoLoungePassesTooltip from '../NoLoungePassesTooltip';

interface IComponent {
  loungePasses: LoungePass[];
  handleClick: () => void;
}

const LoungeExperienceBanner: React.FC<IComponent> = ({
  loungePasses,
  handleClick,
}) => {
  const { t } = useTranslation();

  const loungesCount = loungePasses.length;

  const hasLoungePasses = loungesCount > 0;

  const handleTileClick = hasLoungePasses ? handleClick : undefined;

  return (
    <HStack
      py={0}
      px={5}
      width="100%"
      minHeight="auto"
      data-testid="lounge-experience-banner"
      onClick={handleTileClick}
      cursor={handleTileClick ? 'pointer' : 'initial'}
      bgColor="greyscale.100"
      borderRadius="xl"
      justifyContent="space-between"
    >
      {hasLoungePasses ? (
        <Text fontSize="md" fontWeight="medium">
          <Trans
            i18nKey="lounges.your_available_lounge_passes"
            values={{ count: loungesCount }}
            components={{
              strong: (
                <Text
                  as="span"
                  fontWeight="medium"
                  fontSize="md"
                  color="danger.500"
                  display="inline"
                />
              ),
            }}
          />
        </Text>
      ) : (
        <Box width="100%">
          <Text
            fontWeight="medium"
            fontSize="md"
            display="inline"
            textAlign="center"
          >
            {t('lounges.no_valid_passes')}
          </Text>
          <Box display="inline">
            <NoLoungePassesTooltip />
          </Box>
        </Box>
      )}
      <HStack flexShrink={0}>
        <Image
          src={hasLoungePasses ? spotLoungeImage : noActiveLougePassesImage}
          alt=""
          height="128px"
          position="relative"
          my={0}
          py={hasLoungePasses ? 0 : 2}
        />
        {hasLoungePasses ? (
          <AltArrowForward
            color="primary.500"
            boxSize={6}
            data-testid="view-passes-arrow"
          />
        ) : null}
      </HStack>
    </HStack>
  );
};

export default LoungeExperienceBanner;
