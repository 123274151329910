import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { useMyProfile } from './userProfiles/useFetchMyProfile/useFetchMyProfile';

export type ApiQueryOptions = Pick<UseQueryOptions, 'enabled' | 'gcTime'>;

export const useProtectedQuery = (params: UseQueryOptions) => {
  const { userProfile } = useMyProfile();

  const paramEnabled = Object.keys(params).includes('enabled')
    ? params.enabled
    : true;

  const queryParams = {
    ...params,
    enabled: paramEnabled && Boolean(userProfile),
  };

  return useQuery(queryParams);
};
