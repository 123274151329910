import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';

import { Info, Ok } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { postPersonalDetailsProvided } from '@airhelp/plus';
import { useAhPlusHTTPClient } from '@airhelp/plus/react';
import {
  Button,
  Flex,
  Link,
  Text,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';

import { userApi } from 'api';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { ErrorBadge, Loader } from 'elements';
import useEffectOnce from 'hooks/useEffectOnce';
import { type IUserUpdateData, type User } from 'models/User';
import { useTrackEvent } from 'utils/tracking/hooks';
import { getWebsitePageUrl } from 'utils/sites';

import FormInputs from './FormInputs';

interface IComponent {
  actionLabel?: string;
  onDataSaved?: () => void;
  onSubmit?: () => void;
  responsive?: boolean;
}
const MyAccountForm: React.FC<IComponent> = ({
  actionLabel,
  onDataSaved,
  onSubmit: onSubmitCallback,
  responsive,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { trackEvent } = useTracking();
  const { trackCtaClick } = useTrackEvent();
  const ahPlusClient = useAhPlusHTTPClient();

  const [updatedUser, setUpdatedUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  const { handleSubmit, control, formState, reset, getValues, trigger } =
    useForm<IUserUpdateData>({
      mode: 'onChange',
    });
  const { isValid, errors, isDirty, isSubmitSuccessful } = formState;

  const isDisabled = isLoading || !isValid || error || (responsive && !isDirty);

  const termsUrl = getWebsitePageUrl('termsPath', locale);
  const privacyUrl = getWebsitePageUrl('privacyPath', locale);

  const initializeSession = async () => {
    const [error, response] = await userApi.fetchSession();
    const user = error ? null : response.user;

    if (error) {
      setError(true);
    }
    setUpdatedUser(user);
  };

  useEffectOnce(() => {
    initializeSession();
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (saved) {
      toast({
        title: t('my_account.details.your_details_updated'),
        status: 'success',
        duration: 4000,
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
      });

      const timeoutRef = setTimeout(() => {
        setSaved(false);
      }, 3000);

      return () => clearTimeout(timeoutRef);
    }
  }, [saved]);

  const onSubmit = useCallback(
    async (data: IUserUpdateData) => {
      onSubmitCallback ? onSubmitCallback() : null;

      trackEvent({
        name: 'GAEvent',
        eventCategory: 'ahplus',
        eventAction: 'clicked',
        eventLabel: 'ahplus_my_account_personal_data_save_clicked',
      });

      setIsLoading(true);
      const [updateError, userResponse] = await userApi.updateData(data);
      await postPersonalDetailsProvided({ client: ahPlusClient });

      setIsLoading(false);

      if (updateError) {
        setError(true);
      } else {
        setSaved(true);
        setUpdatedUser(userResponse.user as User);

        trackCtaClick('update my details', 'my account page');

        onDataSaved && onDataSaved();
      }
    },
    [setUpdatedUser, setIsLoading, setError],
  );

  if (!updatedUser) {
    return (
      <Flex justifyContent="center">
        <Loader dataTestId="loader" />
      </Flex>
    );
  }

  const getButtonLabel = () => {
    if (actionLabel) {
      return actionLabel;
    }
    if (saved) {
      return t('common.saved');
    }
    return t('common.save');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInputs
        control={control}
        errors={errors}
        updatedUser={updatedUser}
        responsive={responsive}
        trigger={trigger}
      />
      <InfoBox
        isChat
        icon={<Info width={5} height="auto" color="primary.500" mr={2} />}
      >
        <Text fontSize="md">
          {t('my_account.details.phone_number_for_lounges_and_claim_only')}
        </Text>
      </InfoBox>
      {error ? (
        <Flex justifyContent="center" mt={-2} mb={6}>
          <ErrorBadge data-testid="error-text" />
        </Flex>
      ) : null}
      <Flex
        width="100%"
        mt={6}
        flexDirection={
          responsive ? { base: 'column', md: 'row-reverse' } : 'column'
        }
        gap={6}
        alignItems={
          responsive ? { base: 'flex-start', md: 'center' } : 'center'
        }
        justifyContent="space-between"
      >
        <Button
          size="m"
          textAlign="center"
          onClick={handleSubmit(onSubmit)}
          isDisabled={isDisabled}
          isLoading={isLoading}
          width={responsive ? { base: '100%', md: ' auto' } : '100%'}
          data-testid="update-personal-data-button"
          whiteSpace="nowrap"
          flexShrink={0}
        >
          {getButtonLabel()}
        </Button>
        <Flex>
          <Text fontSize="sm" textAlign={responsive ? 'left' : 'center'}>
            <Trans
              i18nKey="common.check_tc_and_privacy_statement"
              values={{ privacyUrl, termsUrl }}
              components={{
                a: (
                  <Link
                    isExternal
                    fontSize="sm"
                    fontWeight="normal"
                    _visited={{}}
                  />
                ),
              }}
            />
          </Text>
        </Flex>
      </Flex>
    </form>
  );
};

export default MyAccountForm;
