import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { BoxImportant } from '@airhelp/icons';
import {
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';

import ContentBox from 'components/Flights/New/ContentBox';
import { EditedJourneyContext } from 'contexts';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { EditableTypes } from 'reducers/editableJourney';
import { ERROR_CODES } from 'types/api';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  onNextStep: (hasReturn: boolean) => void;
  onPreviousStep: () => void;
  isSavingJourney: boolean;
  airLuggageAccess: boolean;
  firstFlightInPast: boolean;
  apiErrorCode: ERROR_CODES | null;
}

type ReturnValueType = 'yes' | 'no';

const ReturnFlightView: React.FC<IComponent> = ({
  onNextStep,
  onPreviousStep,
  isSavingJourney,
  airLuggageAccess,
  firstFlightInPast,
  apiErrorCode,
}) => {
  const { t } = useTranslation();
  const { trackCtaClick, trackPageInteractions } = useTrackEvent();

  const { userProfile } = useMyProfile();

  const isDisabled = Boolean(apiErrorCode) || isSavingJourney;

  const {
    dispatch,
    itineraryIndex,
    previousItineraryIndex,
    setPreviousItineraryIndex,
  } = useContext(EditedJourneyContext);

  const [hasReturnFlightsValue, setHasReturnFlightsValue] =
    useState<ReturnValueType>(previousItineraryIndex === null ? 'no' : 'yes');

  const [addReturnFlight, setAddReturnFlight] = useState(false);

  const location = useLocation();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleCheckboxChange = (newValue: ReturnValueType) => {
    setHasReturnFlightsValue(newValue);

    if (newValue === 'no' && previousItineraryIndex !== null) {
      dispatch({
        type: EditableTypes.RemoveItineraryByIndex,
        itineraryIndex: itineraryIndex + 1,
      });
      setPreviousItineraryIndex(null);
    }
    trackCtaClick(
      newValue === 'yes' ? 'yes, return flights' : 'no, one way trip',
      'return flights',
    );
  };

  useEffect(() => {
    if (hasReturnFlightsValue === 'no' && previousItineraryIndex !== null) {
      dispatch({
        type: EditableTypes.RemoveItineraryByIndex,
        itineraryIndex: itineraryIndex + 1,
      });
      setPreviousItineraryIndex(null);
    }
  }, [location.pathname]);

  const handleContinueButton = () => {
    if (hasReturnFlightsValue === 'no') {
      onNextStep(false);
    } else {
      if (previousItineraryIndex === null) {
        dispatch({
          type: EditableTypes.AddItinerary,
        });
        setPreviousItineraryIndex(itineraryIndex);
      }

      setAddReturnFlight(true);
    }
    trackCtaClick('continue', 'return flights');
  };

  const getButtonLabel = () => {
    if (airLuggageAccess && !firstFlightInPast) {
      return t('common.continue');
    }
    if (hasReturnFlightsValue === 'no') {
      return t('funnels.add_new_flight.submit_trip');
    }
    return t('common.continue');
  };

  useEffect(() => {
    if (addReturnFlight) {
      onNextStep(true);
      setAddReturnFlight(false);
    }
  }, [addReturnFlight]);

  useEffect(() => {
    if (apiErrorCode) {
      const addJourneyBlocker =
        apiErrorCode === ERROR_CODES.ADD_JOURNEY_BLOCKER;

      toast({
        title: t(
          addJourneyBlocker
            ? 'errors.sorry_you_cant_add_trip'
            : 'errors.something_went_wrong',
        ),
        description: addJourneyBlocker
          ? t('errors.please_contact_our_team')
          : null,
        status: 'error',
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: (
          <BoxImportant boxSize={5} alignSelf="center" color="greyscale.100" />
        ),
        containerStyle: {
          fontSize: 'sm',
          fontWeight: '500',
        },
      });

      if (addJourneyBlocker && Boolean(userProfile?.policy.membership)) {
        trackPageInteractions(
          'show',
          `add trip error message ${userProfile?.policy.membership?.tier}`,
        );
      }
    }
  }, [apiErrorCode]);

  return (
    <>
      <VStack spacing={6}>
        {/* checkbox for return flights */}
        <ContentBox
          header={t(
            'funnels.add_new_flight.return_flight_step.do_you_have_return_flight',
          )}
        >
          <Flex flexDirection="column" maxWidth={{ base: '100%', lg: '410px' }}>
            <RadioGroup
              onChange={handleCheckboxChange}
              value={hasReturnFlightsValue}
            >
              <Stack direction="column" spacing={0}>
                <Radio
                  w="100%"
                  value="no"
                  name="return-flights-no"
                  variant="top"
                  data-testid="btn-action-one-way-trip"
                >
                  {t(
                    'funnels.add_new_flight.return_flight_step.no_one_way_trip',
                  )}
                </Radio>
                <Radio
                  w="100%"
                  value="yes"
                  name="return-flights-yes"
                  variant="bottom"
                  data-testid="btn-action-has-return-trip"
                >
                  {t(
                    'funnels.add_new_flight.return_flight_step.yes_return_flight',
                  )}
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </ContentBox>
      </VStack>

      {/* form actions */}
      <Stack
        direction={{ base: 'column', md: 'row-reverse' }}
        mt={6}
        justifyContent="end"
        spacing={5}
      >
        <Button
          isLoading={isSavingJourney}
          isDisabled={isDisabled}
          type="submit"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          onClick={handleContinueButton}
          data-testid="form-action-continue"
        >
          {getButtonLabel()}
        </Button>
        <Button
          variant="secondary"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          onClick={onPreviousStep}
          data-testid="form-action-back"
        >
          {t('funnels.back')}
        </Button>
      </Stack>
    </>
  );
};

export default ReturnFlightView;
