import React, { type PropsWithChildren } from 'react';

import { Flex, Text, VStack } from '@chakra-ui/react';

interface IComponent extends PropsWithChildren {
  header: string;
  description?: string;
}

const Section: React.FC<IComponent> = ({
  children,
  header,
  description,
  ...props
}) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      bg="greyscale.100"
      p={{ base: 4, md: 6 }}
      borderRadius="lg"
      gap={{ base: 4, md: 8 }}
      justifyContent="space-between"
      {...props}
    >
      <VStack
        w={{ base: '100%', md: '33%' }}
        alignItems="flex-start"
        spacing={{ base: 0, md: 8 }}
      >
        <Text fontWeight="500" fontSize="lg">
          {header}
        </Text>
        {description ? (
          <Text fontSize="md" color="primary.700">
            {description}
          </Text>
        ) : null}
      </VStack>
      {children}
    </Flex>
  );
};

export default Section;
