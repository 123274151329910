import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

interface IComponent {
  airportCode: string | null;
  airportCity?: string;
  side?: 'left' | 'right';
}

const AirportName: React.FC<IComponent> = ({
  side,
  airportCity,
  airportCode,
}) => {
  return (
    <>
      <Flex
        flexDirection="column"
        w="50%"
        display={{ base: 'flex', md: 'none' }}
      >
        <Box
          color="primary.900"
          textAlign={side}
          lineHeight="short"
          fontWeight="semibold"
          fontSize="xl"
          mb={1}
        >
          {airportCode}
        </Box>
        <Box
          color="primary.900"
          fontWeight="medium"
          lineHeight="short"
          fontSize="sm"
          textAlign={side}
        >
          {airportCity}
        </Box>
      </Flex>
      <Text
        as="span"
        fontWeight="semibold"
        fontSize="2xl"
        display={{ base: 'none', md: 'block' }}
      >
        {airportCity} ({airportCode})
      </Text>
    </>
  );
};

export default AirportName;
