import React from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import TripCard from './TripCard';
import TripUpgradeBox from './TripUpgradeBox';
import { Journey } from '@airhelp/plus';

interface IComponent {
  journey: Journey;
}

interface DetailsViewLocationState {
  showAirPayoutSubscriptionModal: boolean;
}

const FlightsView: React.FC<IComponent> = ({ journey }) => {
  const location = useLocation();

  const state = location.state as DetailsViewLocationState;
  const showAirPayoutSubscriptionModal = Boolean(
    state?.showAirPayoutSubscriptionModal,
  );

  return (
    <Box width="100%" data-testid={`journey-${journey.id}`}>
      {/* trip essential to complete */}
      {journey?.trip?.upgradeable ? (
        <TripUpgradeBox tripUuid={journey.trip.uuid} />
      ) : null}
      <TripCard
        key={journey.id}
        journey={journey}
        showAirPayoutSubscriptionModal={showAirPayoutSubscriptionModal}
      />
    </Box>
  );
};

export default FlightsView;
