import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Clock,
  AirplaneTakeOffAirstrip,
  AirplaneLandingAirstrip,
} from '@airhelp/icons';
import { Box, Flex, Text } from '@chakra-ui/react';

import config from 'config/config';
import { journeyUtils } from 'utils';
import { Airport, Flight } from '@airhelp/plus';

const { flightStatuses } = config;

interface IComponent {
  airport: Airport | null;
  flight: Flight;
  flightType: 'arrival' | 'departure';
}

const AirportCard: React.FC<IComponent> = ({ airport, flight, flightType }) => {
  const { t } = useTranslation();

  const timezone = airport?.timeZone;

  const isCanceledFlight =
    flightType === 'departure'
      ? flight.flightStatus?.departureStatus === flightStatuses.canceled
      : flight.flightStatus?.arrivalStatus === flightStatuses.canceled;

  const flightTime =
    flightType === 'departure'
      ? flight.flightStatus?.gmtDepartureDateTime || flight.gmtDepartureDateTime
      : flight.flightStatus?.gmtArrivalDateTime || flight.gmtArrivalDateTime;

  const newFlightTime =
    flightType === 'departure'
      ? flight.flightStatus?.gmtActualDepartureAt ||
        flight.flightStatus?.gmtEstimatedDepartureAt
      : flight.flightStatus?.gmtActualArrivalAt ||
        flight.flightStatus?.gmtEstimatedArrivalAt;

  const hasFlightTimeChanged =
    Boolean(newFlightTime) && flightTime != newFlightTime;

  return (
    <Box width={{ base: '100%', md: '50%' }} mb={1}>
      <Flex alignItems="center" fontWeight="medium">
        {flightType === 'arrival' && (
          <AirplaneLandingAirstrip color="primary.500" h={5} w="auto" mr={3} />
        )}
        {flightType === 'departure' && (
          <AirplaneTakeOffAirstrip color="primary.500" h={5} w="auto" mr={3} />
        )}
        {journeyUtils.cityTranslations(airport)}
      </Flex>
      {airport?.name ? (
        <Text color="greyscale.700" fontSize="sm" mt={1}>
          {airport.name}
        </Text>
      ) : null}
      <Flex mt={4} flexDirection="column">
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          {flightTime ? (
            <Flex
              alignItems="center"
              color="greyscale.700"
              fontSize="sm"
              mr={6}
            >
              <Clock color="greyscale.700" h={4} w="auto" mr={1} />
              {flightTime ? `${t('flights_list.scheduled')} ` : null}
              {flightTime
                ? journeyUtils.flightLocalTime(flightTime, timezone)
                : t('common.en_na')}
            </Flex>
          ) : null}
          {isCanceledFlight ? (
            <Text color="danger.500" fontSize="sm" mt={{ base: 4, md: 0 }}>
              {t('flights_list.flight_status.canceled')}
            </Text>
          ) : null}
        </Flex>
        <Flex alignItems="center" mt={2} color="greyscale.700" fontSize="sm">
          {!isCanceledFlight && (
            <>
              <Clock color="greyscale.700" h={4} w="auto" mr={1} />
              {flightTime ? `${t('common.actual')} ` : null}
              {hasFlightTimeChanged
                ? journeyUtils.flightLocalTime(newFlightTime, timezone)
                : t('common.en_na')}
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default AirportCard;
