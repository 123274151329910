import type React from 'react';

import { type ReducerAction as AirLuggagePaymentAction } from 'types/reducers';
import type { AirLuggagePaymentPax } from '@airhelp/plus';

export enum AirLuggagePaymentTypes {
  EditPassenger = 'EDIT_PASSENGER',
}

export interface AirLuggagePaymentReducer {
  airLuggageJourneys: AirLuggagePaymentPax[];
}

export type Action = AirLuggagePaymentAction<
  AirLuggagePaymentTypes.EditPassenger,
  AirLuggagePaymentPax
>;

export const airLuggagePaymentReducer: React.Reducer<
  AirLuggagePaymentReducer,
  Action
> = (state, action) => {
  const actions = {
    [AirLuggagePaymentTypes.EditPassenger]: () => {
      const airLuggagePaymentPaxPayload = action.payload;
      const airLuggageJourneys = [
        ...state.airLuggageJourneys,
        airLuggagePaymentPaxPayload,
      ];

      const existingIndex = state.airLuggageJourneys.findIndex(
        (pax) => pax.id === airLuggagePaymentPaxPayload.id,
      );

      if (existingIndex !== -1) {
        state.airLuggageJourneys[existingIndex] = airLuggagePaymentPaxPayload;
        return state;
      }

      return {
        ...state,
        airLuggageJourneys,
      };
    },
  };

  if (typeof actions[action.type] === 'function') {
    return actions[action.type]();
  }
  throw new Error(
    `Action of type "${action.type}" is missing or is not a function`,
  );
};
