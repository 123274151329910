import React, { useEffect, useState } from 'react';

import { paymentsApi } from 'api';
import { type HttpError } from 'types/api';

import PaymentStatusView from './PaymentStatusView';

interface PaymentStatusResponse {
  paymentIntentId: string;
  status: STRIPE_PAYMENT_STATUSES;
  errorCode: string | null;
}

export enum STRIPE_PAYMENT_STATUSES {
  SUCCEEDED = 'succeeded',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
}

export enum STRIPE_TEST_PAYMENT_STATUSES {
  TEST = 'test-payment-status',
}

const CHECKING_TIME_STAGE_1 = 2; // 2s
const CHECKING_TIME_STAGE_2 = 10; // 10s

type GetPaymentStatusType = [HttpError | null, PaymentStatusResponse];

const ProcessingPaymentView = ({
  paymentIntentId,
  succeededPath,
  tryAgainPath,
  onPaymentFailed,
  noLayoutWrap,
}: {
  paymentIntentId: string;
  succeededPath?: string;
  tryAgainPath?: string;
  onPaymentFailed?: () => void;
  noLayoutWrap?: boolean;
}) => {
  const [seconds, setSeconds] = useState(0);
  const [isStatusChecking, setIsStatusChecking] = useState(true);
  const [checkingStatusFrequency, setCheckingStatusFrequency] =
    useState<number>(CHECKING_TIME_STAGE_1);
  const [paymentStatus, setPaymentStatus] = useState<STRIPE_PAYMENT_STATUSES>(
    STRIPE_PAYMENT_STATUSES.REQUIRES_PAYMENT_METHOD,
  );
  const [stripePaymentError, setStripePaymentError] = useState(false);

  const processPayment = async () => {
    const [paymentStatusError, paymentStatusData] =
      (await paymentsApi.getStripePaymentStatus(
        paymentIntentId,
      )) as GetPaymentStatusType;
    const { status, errorCode } = paymentStatusData;

    if (status === STRIPE_PAYMENT_STATUSES.SUCCEEDED) {
      setIsStatusChecking(false);
      setPaymentStatus(STRIPE_PAYMENT_STATUSES.SUCCEEDED);
    }
    if (paymentStatusError || errorCode) {
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setIsStatusChecking(false);
      setStripePaymentError(true);
    }
  };

  useEffect(() => {
    if (isStatusChecking) {
      const intervalId = setInterval(() => {
        setSeconds((seconds) => seconds + 1);

        if (seconds > 0 && seconds % checkingStatusFrequency === 0) {
          processPayment();
        }

        if (seconds === CHECKING_TIME_STAGE_2) {
          setCheckingStatusFrequency(CHECKING_TIME_STAGE_2);
        }

        if (seconds > 60) {
          setIsStatusChecking(false);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isStatusChecking, checkingStatusFrequency, seconds]);

  return (
    <PaymentStatusView
      paymentStatus={paymentStatus}
      stripePaymentError={stripePaymentError}
      seconds={seconds}
      tryAgainPath={tryAgainPath}
      succeededPath={succeededPath}
      onPaymentFailed={onPaymentFailed}
      noLayoutWrap={noLayoutWrap}
    />
  );
};

export default ProcessingPaymentView;
