import countries from 'i18n-iso-countries';
import { orderBy } from 'lodash';

export type SelectOption = Record<string, string>;

export function getCountriesSelectOptions(locale: string): SelectOption[] {
  const countriesDict = countries.getNames(locale);

  const options = Object.keys(countriesDict).reduce(
    (options: SelectOption[], countryKey: string) => {
      options.push({ label: countriesDict[countryKey], value: countryKey });
      return options;
    },
    [],
  );

  return orderBy(options, ['label']);
}
