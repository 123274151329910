import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { UpgradeModalHeader } from './UpgradeModalHeader';
import { UpgradeModalNavigation } from './UpgradeModalNavigation';
import { UpgradeModalBody } from './UpgradeModalBody';

interface UpgradeModalProps {
  onClose: () => void;
  onReadMore: () => void;
  onUpgradeNow: () => void;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({
  onClose,
  onReadMore,
  onUpgradeNow,
}) => {
  return (
    <Modal isOpen closeOnOverlayClick={false} onClose={onClose} size="xl">
      <ModalOverlay bg="blackAlpha.500" />
      <ModalContent borderRadius="lg" data-testid="upgrade-modal-content">
        <UpgradeModalHeader onClose={onClose} />
        <UpgradeModalBody />
        <UpgradeModalNavigation
          onReadMore={onReadMore}
          onUpgradeNow={onUpgradeNow}
        />
      </ModalContent>
    </Modal>
  );
};

export default UpgradeModal;
