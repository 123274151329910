import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { TopBanner as AddToHomeScreenShortcutTopBanner } from 'components/HomeView/AddToHomeScreenShortcut';

import Header from '../Header';

const NotificationHeader: React.FC = () => {
  return (
    <Flex
      position={{ base: 'fixed', lg: 'relative' }}
      top="0"
      left="0"
      right="0"
      flexDirection="column"
      zIndex="100"
    >
      <Box
        position="relative"
        width="100%"
        zIndex="10"
        pl={{ base: '0', lg: '278px' }}
      >
        <AddToHomeScreenShortcutTopBanner />
      </Box>
      <Box position="relative" zIndex="11">
        <Header />
      </Box>
    </Flex>
  );
};

export default NotificationHeader;
