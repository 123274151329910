import { Expose, plainToClass } from 'class-transformer';

export interface ILoungePass {
  imageUrl: string;
  passCode: string;
  passengerName: string;
  validUntil: string;
}

export class LoungePass implements ILoungePass {
  @Expose() imageUrl: string;
  @Expose() passCode: string;
  @Expose() passengerName: string;
  @Expose() validUntil: string;
}

export function transformToLoungePassClass(
  loungePass: ILoungePass,
): LoungePass {
  return plainToClass(LoungePass, loungePass);
}
