import React from 'react';

import {
  Button,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Info } from '@airhelp/icons';
import EmptyStateCreditCard from 'assets/images/billing/empty-state-credit-card.png';
import { useTranslation } from 'react-i18next';
import LabelValue from '../../common/LabelValue/LabelValue';
import { BillingManagement } from '@airhelp/plus';
import { format } from 'date-fns';
import UpdateCreditCard from '../UpdateCreditCard/UpdateCreditCard';
import { useUpdateCreditCard } from '@airhelp/plus/react';

interface IComponent {
  billingManagement: BillingManagement;
}

const NoCardView: React.FC<IComponent> = ({ billingManagement }) => {
  const { t } = useTranslation();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { subscription } = billingManagement;
  const { nextChargeAmount, nextChargeDate, nextChargeCurrencySymbol } =
    subscription;

  const {
    mutate: handleUpdatePaymentMethod,
    data: updateCreditCardData,
    isPending: isLoading,
    isError,
  } = useUpdateCreditCard({
    onSuccess: () => {
      onOpen();
    },
    onError: () => {
      toast({
        title: <span>{t('errors.something_went_wrong')}</span>,
        status: 'error',
        duration: 4000,
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: <Info color="greyscale.100" boxSize={5} alignSelf="center" />,
      });
    },
  });

  return (
    <>
      <Center
        bg="greyscale.100"
        borderRadius="lg"
        px={{ base: 4, md: 8 }}
        pt={{ base: 8, md: '128px' }}
        pb={{ base: 4, md: '128px' }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={{ base: '100%', md: '375px' }}
          textAlign="center"
        >
          <Image
            src={EmptyStateCreditCard}
            maxWidth="200px"
            width="100%"
            mb={8}
          />
          <Text fontSize="lg" fontWeight="medium">
            {t('payment_management.add_payment_method')}
          </Text>
          <Text fontSize="md" color="greyscale.600">
            {t('payment_management.add_payment_method_description')}
          </Text>
          <Button
            size="s"
            onClick={() => handleUpdatePaymentMethod()}
            width="100%"
            my={{ base: 4, md: 5 }}
            disabled={isError || isLoading}
            isLoading={isLoading}
            data-testid="btn-add-payment-method"
          >
            {t('payment_management.add_payment_method')}
          </Button>
          <VStack spacing={{ base: 2, md: 3 }} width="100%">
            <LabelValue
              label={t('payment_management.membership_price')}
              value={`${nextChargeCurrencySymbol}${nextChargeAmount}  / ${t('common.year')}`}
            />
            <LabelValue
              label={t('payment_management.next_payment')}
              value={format(nextChargeDate, 'dd.MM.yyyy')}
            />
          </VStack>
        </Flex>
      </Center>
      {updateCreditCardData ? (
        <UpdateCreditCard
          isOpen={isOpen}
          onClose={onClose}
          updateCreditCardData={updateCreditCardData}
          isUpdate
        />
      ) : null}
    </>
  );
};

export default NoCardView;
