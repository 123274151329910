import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Text } from '@chakra-ui/react';

import CheckoutLayout from 'components/Layouts/CheckoutLayout';
import StripeCheckoutWidget from 'components/StripeCheckoutWidget';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type Offer } from 'models/Offers';
import { usePreferencesStore } from 'stores/preferences';
import { getDashboardUrl } from 'utils/sites';
import {
  CheckoutEvent,
  useTrackCheckout,
  useTrackOnce,
} from 'utils/tracking/hooks';
import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';

import MembershipCheckoutBanner from './MembershipCheckoutBanner';
import PlanDetails from './PlanDetails';
import { getMembershipPlan } from '../membershipHelper';

const newUrlSearchParams = new URLSearchParams(location.search);

const getCallbackUrlWithParams = (channel?: string, tier?: string) => {
  const channelReservedWords = [
    'complete',
    'essential',
    'smart',
    'pro',
    'lite',
  ];

  const claimId: string = newUrlSearchParams.get('claimId') || '';
  const claimEnquiryUuid: string =
    newUrlSearchParams.get('claimEnquiryUuid') || '';
  const brand: string = newUrlSearchParams.get('brand') || '';
  const paymentId: string = newUrlSearchParams.get('paymentId') || '';
  const renew: string = newUrlSearchParams.get('renew') || '';

  const callbackUrl = new URL(
    `${getDashboardUrl()}${generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.PROCESSING_PAYMENT_PAGE)}`,
    window.location.origin,
  );

  if (tier) {
    callbackUrl.searchParams.set('tier', tier);
  }

  if (renew) {
    callbackUrl.searchParams.set('renew', renew);
  }

  if (channel && !channelReservedWords.includes(channel)) {
    callbackUrl.searchParams.set('channel', channel);
  }

  if (claimId) {
    callbackUrl.searchParams.set('claimId', claimId);
  }

  if (claimEnquiryUuid) {
    callbackUrl.searchParams.set('claimEnquiryUuid', claimEnquiryUuid);
  }

  if (brand) {
    callbackUrl.searchParams.set('brand', brand);
  }

  if (paymentId) {
    callbackUrl.searchParams.set('paymentId', paymentId);
  }

  return callbackUrl;
};

interface ICheckoutView {
  offers: Record<string, Offer>;
  renewOffer?: Offer;
  upgradeOffer?: Offer;
  isUpgradeOffer?: boolean;
  isManualRenew?: boolean;
}

const CheckoutView: React.FC<ICheckoutView> = ({
  offers,
  renewOffer,
  upgradeOffer,
  isUpgradeOffer = false,
  isManualRenew = false,
}) => {
  const { t } = useTranslation();
  const { userProfile } = useMyProfile();
  const { checkoutDataDispatch, checkoutData } = useContext(TrackingContext);
  const currency = usePreferencesStore((state) => state.currency);

  const { trackCheckout, trackPaymentFailed, trackSubscriptionFunnel } =
    useTrackCheckout();

  const channel: string = newUrlSearchParams.get('channel') || '';
  const claimId: string = newUrlSearchParams.get('claimId') || '';
  const brand: string = newUrlSearchParams.get('brand') || '';
  const tier: string = newUrlSearchParams.get('tier') || '';

  const productDetails = getMembershipPlan({
    offers,
    renewOffer,
    tier,
    isManualRenew,
    isUpgradeOffer,
    upgradeOffer,
  });

  const callbackUrl = getCallbackUrlWithParams(channel, tier);

  useTrackOnce(
    (setTracked) => {
      if (userProfile && checkoutData.productDetails) {
        trackSubscriptionFunnel('Checkout');
        setTracked();
      }
    },
    [userProfile, offers, renewOffer, trackSubscriptionFunnel, checkoutData],
  );

  useEffect(() => {
    checkoutDataDispatch({ type: CheckoutActionTypes.Reset });
    checkoutDataDispatch({
      type: CheckoutActionTypes.Update,
      payload: { productDetails, brand },
    });
  }, [productDetails, offers, renewOffer]);

  useEffect(() => {
    if (checkoutData.productDetails !== null) {
      trackCheckout(CheckoutEvent.BEGIN_CHECKOUT);
    }
  }, [checkoutData.productDetails]);

  const onPaymentSubmit = useCallback(() => {
    trackCheckout(CheckoutEvent.ADD_PAYMENT_INFO);
  }, [trackCheckout]);

  if (!productDetails) {
    return null;
  }

  return (
    <>
      <MembershipCheckoutBanner productDetails={productDetails} />
      <CheckoutLayout
        side={
          <PlanDetails
            productDetails={productDetails}
            callbackUrl={callbackUrl}
            claimId={claimId}
            currency={currency}
            isUpgradeOffer={isUpgradeOffer}
            isManualRenew={isManualRenew}
          />
        }
        showCurrencySwitcher={!isManualRenew}
        currency={currency}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={5}
          data-testid="card-details"
        >
          {t('payment.checkout.payment_method')}
        </Text>
        <StripeCheckoutWidget
          key={currency}
          upgrade={isUpgradeOffer}
          productName={productDetails.name}
          callbackUrl={callbackUrl.toString()}
          requireMarketing={!userProfile?.consents.marketingConsent}
          recurring
          footer={
            <InfoBox
              isChat
              bg="greyscale.100"
              icon={<Info color="primary.500" />}
              variant="secondary"
              mt={6}
              mb={6}
            >
              {t('memberships.membership_billed_as_annual_payments')}
            </InfoBox>
          }
          onPaymentSubmit={onPaymentSubmit}
          onPaymentFailed={trackPaymentFailed}
        />
      </CheckoutLayout>
    </>
  );
};

export default CheckoutView;
