import React, { type ChangeEvent } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';

import { removeWhitespaces, validateSwift } from 'utils/validations';

const SWIFT_CODE_MAX_LENGTH = 11;
const SWIFT_CODE_MIN_LENGTH = 8;

const removeWhitespceOnChange = (
  event: ChangeEvent<HTMLInputElement>,
  formOnChange: (value) => void,
) => {
  const value = removeWhitespaces(event?.target.value);
  value ? formOnChange(value) : formOnChange('');
};

const swift = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  return (
    <Controller
      name="swift"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        minLength: {
          value: SWIFT_CODE_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.en_swift'),
            min: SWIFT_CODE_MIN_LENGTH,
          }),
        },
        validate: (value: string) => validateSwift(value, t),
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.en_swift')}
            value={value}
            placeholder={t('common.en_swift')}
            onChange={(event) => {
              removeWhitespceOnChange(event, onChange);
            }}
            maxLength={SWIFT_CODE_MAX_LENGTH}
            data-testid="swift-number"
          />
        </Box>
      )}
    />
  );
};
export default swift;
