import React from 'react';

import { VStack } from '@chakra-ui/react';

import { PageLoader } from 'elements';

import AirLuggageInsuranceView from './AirLuggageInsuranceView';
import AirPayoutInsuranceView from './AirPayoutInsuranceView';
import {
  useAirLuggageJourneys,
  useAirPayoutJourneys,
} from '@airhelp/plus/react';
import { Journey } from '@airhelp/plus';

const InsuranceView = ({ journey }: { journey: Journey }) => {
  const hasAirPayout = journey.computed.hasAirPayout;
  const hasAirLuggage = journey.computed.hasAirLuggage;

  const {
    data: airPayoutJourneys,
    isPending: isPendingAirPayout,
    isFetching: isFetchingAirPayout,
  } = useAirPayoutJourneys({
    params: {
      journeyId: journey.id,
    },
    enabled: hasAirPayout,
  });

  const {
    data: airLuggageJourneys,
    isPending: isPendingAirLuggage,
    isFetching: isFetchingAirLuggage,
  } = useAirLuggageJourneys({
    params: {
      journeyId: journey.id,
    },
    enabled: hasAirLuggage,
  });
  if (
    (isPendingAirPayout || isFetchingAirPayout) &&
    (isPendingAirLuggage || isFetchingAirLuggage)
  ) {
    return <PageLoader />;
  }

  return (
    <VStack spacing={4} mb={4}>
      {airPayoutJourneys ? (
        <AirPayoutInsuranceView
          journey={journey}
          airPayoutJourneys={airPayoutJourneys}
        />
      ) : null}
      {airLuggageJourneys ? (
        <AirLuggageInsuranceView
          journey={journey}
          airLuggageJourneys={airLuggageJourneys}
        />
      ) : null}
    </VStack>
  );
};

export default InsuranceView;
