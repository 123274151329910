import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { AirhelpPlus } from '@airhelp/logos';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { getYear } from 'date-fns';

import CurrencySwitcher from 'elements/CurrencySwitcher';
import LanguageSwitcher from 'elements/LanguageSwitcher';
import { usePreferencesStore } from 'stores/preferences';
import { type CurrencyCode } from 'stores/preferences/currencies';

const openModalButtonProps = {
  variant: 'subtle',
  bg: 'none',
  color: 'font.primary',
  _hover: { md: { color: 'primary.500' } },
  _focus: { bg: 'none' },
  _active: { bg: 'none' },
  border: 'none',
  fontSize: 'md',
  cursor: 'pointer',
  fontWeight: 'medium',
  px: 0,
  minWidth: 'content',
};

interface HeaderProps {
  showCurrencySwitcher: boolean;
  currency?: CurrencyCode;
  handleCurrencyChange: (currency: CurrencyCode) => void;
}

const Header: React.FC<HeaderProps> = ({
  showCurrencySwitcher,
  currency,
  handleCurrencyChange,
}) => {
  return (
    <Flex mb={{ base: 10, lg: 16 }} justifyContent="space-between" width="100%">
      <AirhelpPlus
        width={{ base: '110px', lg: '150px' }}
        height="auto"
        data-testid="airhelp-logo"
      />
      <Box mx={-5} my={-3} display={{ base: 'inline-block', lg: 'none' }}>
        {showCurrencySwitcher ? (
          <CurrencySwitcher
            onCurrencyChange={handleCurrencyChange}
            render={(openModalHandler) => (
              <Button
                onClick={openModalHandler}
                data-testid="currency-switcher-button"
                {...openModalButtonProps}
              >
                {currency}
              </Button>
            )}
          />
        ) : null}
        <LanguageSwitcher />
      </Box>
    </Flex>
  );
};

const Footer = () => {
  const copyrightDate = getYear(new Date());
  const { t } = useTranslation();

  return (
    <Flex width="100%" justifyContent="flex-end" pt={{ base: 4, md: 0 }}>
      <Text fontSize="sm" color="greyscale.500">
        {t('common.en_copyright', { date: copyrightDate })}
      </Text>
    </Flex>
  );
};

interface CheckoutLayoutProps extends PropsWithChildren {
  side: React.ReactNode;
  showCurrencySwitcher?: boolean;
  currency?: CurrencyCode;
}

const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({
  children,
  side,
  showCurrencySwitcher = false,
  currency,
}) => {
  const setCurrency = usePreferencesStore((state) => state.setCurrency);

  return (
    <Box width="100%" bg="greyscale.100">
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        data-testid="membership-checkout-step"
        minH="100vh"
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          bg="greyscale.200"
          width={{ base: '100%', xlg: '50%' }}
        >
          <Flex
            py={8}
            px={{ base: 4, lg: 8 }}
            height="100%"
            flexDirection="column"
            width={{ base: '100%', xlg: '500px' }}
          >
            <Header
              showCurrencySwitcher={showCurrencySwitcher}
              currency={currency}
              handleCurrencyChange={setCurrency}
            />
            {side}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="start"
          width="100%"
          bg="greyscale.100"
        >
          <Flex
            py={8}
            px={{ base: 4, xlg: 12 }}
            flexDirection="column"
            justifyContent="space-between"
            width={{ base: '100%', xlg: '586px' }}
            height="100%"
          >
            <Flex
              mb={{ base: 10, lg: 16 }}
              width="100%"
              display={{ base: 'none', lg: 'flex' }}
              justifyContent="flex-end"
            >
              <Box mx={-5} my={-3}>
                {showCurrencySwitcher ? (
                  <CurrencySwitcher
                    onCurrencyChange={setCurrency}
                    render={(openModalHandler) => (
                      <Button
                        onClick={openModalHandler}
                        data-testid="currency-switcher-button"
                        {...openModalButtonProps}
                      >
                        {currency}
                      </Button>
                    )}
                  />
                ) : null}
                <LanguageSwitcher />
              </Box>
            </Flex>
            <Box height="100%">{children}</Box>
            <Footer />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CheckoutLayout;
