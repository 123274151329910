import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { PlusMath } from '@airhelp/icons';
import { IconButton, type StackProps, Text, VStack } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';

import { TILE_STYLE } from '../config';

interface BlankTileProps extends StackProps {
  actionSource?: string;
}

const BlankTile: React.FC<BlankTileProps> = ({
  actionSource = 'dashboard',
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const handleClick = () => {
    trackCtaClick('add trip tile', 'journeys page');
    navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE), {
      state: {
        from: actionSource,
      },
    });
  };

  return (
    <VStack
      onClick={handleClick}
      data-testid="btn-add-trip-blank-tile"
      justifyContent="center"
      spacing={4}
      {...TILE_STYLE}
      {...props}
      _hover={{
        cursor: 'pointer',
        borderColor: 'primary.500',
        boxShadow: '0px 0px 0px 4px #DFEDFF',
        bgColor: 'primary.100',
      }}
    >
      <IconButton
        icon={<PlusMath fontSize="md" />}
        isRound
        aria-label="add trip"
        size="sm"
        variant="subtle"
        bgColor="primary.100"
        _groupHover={{ bgColor: 'greyscale.100' }}
      />
      <Text fontSize="sm" color="greyscale.500">
        {t('journeys.add_trip')}
      </Text>
    </VStack>
  );
};

export default BlankTile;
