import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';
import { addressFieldMaxLength } from 'utils/validations';

const bankAddress = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  return (
    <Controller
      name="bankAddress"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: { value: /^\S+/, message: t('errors.required') },
        maxLength: {
          value: addressFieldMaxLength,
          message: t('errors.too_long', {
            attribute: t('common.bank_address'),
            max: addressFieldMaxLength,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.bank_address')}
            value={value}
            placeholder={t('common.bank_address')}
            onChange={onChange}
            data-testid="bank-address"
          />
        </Box>
      )}
    />
  );
};

export default bankAddress;
