import { QueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';

const MAX_RETRY_COUNT = 2;
const RETRY_WHITELIST_ERROR_CODES = [401, 404];

export const queryRetry = (failureCount: number, error: unknown): boolean => {
  if (failureCount >= MAX_RETRY_COUNT) {
    return false;
  }

  // do not retry on whitelisted error codes
  if (error instanceof HTTPError) {
    return !RETRY_WHITELIST_ERROR_CODES.includes(error.response?.status);
  }

  return true;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      // 10 minutes cache
      gcTime: 10 * 60 * 1000,
      retry: queryRetry,
    },
  },
});

export default queryClient;
