import type React from 'react';

import {
  type GenerateInstance,
  type ReducerAction as JourneyReducerAction,
} from 'types/reducers';
import { updateFlightsDateBoundaries } from 'utils/journey/journey';
import {
  EditableFlight,
  EditableItinerary,
  EditableJourney,
  FellowPassenger,
  Flight,
} from '@airhelp/plus';

export enum EditableTypes {
  UpdateName = 'UPDATE_NAME',
  UpdateFlight = 'UPDATE_FLIGHT',
  UpdateFlightDepartueDate = 'UPDATE_FLIGHT_DEPARTURE_DATE',
  UpdateConnectingFlightAirport = 'UPDATE_CONNECTING_FLIGHT_AIRPORT',
  AddFlight = 'ADD_FLIGHT',
  AddItinerary = 'ADD_ITINERARY',
  RemoveItineraryByIndex = 'REMOVE_ITINERARY_BY_INDEX',
  RemoveFlight = 'REMOVE_FLIGHT',
  RemoveAllConnectingFlights = 'REMOVE_ALL_CONNECTING_FLIGHTS',
  ClearEmptyFlights = 'CLEAR_EMPTY_FLIGHTS',
  AddFellowPassenger = 'ADD_FELLOW_PASSENGER',
  UpdateFellowPassenger = 'UPDATE_FELLOW_PASSENGER',
  DeleteFellowPassenger = 'DELETE_FELLOW_PASSENGER',
  SetApplyAirLuggage = 'SET_APPLY_ADD_AIR_LUGGAGE',
}

export type Action =
  | JourneyReducerAction<
      EditableTypes.AddItinerary,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.SetApplyAirLuggage,
      { applyAirLuggage: boolean },
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UpdateName,
      { name?: string | null },
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UpdateFlight,
      EditableFlight,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UpdateFlightDepartueDate,
      { departureDate: Date; id: number },
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UpdateConnectingFlightAirport,
      EditableFlight,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.AddFlight,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.RemoveItineraryByIndex,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.RemoveFlight,
      Flight,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.RemoveAllConnectingFlights,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.ClearEmptyFlights,
      undefined,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.AddFellowPassenger,
      FellowPassenger,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.UpdateFellowPassenger,
      FellowPassenger,
      { itineraryIndex?: number }
    >
  | JourneyReducerAction<
      EditableTypes.DeleteFellowPassenger,
      FellowPassenger,
      { itineraryIndex?: number }
    >;

const generateTempId = () => Math.floor(Math.random() * Date.now());
const getFlightIndex = (flights, action) =>
  flights.findIndex((f) => f.id === action.payload.id);

const generateFlight: GenerateInstance<EditableFlight> = (
  minDate: string | null = null,
) => ({
  id: generateTempId(),
  departureAirport: null,
  departureAirportCode: null,
  arrivalAirport: null,
  arrivalAirportCode: null,
  airline: null,
  airlineIdentifier: null,
  flightNumber: null,
  localDepartureDate: null,
  metadata: {
    minDate,
    maxDate: null,
  },
});

const generateItinerary: GenerateInstance<EditableItinerary> = () => ({
  id: generateTempId(),
  flights: [generateFlight()],
  fellowPassengers: [],
});

export const generateEmptyJourney: GenerateInstance<EditableJourney> = () => ({
  id: generateTempId(),
  name: '',
  itineraries: [generateItinerary()],
  applyAirLuggage: false,
});

export const editableJourneyReducer: React.Reducer<EditableJourney, Action> = (
  journey,
  action,
) => {
  const itineraryIndex = action?.itineraryIndex || 0;
  const { itineraries } = journey;
  const itinerary = itineraries[itineraryIndex];

  const { flights, fellowPassengers } = itinerary;

  const handleAction = (action: Action) => {
    switch (action.type) {
      case EditableTypes.AddItinerary: {
        const isReturnFlight = flights.length > 0;

        if (isReturnFlight) {
          const lastFlightIndex = itineraries[0].flights.length - 1;
          const minFlightDate =
            itineraries[0].flights[lastFlightIndex].localDepartureDate;

          const newItinerary = {
            id: generateTempId(),
            flights: [generateFlight(minFlightDate)],
            fellowPassengers: [],
          };

          const newItineraries = [...itineraries, newItinerary];
          return {
            ...journey,
            itineraries: newItineraries,
          };
        }
        const newItineraries = [...itineraries, generateItinerary()];
        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.SetApplyAirLuggage: {
        const { applyAirLuggage } = action.payload;

        return {
          ...journey,
          applyAirLuggage,
        };
      }
      case EditableTypes.UpdateName: {
        const { name } = action.payload;

        return {
          ...journey,
          name,
        };
      }
      case EditableTypes.UpdateFlight: {
        const newFlights = [...flights];
        newFlights[getFlightIndex(flights, action)] = action.payload;

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.UpdateFlightDepartueDate: {
        const newFlights = [...flights];
        const flightIndex = getFlightIndex(flights, action);

        updateFlightsDateBoundaries(
          flightIndex,
          action.payload.departureDate,
          newFlights,
        );

        newFlights[flightIndex].localDepartureDate =
          action.payload.departureDate;
        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.UpdateConnectingFlightAirport: {
        const newFlights = [...flights];

        const updatedFlight = action.payload;
        const updatedFlightIndex = getFlightIndex(flights, action);

        newFlights[updatedFlightIndex] = updatedFlight;

        const prevFlightIndex = updatedFlightIndex - 1;

        newFlights[prevFlightIndex] = {
          ...newFlights[prevFlightIndex],
          arrivalAirport: updatedFlight.departureAirport,
          arrivalAirportCode: updatedFlight.departureAirportCode,
        };

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.AddFlight: {
        const { itineraries: prevItineraries } = journey;

        return {
          ...journey,
          itineraries: prevItineraries.map((itinerary, index) => {
            const prevFlights = itinerary.flights;

            let newFlights = prevFlights.map((flight) => ({ ...flight }));

            if (index == itineraryIndex) {
              const lastFlight = newFlights.pop() as EditableFlight;

              const addedFlight = {
                ...generateFlight(),
                arrivalAirport: lastFlight?.arrivalAirport,
                arrivalAirportCode: lastFlight?.arrivalAirportCode,
              };

              const updatedLastFlight = {
                ...lastFlight,
                arrivalAirport: null,
                arrivalAirportCode: null,
              };

              newFlights = [...newFlights, updatedLastFlight, addedFlight];
            }

            return {
              ...itinerary,
              flights: newFlights,
            };
          }),
        };
      }
      case EditableTypes.RemoveItineraryByIndex: {
        const removedItineraryIndex = action.itineraryIndex;
        const newItineraries = [...itineraries];

        if (removedItineraryIndex !== undefined) {
          newItineraries.splice(removedItineraryIndex, 1);
        }

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.RemoveFlight: {
        const removedFlightIndex = getFlightIndex(flights, action);
        const removedFlight = action.payload;
        const precedingFlights = [...flights.slice(0, removedFlightIndex)];
        const followingFlights = [...flights.slice(removedFlightIndex + 1)];

        let lastPrecedingFlight = precedingFlights.pop() as EditableFlight;

        lastPrecedingFlight = {
          ...lastPrecedingFlight,
          arrivalAirport:
            followingFlights[0]?.departureAirport ||
            removedFlight.arrivalAirport,
        };

        const newItineraries = [...itineraries];
        const newFlights = [
          ...precedingFlights,
          lastPrecedingFlight,
          ...followingFlights,
        ];

        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.RemoveAllConnectingFlights: {
        const firstFlight = flights[0];
        const prevLastFlight = flights[flights.length - 1];

        const newFlights = [
          {
            ...firstFlight,
            arrivalAirport: prevLastFlight?.arrivalAirport,
            arrivalAirportCode: prevLastFlight?.arrivalAirportCode,
          },
        ];

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.ClearEmptyFlights: {
        const filteredFlights = flights.filter((flight) =>
          Boolean(flight.departureAirport),
        );

        if (filteredFlights.length === flights.length || flights.length === 1) {
          return {
            ...journey,
          };
        }
        const prevLastFlight = flights[flights.length - 1];
        const newFlights = filteredFlights.map((flight, index) => ({
          ...flight,
          arrivalAirport:
            filteredFlights[index + 1]?.departureAirport ||
            prevLastFlight.arrivalAirport,
          arrivalAirportCode:
            filteredFlights[index + 1]?.departureAirportCode ||
            prevLastFlight.arrivalAirportCode,
        }));

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].flights = newFlights;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.AddFellowPassenger: {
        const addedFellowPassenger = action.payload;

        return {
          ...journey,
          itineraries: itineraries.map((itinerary, index) => {
            const isEditedItinerary = itineraryIndex === index;

            return {
              ...itinerary,
              fellowPassengers: isEditedItinerary
                ? [addedFellowPassenger, ...itinerary.fellowPassengers]
                : [...itinerary.fellowPassengers],
            };
          }),
        };
      }
      case EditableTypes.UpdateFellowPassenger: {
        const editedFellowPassenger = action.payload;
        const fellowPassenger = fellowPassengers.find(
          (passenger) => passenger.id === editedFellowPassenger.id,
        );
        const newItineraries = [...itineraries];

        if (!fellowPassenger) {
          newItineraries[itineraryIndex].fellowPassengers = [
            ...fellowPassengers,
          ];

          return {
            ...journey,
            itineraries: newItineraries,
          };
        }

        const updatedFellowPassenger = {
          ...fellowPassenger,
          ...editedFellowPassenger,
        };

        const fellowPassengersWithoutEdited = fellowPassengers.filter(
          (passenger) => passenger.id !== editedFellowPassenger.id,
        );

        newItineraries[itineraryIndex].fellowPassengers = [
          ...fellowPassengersWithoutEdited,
          updatedFellowPassenger,
        ];

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }
      case EditableTypes.DeleteFellowPassenger: {
        const deletedFellowPassenger = action.payload;
        const newFellowPassengersAfterDeleted = fellowPassengers.filter(
          (passenger) => passenger.id !== deletedFellowPassenger.id,
        );

        const newItineraries = [...itineraries];
        newItineraries[itineraryIndex].fellowPassengers =
          newFellowPassengersAfterDeleted;

        return {
          ...journey,
          itineraries: newItineraries,
        };
      }

      default:
        throw new Error(
          // @ts-expect-error - typescript error would be thrown if action type is not handled, so action.type here is treated as never
          `Action of type "${action.type}" is missing or is not a function`,
        );
    }
  };

  return handleAction(action);
};
