import React from 'react';

import { Check } from '@airhelp/icons';
import { Flex, Text } from '@chakra-ui/react';

interface IComponent {
  benefits: { content: string; key: string }[];
}

const BenefitsList: React.FC<IComponent> = ({ benefits }) => {
  return (
    <Flex flexDir="row" flexWrap="wrap" rowGap={4}>
      {benefits.map((benefit) => (
        <Flex key={benefit.key} alignItems="center" flexBasis="50%" pr={2}>
          <Check boxSize={4} mr={2} />
          <Text fontSize="sm" lineHeight="1.25em">
            {benefit.content}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default BenefitsList;
