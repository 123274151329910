import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PriceTag } from '@airhelp/icons';
import {
  Box,
  Center,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import perkLogo from 'assets/images/perks/logo-airalo.svg';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { CopyToClipboardButton } from 'elements';
import { useTrackEvent } from 'utils/tracking/hooks';

import { getAiraloLinkByLocale } from './airaloLinkHelper';
import BenefitsList from '../BenefitsList';
import GoToSiteButton from '../GoToSiteButton';

interface IComponent {
  code: string;
  discount: number;
}

const TRACKING_PROVIDER_NAME = 'airalo';

const ESim: React.FC<IComponent> = ({ code, discount }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { trackPageInteractions } = useTrackEvent();

  const siteUrl = getAiraloLinkByLocale(locale);

  const benefits = [
    {
      content: t('dashboard.perks.esim.instant_connectivity'),
      key: 'instant_connectivity',
    },
    {
      content: t('dashboard.perks.esim.global_coverage'),
      key: 'global_coverage',
    },
    {
      content: t('dashboard.perks.esim.affordable_and_transparent'),
      key: 'affordable_and_transparent',
    },
    { content: t('dashboard.perks.esim.support'), key: 'support' },
  ];

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(siteUrl, '_blank');
  };

  const onCodeCopied = () => {
    trackPageInteractions(
      'promo code copy',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );
  };

  return (
    <VStack
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      minHeight="100%"
    >
      {/* top section */}
      <Box>
        <HStack
          pb={{ base: 4, md: 8 }}
          justifyContent="space-between"
          alignItems="center"
          mb={{ base: 2, md: 0 }}
          width="100%"
        >
          <Image src={perkLogo} h={{ base: 4, md: 6 }} />
          <Center
            borderRadius="sm"
            px={2}
            py={3}
            h={4}
            alignItems="center"
            background="success.500"
          >
            <PriceTag color="white" mr={1} boxSize={4} />
            <Text
              color="white"
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
            >
              {t('dashboard.perks.percentage_off', { percentage: discount })}
            </Text>
          </Center>
        </HStack>

        <Heading variant={{ base: 'h5', md: 'h4' }} mb={4}>
          <Trans
            i18nKey="dashboard.perks.esim.percentage_off_your_esim"
            values={{ percentage: discount }}
            components={{
              strong: (
                <Text
                  display="inline"
                  fontSize="xl"
                  fontWeight="bold"
                  color="danger.500"
                />
              ),
            }}
          />
        </Heading>

        <Text
          fontWeight="medium"
          fontSize={{ base: 'sm', md: 'md' }}
          pb={{ base: 4, md: 8 }}
        >
          {t('dashboard.perks.esim.stay_connected_when_your_travel', {
            discount,
          })}
        </Text>

        <Text fontSize={{ base: 'sm', md: 'md' }} color="greyscale.600">
          {t('dashboard.perks.esim.even_found_desperately_searching')}
        </Text>

        <Box my={{ base: 4, md: 8 }}>
          <BenefitsList benefits={benefits} />
        </Box>
      </Box>

      {/* bottom section */}
      <Box p={4} background="greyscale.300" borderRadius="xl" width="100%">
        <Text fontSize="sm" fontWeight="medium" pb={1}>
          {t('dashboard.perks.get_your_promocode')}
        </Text>

        <CopyToClipboardButton
          id="perk-code"
          inputValue={code}
          fullWidth
          valueAlign="left"
          onCopy={onCodeCopied}
        />

        <Text fontSize="md" color="greyscale.600" pt={2} pb={6}>
          {t('dashboard.perks.use_it_on', { url: 'airalo.com' })}
        </Text>

        <GoToSiteButton
          onClick={goToSite}
          label={t('dashboard.perks.esim.get_esim_card')}
        />
      </Box>
    </VStack>
  );
};

export default ESim;
