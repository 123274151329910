import { get, AHPLUS_API_URL } from 'api/ky';
import { Locale } from 'contexts/LocaleContext/locales';

export const AIR_BUNDLE_SUBSCRIPTION_URL = `${AHPLUS_API_URL}/api/v2/user_policy/air_bundle_subscription`;

export const fetchAirBundleSubscription = async () => {
  return await get(AIR_BUNDLE_SUBSCRIPTION_URL);
};

export const redirectToLoungePassSite = (locale: Locale) => {
  window.open(
    `${AHPLUS_API_URL}/api/v2/user_profiles/lounge_pass_perk/?locale=${locale}`,
    '_blank',
  );
};
