import { AHPLUS_API_URL, get, patch } from 'api/ky';
import { type IConsents } from 'models/userProfile/Consents';

export const FETCH_MY_PROFILE_URL = `${AHPLUS_API_URL}/api/v2/user_profiles/me`;
export const FETCH_LOUNGE_PASSES_URL = `${AHPLUS_API_URL}/api/v2/user_profiles/lounge_passes`;
export const FETCH_CONSENTS_URL = `${AHPLUS_API_URL}/api/v2/user_profiles/consents`;
export const UPDATE_CONSENTS_URL = `${AHPLUS_API_URL}/api/v2/user_profiles/consents`;

export const fetchMyProfile = async () => {
  return await get(FETCH_MY_PROFILE_URL);
};

export const fetchConsents = async () => {
  return await get(FETCH_CONSENTS_URL);
};

export const updateConsents = async (consents: IConsents) => {
  return await patch(UPDATE_CONSENTS_URL, consents);
};

export const fetchLoungePasses = async (token = '') => {
  return await get(`${FETCH_LOUNGE_PASSES_URL}?token=${token}`);
};
