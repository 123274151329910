import React, {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTracking } from 'react-tracking';

import config from 'config/config';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { PROVIDERS } from 'utils/tracking/providers';

import { getProductTier, getProductVariant } from './productInfo';

const { zowieChat: zowieChatConfig } = config;

interface ZowieContextInterface {
  openZowieChat: () => void;
}

declare const Chatbotize: {
  init: (arg: ChatbotizeInitOptions) => void;
  logout: () => void;
  hide: () => void;
  show: () => void;
  open: () => void;
  close: () => void;
  updateMetadata: (updateMetadata: ChatbotizeMetaData) => void;
};

interface ChatbotizeMetaData {
  locale?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  extraParams?: {
    currentPlan?: string;
    productVariant?: string;
  };
}

interface ChatbotizePosition {
  desktopBottom: number;
  desktopRight: number;
  mobileBottom: number;
  mobileRight: number;
  mobileOffset: number;
}

interface ChatbotizeInitOptions {
  instanceId: string;
  showOnLoad: boolean;
  startOnOpen: boolean;
  allowEndChat: boolean;
  headerMode: string;
  position: ChatbotizePosition;
  metadata: ChatbotizeMetaData;
  onEndChat: () => void;
  onOpen: () => void;
  onLoaded: () => void;
}

export const ZowieContext = createContext<ZowieContextInterface>({
  openZowieChat: () => null,
});

const ZowieContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { userProfile } = useMyProfile();
  const { trackEvent } = useTracking();
  const { locale } = useLocale();

  const [isInitialized, setIsInitialized] = useState(false);

  const chatbotizeContainer = document.getElementById('chatbotize');

  const initializeZowieChat = () => {
    if (!zowieChatConfig.enabled) {
      return;
    }
    if (!userProfile) {
      return;
    }

    const productVariant = getProductVariant(userProfile);
    const productTier = getProductTier(userProfile);

    if (chatbotizeContainer) {
      Chatbotize.init({
        instanceId: zowieChatConfig.instanceIdAirhelp,
        showOnLoad: false,
        startOnOpen: true,
        allowEndChat: true,
        headerMode: 'white',
        position: {
          desktopBottom: 80,
          desktopRight: 32,
          mobileBottom: 126,
          mobileRight: 32,
          mobileOffset: 992,
        },
        metadata: {
          locale,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          phoneNumber: userProfile.phoneNumber,
          email: userProfile.email,
          extraParams: {
            currentPlan: productTier,
            productVariant,
          },
        },

        onLoaded: () => {
          setIsInitialized(true);
        },

        onOpen: () => {
          trackEvent({
            providers: [PROVIDERS.DataLayer],
            name: 'GAEvent',
            eventCategory: 'Zowie',
            eventAction: 'clicked',
            eventLabel: 'AHPconversationStarted',
          });

          Chatbotize.show();
        },

        onEndChat: () => {
          Chatbotize.logout();
          Chatbotize.hide();
        },
      });
    }
  };

  const openZowieChat = () => {
    if (!isInitialized) {
      initializeZowieChat();
    }

    Chatbotize.open();
  };

  useEffect(() => {
    if (!isInitialized) {
      initializeZowieChat();
    }
  }, [userProfile]);

  // Logout from chatbotize when user logs out to initialize widget with chosen language
  useEffect(() => {
    if (isInitialized) {
      Chatbotize.logout();
    }
    initializeZowieChat();
  }, [locale]);

  return (
    <ZowieContext.Provider value={{ openZowieChat }}>
      {children}
    </ZowieContext.Provider>
  );
};

export const useZowie = () => {
  return useContext(ZowieContext);
};

export default ZowieContextProvider;
