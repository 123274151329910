import React from 'react';
import Slider from 'react-slick';

import { Box, Flex, Image } from '@chakra-ui/react';

import styles from 'components/Carousel/Carousel.module.scss';
import defaultSettings from 'components/Carousel/defaultSettings';
import { Loader } from 'elements';
import { type AirportLoungeImage } from 'models/AirportLounge';

interface ICustomSlide {
  image: AirportLoungeImage;
  className?: string;
}

interface ICarousel {
  images: AirportLoungeImage[];
}

const CustomSlide: React.FC<ICustomSlide> = ({ image, ...props }) => {
  return (
    <Box
      maxH={{ base: '222px', sm: '300px', md: '370px' }}
      borderRadius="md"
      {...props}
    >
      <Image
        borderRadius="md"
        bg="greyscale.400"
        objectFit="cover"
        w="100%"
        h={image.height}
        src={image.url}
        alt={image.altText}
        title={image.altText}
        fallback={
          <Flex
            color="primary.900"
            w="100%"
            h={image.height}
            alignItems="center"
            justifyContent="center"
            bg="greyscale.400"
            borderRadius="md"
          >
            <Loader size={20} />
          </Flex>
        }
      />
    </Box>
  );
};

const Carousel: React.FC<ICarousel> = ({ images }) => {
  return (
    <Box borderRadius="md">
      <Slider {...defaultSettings}>
        {images.map((image) => (
          <CustomSlide image={image} className={styles.slide} key={image.url} />
        ))}
      </Slider>
    </Box>
  );
};

export default Carousel;
