import { Expose } from 'class-transformer';

import { type ESim } from './ESim';

interface CarRental {
  code: string;
  discount: number;
}

interface FastTrack {
  code: string;
  discount: number;
}

interface LoungePass {
  url: string;
  discount: number;
}

export interface IPerks {
  esim: ESim | null;
  carRental: CarRental | null;
  airportTransfer: object | null;
  fastTrack: FastTrack | null;
  loungePass: LoungePass | null;
}

export class Perks implements IPerks {
  @Expose() esim: ESim | null;
  @Expose() carRental: CarRental | null;
  @Expose() airportTransfer: object | null;
  @Expose() fastTrack: FastTrack | null;
  @Expose() loungePass: LoungePass | null;
}
