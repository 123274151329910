import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

const EXCLUDED_STATUSES = [401, 404, 409, 422];

const { VITE_SENTRY_DSN_TOKEN, VITE_ENV, VITE_CODE_VERSION } = import.meta.env;
const SUPPORTED_DOMAIN_REGEX =
  /https:\/\/(www\.)?((app|my)(-sta)?\.)?airhelp\.com/i;

export const captureException = (error: unknown) => {
  Sentry.captureException(error);
};

export function initSentry() {
  if (VITE_SENTRY_DSN_TOKEN) {
    Sentry.init({
      dsn: VITE_SENTRY_DSN_TOKEN,
      environment: VITE_ENV,
      enabled: Boolean(VITE_SENTRY_DSN_TOKEN),
      release: VITE_CODE_VERSION,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,

      beforeSend(event, hint) {
        const excludedStatuses = EXCLUDED_STATUSES.some((status) =>
          hint?.data?.payload?.data?.statusCode?.includes(`code ${status}`),
        );
        if (excludedStatuses) {
          return null;
        }
        return event;
      },

      beforeBreadcrumb(breadcrumb) {
        if (
          breadcrumb.category === 'xhr' &&
          !breadcrumb.data?.url.match(SUPPORTED_DOMAIN_REGEX)
        ) {
          return null;
        }

        return breadcrumb;
      },

      ignoreErrors: [
        'Failed to fetch',
        'ttq is not defined',
        'fbq is not defined',
        'esk is not defined',
        "Can't find variable: fbq",
        "Can't find variable: ttq",
        "Can't find variable: esk",
        'window.lintrk is not a function',
        'Request aborted',
        'Request timed out',
        'Failed to start the audio device',
        'timeout exceeded',
        'NotAllowedError',
        'NotAllowedError: Permission denied',
        't.throwIfAborted is not a function',
        "undefined is not an object (evaluating 'a.L')",
        'NetworkError when attempting to fetch resource',
        'Load failed',
      ],
      allowUrls: [SUPPORTED_DOMAIN_REGEX, 'localhost'],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other
        /gtm\.js/i,
        // translators
        /translate_http[^/]+/,
        /^https:\/\/www-airhelp-com\.translate\.goog\/\S+/,
      ],
    });
  }
}
