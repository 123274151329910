import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Ok } from '@airhelp/icons';
import {
  HStack,
  Heading,
  Switch,
  Text,
  VStack,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';

import useUpdateConsents from 'hooks/api/userProfiles/useUpdateConsents/useUpdateConsents';
import {
  type Consents,
  transformToConsentsClass,
} from 'models/userProfile/Consents';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  consents: Consents;
}

const SettingsView: React.FC<IComponent> = ({ consents }) => {
  const { t } = useTranslation();
  const [isMarketingConsentDisabled, setIsMarketingConsentDisabled] =
    useState(false);
  const [consentsState, setConsentsState] = useState(consents);
  const toast = useToast();
  const updateConsents = useUpdateConsents();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { trackPageInteractions } = useTrackEvent();

  const toggleMarketingConsent = () => {
    if (isMarketingConsentDisabled) {
      return;
    }

    const marketingConsent = !consentsState.marketingConsent;

    setIsMarketingConsentDisabled(true);

    const currentConsents = consentsState;
    const updatedConsents = transformToConsentsClass({
      ...consentsState,
      marketingConsent,
    });

    setConsentsState(updatedConsents);

    updateConsents.mutate(
      { consents: updatedConsents },
      {
        onSuccess: () => {
          trackPageInteractions(
            updatedConsents.marketingConsent ? 'on' : 'off',
            'marketing consent',
          );

          toast({
            title: t('my_account.settings.notification_updated'),
            status: 'success',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
          });
        },
        onError: () => {
          setConsentsState(currentConsents);
        },
        onSettled: () => {
          setIsMarketingConsentDisabled(false);
        },
      },
    );
  };

  return (
    <VStack
      bg="greyscale.100"
      p={{ base: 4, md: 6 }}
      borderRadius="lg"
      alignItems="stretch"
      spacing={{ base: 3, md: 0 }}
    >
      <HStack spacing={{ base: 6, lg: 24 }} alignItems="center">
        <Heading flexGrow="1" variant="h5">
          {t('common.marketing')}
        </Heading>
        <Switch
          flexShrink="0"
          isChecked={consentsState.marketingConsent}
          onChange={toggleMarketingConsent}
          data-testid="marketing-consent-switch"
        />
      </HStack>

      <Text fontSize="m" color="primary.700">
        {t('my_account.settings.marketing_notification')}
      </Text>
    </VStack>
  );
};

export default SettingsView;
