import { type UseQueryResult } from '@tanstack/react-query';

import { userProfileApi } from 'api';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import {
  type Consents,
  type IConsents,
  transformToConsentsClass,
} from 'models/userProfile/Consents';

const fetchConsents = async () => {
  const [error, response] = await userProfileApi.fetchConsents();

  if (error) {
    throw error;
  } else {
    return response;
  }
};

const useFetchConsents = (options: ApiQueryOptions = {}) => {
  return useProtectedQuery({
    queryKey: ['userProfile', 'consents'],
    queryFn: () => fetchConsents(),
    select: (data) =>
      transformToConsentsClass((data as { consents: IConsents }).consents),
    ...options,
  }) as UseQueryResult<Consents>;
};

export default useFetchConsents;
