import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PriceTag } from '@airhelp/icons';
import {
  Box,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  Center,
} from '@chakra-ui/react';

import perkLogo from 'assets/images/perks/logo-high-pass.svg';
import { useTrackEvent } from 'utils/tracking/hooks';

import BenefitsList from '../BenefitsList';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { Locale } from 'contexts/LocaleContext/locales';
import { CopyToClipboardButton } from 'elements';
import GoToSiteButton from '../GoToSiteButton';

const SUPPORTED_LANGUAGES: Locale[] = ['en', 'es', 'fr', 'it', 'ru'];
const TRACKING_PROVIDER_NAME = 'highpass_fasttrack';

interface IComponent {
  code: string;
  discount: number;
}

const FastTrack: React.FC<IComponent> = ({ code, discount }) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const { locale } = useLocale();

  const benefits = [
    {
      content: t('dashboard.perks.fast_track.speed_the_queues'),
      key: 'skip_the_queues',
    },
    {
      content: t('dashboard.perks.fast_track.less_stress'),
      key: 'less_stress',
    },
    {
      content: t('dashboard.perks.fast_track.dedicated_lane'),
      key: 'dedicated_lane',
    },
    {
      content: t('dashboard.perks.fast_track.available_for_family'),
      key: 'available_for_family',
    },
  ];

  const getSiteUrl = (locale: Locale) => {
    let selectedLocale = locale;

    if (!SUPPORTED_LANGUAGES.includes(locale)) {
      selectedLocale = 'en';
    }
    return `https://highpass.aero/${selectedLocale}/?apiKey=353f870f-3f11-6058-e442-85966e7255f3&categories=FastTrack`;
  };

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(getSiteUrl(locale), '_blank');
  };

  const onCodeCopied = () => {
    trackPageInteractions(
      'promo code copy',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );
  };

  return (
    <VStack
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      minHeight="100%"
    >
      {/* top section */}
      <Box>
        <HStack
          pb={{ base: 4, md: 8 }}
          justifyContent="space-between"
          alignItems="center"
          mb={{ base: 2, md: 0 }}
          width="100%"
        >
          <Image src={perkLogo} h={{ base: 4, md: 6 }} />
          <Center
            borderRadius="sm"
            px={2}
            py={3}
            h={4}
            alignItems="center"
            background="success.500"
          >
            <PriceTag color="white" mr={1} boxSize={4} />
            <Text
              color="white"
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
            >
              {t('dashboard.perks.percentage_off', { percentage: discount })}
            </Text>
          </Center>
        </HStack>

        <Heading variant={{ base: 'h5', md: 'h4' }} mb={4}>
          <Trans
            i18nKey="dashboard.perks.fast_track.percentage_off_your_fast_track"
            values={{ percentage: discount }}
            components={{
              strong: (
                <Text
                  display="inline"
                  fontSize="xl"
                  fontWeight="bold"
                  color="danger.500"
                />
              ),
            }}
          />
        </Heading>

        <Text fontSize={{ base: 'sm', md: 'md' }} color="greyscale.600">
          {t('dashboard.perks.fast_track.skip_the_queues')}
        </Text>

        <Box my={{ base: 4, md: 8 }}>
          <BenefitsList benefits={benefits} />
        </Box>
      </Box>

      <Box p={4} background="greyscale.300" borderRadius="xl" width="100%">
        <Text fontSize="sm" fontWeight="medium" pb={1}>
          {t('dashboard.perks.get_your_promocode')}
        </Text>

        <CopyToClipboardButton
          id="perk-code"
          inputValue={code}
          fullWidth
          valueAlign="left"
          onCopy={onCodeCopied}
        />

        <Text fontSize="md" color="greyscale.600" pt={2} pb={6}>
          {t('dashboard.perks.use_it_on', { url: 'highpass.aero' })}
        </Text>

        <GoToSiteButton
          onClick={goToSite}
          label={t('dashboard.perks.fast_track.get_pass')}
        />
      </Box>
    </VStack>
  );
};

export default FastTrack;
