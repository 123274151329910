import React, { useContext } from 'react';
import { NavLink, generatePath } from 'react-router-dom';

import { Airhelp, AirhelpPlus } from '@airhelp/logos';
import { Link } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useTrackEvent } from 'utils/tracking/hooks';

const DashboardLogoLink: React.FC = () => {
  const { subPageType } = useContext(TrackingContext);
  const { userProfile } = useMyProfile();
  const { trackCtaClick } = useTrackEvent();

  const onClick = () => {
    trackCtaClick('ahp logo', subPageType);
  };

  return (
    <Link
      as={NavLink}
      to={generatePath(PAGE_PATHS.HOME_PAGE)}
      onClick={onClick}
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-end"
      width={{ base: '110px', lg: '150px' }}
      height={{ base: '32px', lg: '48px' }}
      sx={{ svg: { boxSize: 'unset', lineHeight: 'unset' } }}
    >
      {userProfile?.policy.coverage?.active ? (
        <AirhelpPlus
          data-testid="airhelp-logo"
          width={{ base: '110px !important', lg: '150px !important' }}
          height="auto !important"
        />
      ) : (
        <Airhelp
          data-testid="airhelp-logo"
          width={{ base: '94px !important', lg: '150px !important' }}
          height="auto !important"
        />
      )}
    </Link>
  );
};

export default DashboardLogoLink;
