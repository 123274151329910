import React from 'react';

import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';

interface IComponent {
  airportCode: string;
  airportCity: string | null;
  side?: 'left' | 'right';
}

const AirportName: React.FC<IComponent> = ({
  side,
  airportCity,
  airportCode,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      width={{ base: '50%', xl: 'unset' }}
      justifyContent={side === 'right' ? 'flex-end' : ''}
    >
      {isMobile ? (
        <Flex flexDirection="column">
          <Box
            color="primary.900"
            lineHeight="short"
            fontWeight="bold"
            fontSize="xl"
            textAlign={side}
          >
            {airportCode}
          </Box>
          <Box
            color="greyscale.600"
            fontWeight="light"
            lineHeight="short"
            fontSize="sm"
            textAlign={side}
          >
            {airportCity}
          </Box>
        </Flex>
      ) : (
        <Box fontWeight="medium" fontSize="sm">
          {airportCity} ({airportCode})
        </Box>
      )}
    </Flex>
  );
};

export default AirportName;
