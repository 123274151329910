import React from 'react';

import { VStack } from '@chakra-ui/react';
import PaymentCardView from './PaymentCardView/PaymentCardView';
import BillingInformationView from '../BillingInformationView';
import { BillingManagement } from '@airhelp/plus';

interface IComponent {
  billingManagement: BillingManagement;
}

const PaymentMethodsView: React.FC<IComponent> = ({ billingManagement }) => {
  const { billingAddress } = billingManagement;

  return (
    <VStack alignItems="stretch" spacing={4}>
      <PaymentCardView billingManagement={billingManagement} />
      {billingAddress !== null && (
        <BillingInformationView billingAddress={billingAddress} />
      )}
    </VStack>
  );
};

export default PaymentMethodsView;
