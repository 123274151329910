import React, { Suspense } from 'react';
import TagManager from 'react-gtm-module';
import track from 'react-tracking';

import { Box } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import HTTPClientProviders from 'api/clients';
import ChakraProvider from 'components/ChakraProvider';
import TurnstileWidget from 'components/TurnstileWidget';
import config from 'config/config';
import {
  TrackingContextProvider,
  LocaleContextProvider,
  LayoutContextProvider,
  OptimizelyContextProvider,
} from 'contexts';
import { TabsContextProvider } from 'contexts/TabsContext/TabsContext';
import ZowieContextProvider from 'contexts/ZowieContext';
import { rootUtils, zowieUtils, sentryUtils } from 'utils';
import { trackingDispatch } from 'utils/tracking';

import queryClient from './queryClient';
import Router from './Router';
import 'config/i18n';
import 'config/i18n-countries';
// Carousel styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

config.gtmId &&
  TagManager.initialize({
    gtmId: config.gtmId,
  });

sentryUtils.initSentry();
zowieUtils.loadScript();

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <TurnstileWidget />
      <HTTPClientProviders>
        <ChakraProvider>
          <Box
            className="App"
            backgroundColor="greyscale.300"
            padding={0}
            height="100%"
            width="100%"
            overflow="auto"
          >
            <Suspense fallback="">
              <Router />
            </Suspense>
          </Box>
          <ReactQueryDevtools initialIsOpen={false} />
        </ChakraProvider>
      </HTTPClientProviders>
    </React.StrictMode>
  );
};

// NOTE: The order of the providers determines the order of nesting - from outermost to innermost!
const AppWithContexts = rootUtils.composeProviders(
  [LayoutContextProvider],
  [QueryClientProvider, { client: queryClient }],
  [TrackingContextProvider],
  [LocaleContextProvider],
  [ZowieContextProvider],
  [OptimizelyContextProvider],
  [TabsContextProvider],
)(App);

const AppWithTracking = track(
  { app: 'AHPlusDashboard' },
  {
    // defines how data collected through components tree will be send to the data provider
    //  when track event is called
    dispatch: trackingDispatch,
  },
)(AppWithContexts);

export default AppWithTracking;
