import React from 'react';
import { generatePath, useParams } from 'react-router-dom';

import BackToButton from 'components/BackToButton';
import { PAGE_PATHS } from 'config/routes';

const BackToTripButton = ({
  variantBreakpoint,
  dataTestId,
}: {
  variantBreakpoint?: string;
  dataTestId: string;
}) => {
  const { id } = useParams<{ id: string }>();

  const backToPath = id
    ? generatePath(PAGE_PATHS.JOURNEY.INSURANCE_PAGE, { id })
    : generatePath(PAGE_PATHS.JOURNEYS_PAGE);

  return (
    <BackToButton
      dataTestId={dataTestId}
      labelTKey="common.back_to_trip"
      navigateTo={backToPath}
      variantBreakpoint={variantBreakpoint}
    />
  );
};

export default BackToTripButton;
