import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Info, PriceTag } from '@airhelp/icons';
import {
  Box,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  Center,
} from '@chakra-ui/react';
import perkLogo from 'assets/images/perks/logo-lounge-pass.svg';
import BenefitsList from '../BenefitsList';
import { useTrackEvent } from 'utils/tracking/hooks';
import { InfoBox } from '@airhelp/react';

import GoToSiteButton from '../GoToSiteButton';
import { redirectToLoungePassSite } from 'api/userPolicy/userPolicy';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

const TRACKING_PROVIDER_NAME = 'collinson_loungepass';

interface IComponent {
  discount: number;
}

const LoungePass: React.FC<IComponent> = ({ discount }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { trackPageInteractions } = useTrackEvent();

  const benefits = [
    {
      content: t('dashboard.perks.lounge_pass.guaranteed_access'),
      key: 'guaranteed_access',
    },
    {
      content: t('dashboard.perks.lounge_pass.over_six_hundred_lounges'),
      key: 'over_six_hundred_lounges',
    },
    {
      content: t('dashboard.perks.lounge_pass.relax_away'),
      key: 'relax_away',
    },
    {
      content: t('dashboard.perks.lounge_pass.complimentary_drinks'),
      key: 'complimentary_drinks',
    },
    {
      content: t('dashboard.perks.lounge_pass.free_cancellation'),
      key: 'free_cancellation',
    },
  ];

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    redirectToLoungePassSite(locale);
  };

  return (
    <VStack
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      minHeight="100%"
    >
      {/* top section */}
      <Box>
        <HStack
          pb={{ base: 4, md: 8 }}
          justifyContent="space-between"
          alignItems="center"
          mb={{ base: 2, md: 0 }}
          width="100%"
        >
          <Image src={perkLogo} h={{ base: 4, md: 6 }} />
          <Center
            borderRadius="sm"
            px={2}
            py={3}
            h={4}
            alignItems="center"
            background="success.500"
          >
            <PriceTag color="white" mr={1} boxSize={4} />
            <Text
              color="white"
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
            >
              {t('dashboard.perks.percentage_off', { percentage: discount })}
            </Text>
          </Center>
        </HStack>

        <Heading variant={{ base: 'h5', md: 'h4' }} mb={4}>
          <Trans
            i18nKey="dashboard.perks.lounge_pass.percentage_off_your_lounge_pass"
            values={{ percentage: discount }}
            components={{
              strong: (
                <Text
                  display="inline"
                  fontSize="xl"
                  fontWeight="bold"
                  color="danger.500"
                />
              ),
            }}
          />
        </Heading>

        <Text fontSize={{ base: 'sm', md: 'md' }} color="greyscale.600">
          {t('dashboard.perks.fast_track.skip_the_queues')}
        </Text>

        <Box my={{ base: 4, md: 8 }}>
          <BenefitsList benefits={benefits} />
        </Box>
      </Box>

      <InfoBox icon={<Info boxSize={5} />}>
        <Text color="greyscale.700" fontSize="md">
          {t('dashboard.perks.lounge_pass.gets_guaranteed_lounge_access')}
        </Text>
      </InfoBox>

      <Box p={4} background="greyscale.300" borderRadius="xl" width="100%">
        <GoToSiteButton
          onClick={goToSite}
          label={t('dashboard.perks.lounge_pass.get_lounge_pass')}
        />
      </Box>
    </VStack>
  );
};

export default LoungePass;
