import React from 'react';

import { ContentSpinner } from '@airhelp/react';
import { Box } from '@chakra-ui/react';

const Loader = ({
  dataTestId,
  size,
}: {
  dataTestId?: string;
  size?: number;
}) => {
  return (
    <Box data-testid={dataTestId}>
      <ContentSpinner size={size || 30} />
    </Box>
  );
};

export default Loader;
