import { AirPayoutJourney, AirPayoutStatus } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';

export const getTooltipStatus = (airPayoutJourney: AirPayoutJourney) => {
  const { t } = useTranslation();

  const { status, computed, payoutAmount } = airPayoutJourney;
  const { outboundOnly, cancelationOnly } = computed;
  const airPayoutPayout = payoutAmount.toString();

  const getActiveStatusDescription = () => {
    if (outboundOnly && cancelationOnly) {
      return t('air_payout.card.description.cancelation');
    }
    return t('air_payout.card.description.added');
  };

  switch (status) {
    case AirPayoutStatus.enum.active:
      return getActiveStatusDescription();

    case AirPayoutStatus.enum.eligible:
      return t('air_payout.card.description.eligible', {
        amount: airPayoutPayout,
      });

    case AirPayoutStatus.enum.ineligible:
    case AirPayoutStatus.enum.unregistered:
      return t('air_payout.card.description.not_eligible');

    case AirPayoutStatus.enum.processing_payment:
    case AirPayoutStatus.enum.manual_verification_pending:
      return t('air_payout.card.description.processing');

    case AirPayoutStatus.enum.payout_failed:
    case AirPayoutStatus.enum.payout_returned:
      return t('air_payout.card.description.payment_failed');
    case AirPayoutStatus.enum.payout_successful:
      return t('air_payout.card.description.paid');

    case AirPayoutStatus.enum.expired:
      return t('air_payout.card.description.expired', { value: 28 });
    default:
      return t('air_payout.card.description.default', {
        amount: airPayoutPayout,
      });
  }
};
