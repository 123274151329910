import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Edit, MoreVertical } from '@airhelp/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import androidImage from 'assets/images/ahplus-how-install-shortcut-android@2x.png';
import iOSImage from 'assets/images/ahplus-how-install-shortcut-ios@2x.png';
import {
  AddToHomeScreen,
  Share,
  PlusOutline,
} from 'components/HomeView/AddToHomeScreenShortcut/Icons';
import { useTrackEvent } from 'utils/tracking/hooks';

interface Props {
  render: (openModalHandler: () => void) => void;
}

const ExplanationModal: React.FC<Props> = ({ render }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const androidList = [
    {
      icon: <MoreVertical boxSize={5} />,
      text: t('home_screen_banner.android.click_on_the_top_right'),
      key: 'explanation-modal-android-click-on-the-top-right',
    },
    {
      icon: <AddToHomeScreen boxSize={6} />,
      text: t('home_screen_banner.android.select_add_to_home_screen'),
      key: 'explanation-modal-android-select-add-to-home-screen',
    },
    {
      icon: <Edit />,
      text: t('home_screen_banner.android.name_the_shortcut'),
      key: 'explanation-modal-android-name-shortcut',
    },
  ];

  const iOSList = [
    {
      icon: <Share boxSize={5} />,
      text: t('home_screen_banner.ios.click_the_share_button_in_your_browser'),
      key: 'explanation-modal-ios-click-share-button',
    },
    {
      icon: <PlusOutline boxSize={5} />,
      text: t('home_screen_banner.ios.select_add_to_home_screen'),
      key: 'explanation-modal-ios-select-add-home-screen',
    },
  ];

  const headerImage = isIOS ? iOSImage : androidImage;
  const howToInstallList = isIOS ? iOSList : androidList;

  const closeModalByXCloseHandler = () => {
    trackCtaClick('x-close', 'shortcut info modal');
    onClose();
  };

  const closeModalByCtaHandler = () => {
    trackCtaClick('x-close', 'got it');
    onClose();
  };

  const openModalHandler = () => {
    onOpen();
  };

  return (
    <>
      {render ? render(openModalHandler) : null}
      <Modal
        isOpen={isOpen}
        size="md"
        onClose={closeModalByXCloseHandler}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent
          p={{ base: 0, md: 0 }}
          borderRadius="lg"
          data-testid="add-to-home-screen-explanation-modal"
        >
          <ModalCloseButton
            borderRadius="sm"
            boxSize={2}
            top={4}
            right={4}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2.5 },
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            data-testid="modal-close-button"
          />
          <ModalBody
            p={{ base: 0, md: 0 }}
            textAlign="center"
            overflowY="hidden"
            overflowX="auto"
          >
            <Box
              textAlign="center"
              m={0}
              p={0}
              borderTopLeftRadius="lg"
              borderTopRightRadius="lg"
              overflow="hidden"
            >
              <Image src={headerImage} w="100%" mx="auto" alt="" />
            </Box>
            <Box textAlign="center" p={4}>
              <Heading
                variant="h4"
                mb={4}
                mt={0}
                mx="auto"
                fontSize="lg"
                textAlign="left"
                lineHeight="short"
              >
                {t('home_screen_banner.your_flight_updates')}
              </Heading>
              <List spacing={4}>
                {howToInstallList.map((item) => (
                  <ListItem
                    display="flex"
                    alignItems="center"
                    mb={4}
                    key={item.key}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="md"
                      bgColor="primary.100"
                      color="primary.500"
                      width={9}
                      height={9}
                      mr={3}
                    >
                      {item.icon}
                    </Flex>
                    <Text textAlign="left" fontSize="md" width="100%">
                      {item.text}
                    </Text>
                  </ListItem>
                ))}
              </List>
              <Text fontSize="md" textAlign="left">
                {t('home_screen_banner.now_you_will_be_able_to_access')}
              </Text>
              <Button
                onClick={closeModalByCtaHandler}
                size="m"
                fontWeight="bold"
                mt={4}
                width="100%"
                data-testid="got-it-cta"
              >
                {t('home_screen_banner.cta')}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExplanationModal;
