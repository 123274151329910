import React from 'react';

import { VStack } from '@chakra-ui/react';

import { type UserProfile } from 'models/UserProfile';

import FeaturesNotIncluded from './FeaturesNotIncluded';
import ManageYourPlan from './ManageYourPlan';
import YourPlanBenefits from './YourPlanBenefits';
import YourPlanInfo from './YourPlanInfo';
import YourTrips from './YourTrips/YourTrips';
import { Journey } from '@airhelp/plus';

const BenefitsView: React.FC<{
  userProfile: UserProfile;
  journeys: Journey[];
}> = ({ userProfile, journeys }) => {
  const hasUserOldPlans = Boolean(
    userProfile.policy.membership?.isComplete ||
      userProfile.policy.membership?.isEssential,
  );

  return (
    <VStack alignItems="stretch" spacing={4}>
      <YourPlanInfo userProfile={userProfile} />
      {userProfile.policy.trip && journeys.length > 0 ? (
        <YourTrips journeys={journeys} />
      ) : null}
      {userProfile.policy.membership?.active && userProfile.policy.benefits ? (
        <>
          <YourPlanBenefits
            oldPlans={hasUserOldPlans}
            benefits={userProfile.policy.benefits}
            eSim={userProfile.policy.perks.esim}
          />
          <FeaturesNotIncluded />
          <ManageYourPlan userProfile={userProfile} />
        </>
      ) : null}
    </VStack>
  );
};

export default BenefitsView;
