import { createBaseAuthHTTPClient } from '@airhelp/auth';
import { type GenericUnauthorizedCatcher } from '@airhelp/http-client';
import { retry } from '@airhelp/http-client/middleware';
import { createBaseAhPlusHTTPClient } from '@airhelp/plus';
import {
  AuthenticationRequiredError,
  createRefreshAccessTokenUnauthorizedCatcher,
} from '@airhelp/session';
import { createBaseWebappHTTPClient } from '@airhelp/webapp';

import { convertDomainUrl } from 'utils/domains';
import config from 'config/config';
import { sentryUtils } from 'utils';
import { ZodError } from 'zod';

export const authClient = createBaseAuthHTTPClient({
  caller: config.appName,
  baseURL: convertDomainUrl(
    window.location.origin,
    import.meta.env.VITE_AUTH_URL,
  ),
});

const throwAuthRequiredWithReturnURL = () => {
  throw new AuthenticationRequiredError();
};

const onUnauthorized: GenericUnauthorizedCatcher =
  createRefreshAccessTokenUnauthorizedCatcher({
    authClient,
    onRetryUnauthorized: throwAuthRequiredWithReturnURL,
  });

export const webappClient = createBaseWebappHTTPClient({
  caller: config.appName,
  baseURL: convertDomainUrl(
    window.location.origin,
    import.meta.env.VITE_WEBAPP_BACKEND_URL,
  ),
})
  // Add retry middleware to handle network errors and retry failed requests
  .middlewares([
    retry({
      maxAttempts: 3, // Maximum of 3 retry attempts
      retryOnNetworkError: true, // Retry on network errors (e.g., connection issues)
      until: (response) => {
        // Stop retrying if a response is received with a status less than 500
        return typeof response !== 'undefined' && response.status < 500;
      },
    }),
  ])
  .catcher(401, onUnauthorized);

export const ahplusClient = createBaseAhPlusHTTPClient({
  caller: config.appName,
  baseURL: convertDomainUrl(
    window.location.origin,
    import.meta.env.VITE_AHPLUS_API_URL,
  ),
})
  .middlewares([
    // Add retry middleware to handle network errors and retry failed requests
    retry({
      maxAttempts: 3, // Maximum of 3 retry attempts
      retryOnNetworkError: true, // Retry on network errors (e.g., connection issues)
      until: (response) => {
        // Stop retrying if a response is received with a status less than 500
        return typeof response !== 'undefined' && response.status < 500;
      },
    }),
  ])
  .catcher(401, onUnauthorized)
  .catcherFallback((error) => {
    if (error instanceof ZodError) {
      sentryUtils.captureException(error);
    }
    throw error;
  });
