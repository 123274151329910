import React from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';

import { Loader } from 'elements';

const PassQrCode: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return (
    imageUrl && (
      <Box
        textAlign="center"
        px={{ base: 0, md: 8 }}
        data-testid="lounge-pass-details-qr-image"
      >
        <Box
          py={{ base: 6, md: 0 }}
          bg="greyscale.100"
          boxShadow="lg"
          borderRadius="lg"
        >
          <Image
            borderRadius="lg"
            width={{ base: '160px', md: '200px' }}
            height={{ base: '160px', md: '200px' }}
            mx="auto"
            src={imageUrl}
            fallback={
              <Flex
                color="primary.900"
                w="100%"
                width={{ base: '160px', md: '200px' }}
                height={{ base: '160px', md: '200px' }}
                mx="auto"
                alignItems="center"
                justifyContent="center"
              >
                <Loader size={20} data-testid="qr-loader" />
              </Flex>
            }
          />
        </Box>
      </Box>
    )
  );
};
export default PassQrCode;
