import React from 'react';
import { Trans } from 'react-i18next';

import { InfoOutline, Check, Delete } from '@airhelp/icons';
import {
  HStack,
  Text,
  Tooltip,
  Circle,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';

const PlanDetailsItem = ({ item }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const hasTitleTKey = Boolean(item.title_tkey);

  return (
    <HStack
      w="100%"
      px={0}
      spacing={1}
      key={item.key}
      textColor="primary.700"
      data-testid={`plan-detail-${item.key}`}
    >
      <item.icon
        boxSize={9}
        p={2}
        bg="primary.100"
        color="primary.500"
        borderRadius="md"
        mr={2}
      />
      {hasTitleTKey ? (
        <Trans
          i18nKey={item.title_tkey}
          values={item.value}
          components={{
            strong: (
              <Text
                fontSize="inherit"
                fontWeight="bold"
                display="inline-block"
              />
            ),
          }}
        />
      ) : (
        <Text fontSize="inherit" display="inline-block" textColor="inherit">
          {item.title}
        </Text>
      )}
      <Spacer display={{ base: 'inline', xl: 'none' }} />
      {item.tooltip ? (
        <Tooltip
          hasArrow
          isOpen={isOpen}
          label={
            <Trans
              i18nKey={item.tooltip.content}
              values={item.tooltip.value}
              components={{
                strong: (
                  <Text
                    as="span"
                    fontSize="inherit"
                    fontWeight="bold"
                    display="inline-block"
                  />
                ),
              }}
            />
          }
          arrowPadding={6}
          bgColor="greyscale.800"
          borderRadius="md"
          p={4}
        >
          <InfoOutline
            mr={1}
            color="greyscale.600"
            boxSize={4}
            cursor="pointer"
            display="inline"
            data-testid="tooltip-info-icon"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={onToggle}
          />
        </Tooltip>
      ) : null}
      <Spacer display={{ base: 'none', xl: 'inline' }} />
      {typeof item.included !== 'undefined' &&
        (item.included ? (
          <Circle
            size={8}
            bgColor="success.500"
            justifySelf="flex-end"
            data-testid="included-icon"
          >
            <Check color="greyscale.100" />
          </Circle>
        ) : (
          <Circle
            size={8}
            bgColor="danger.100"
            border="1px solid #E09999"
            justifySelf="flex-end"
            data-testid="excluded-icon"
          >
            <Delete color="danger.400" />
          </Circle>
        ))}
    </HStack>
  );
};

export default PlanDetailsItem;
