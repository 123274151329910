import { Expose } from 'class-transformer';

import { type CurrencyCode } from 'stores/preferences/currencies';

export interface IPaymentDetails {
  currency: CurrencyCode;
  discountValue: string;
  discountedPrice: string;
  productPrice: string;
}

export class PaymentDetails implements IPaymentDetails {
  @Expose() currency: CurrencyCode;
  @Expose() discountValue: string;
  @Expose() discountedPrice: string;
  @Expose() productPrice: string;
}
