import React from 'react';
import { useTranslation } from 'react-i18next';

import { Delete } from '@airhelp/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { BrandIcon } from 'components/HomeView/AddToHomeScreenShortcut/Icons';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { useTrackEvent } from 'utils/tracking/hooks';

import ExplanationModal from '../ExplanationModal';

const TopBanner: React.FC = () => {
  const { hideAddToHomeScreenBanner } = useLayout();

  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const onBannerClose = () => {
    trackCtaClick('x-close', 'create shortcut up banner');

    hideAddToHomeScreenBanner();
  };

  return (
    <Flex
      bg="primary.900"
      p={3}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      data-testid="add-to-home-screen-top-banner"
    >
      <Flex justifyContent="flex-start" alignItems="center">
        <Button
          variant="link"
          color="greyscale.500"
          minWidth="auto"
          width="auto"
          display="flex"
          height={10}
          p={0}
          m={0}
          justifyContent="flex-start"
          alignItems="center"
          onClick={onBannerClose}
          data-testid="add-to-home-screen-top-banner-close-button"
        >
          <Delete boxSize="5 !important" alignSelf="center" />
        </Button>
        <Flex color="greyscale.100" ml={6} alignItems="center">
          <Flex
            bg="greyscale.100"
            borderRadius="md"
            height={10}
            width={10}
            px={3}
            mr={4}
            alignItems="center"
            justifyContent="center"
          >
            <BrandIcon />
          </Flex>
          <Box color="greyscale.100">
            <Text
              fontWeight="semibold"
              fontSize="md"
              m={0}
              color="greyscale.100"
            >
              AirHelp+
            </Text>
            <Text fontSize="sm" m={0} color="greyscale.500" lineHeight="short">
              {t('home_screen_banner.add_to_your_home_screen')}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <ExplanationModal
        render={(openModalHandler) => (
          <Button
            variant="primaryAlt"
            size="s"
            py={2}
            onClick={() => {
              trackCtaClick('add', 'create shortcut up banner');
              openModalHandler();
            }}
            data-testid="add-to-home-screen-top-banner-show-explanation-modal"
            _visited={{ color: 'greyscale.100' }}
          >
            {t('home_screen_banner.add')}
          </Button>
        )}
      />
    </Flex>
  );
};

export default TopBanner;
