import { Locale } from '../contexts/LocaleContext/locales';

interface CookieOptions {
  path: string;
  domain: string;
}

interface Optimizely {
  enabled: boolean;
  scriptUrl: string;
  logsEnabled: boolean;
}

interface Config {
  appName: string;
  airLuggageIpidVersion: string;
  airPayoutIpidVersion: string;
  authApiVersion: string;
  authUrl: string;
  contactEmail: string;
  cookieOptions: CookieOptions;
  defaultPassengerAge: number;
  flightStatuses: Record<string, string>;
  gtmId?: string;
  languageCookie: string;
  maxFellowPassengers: number;
  maxPassengerAge: number;
  optimizely: Optimizely;
  partners: Partners;
  stripeAccountId: string;
  stripePublishableKey: string;
  supportedRegionalDomains: string[];
  tracking: Record<string, boolean>;
  trustpilotApiKey: string;
  turnstileSiteKey: string;
  webappUrl: string;
  websitePaths: WebsitePaths;
  websiteUrl: string;
  zowieChat: ZowieChat;
}

interface ZowieChat {
  enabled: boolean;
  url: string;
  instanceIdAirhelp: string;
}

interface Partners {
  telemedico: string;
}

type LocalizedPaths = { [key in Locale]?: string };

export interface WebsitePaths {
  airPassengerRights: LocalizedPaths;
  faqPath: LocalizedPaths;
  help: LocalizedPaths;
  privacyPath: LocalizedPaths;
  termsPath: LocalizedPaths;
}

const config: Config = {
  appName: 'ahplus-web',
  authUrl: import.meta.env.VITE_AUTH_URL || '',
  authApiVersion: import.meta.env.VITE_AUTH_API_VERSION || '',
  contactEmail: 'plus@airhelp.com',
  // Cookie configuration needs to be compatible with webapp-frontend,
  // webapp uses .airhelp.com configuration for staging and production
  airLuggageIpidVersion:
    'https://ahplus-production.s3.eu-west-1.amazonaws.com/public/insurance/AirLuggage_IPID_',
  airPayoutIpidVersion:
    'https://ahplus-production.s3.eu-west-1.amazonaws.com/public/insurance/AirPayout_IPID_',
  cookieOptions: {
    path: '/',
    domain: import.meta.env.VITE_COOKIE_DOMAIN || '.airhelp.com',
  },
  flightStatuses: {
    canceled: 'canceled',
    delayed: 'delayed',
    // Status `on_time` is temporarily disabled
    //on_time: "on_time",
  },
  maxFellowPassengers: 4,
  gtmId: import.meta.env.VITE_GTM_ID,
  languageCookie: 'user_language',
  partners: {
    telemedico: 'telemedico',
  },
  optimizely: {
    enabled: import.meta.env.VITE_OPTIMIZELY_ENABLED === 'true',
    scriptUrl: 'https://cdn.optimizely.com/js/24797301378.js',
    logsEnabled: import.meta.env.VITE_OPTIMIZELY_LOGS === 'true',
  },
  tracking: {
    logEvents: import.meta.env.VITE_TRACKING_LOG_EVENTS === 'true',
  },
  zowieChat: {
    enabled: import.meta.env.VITE_ZOWIE_ENABLED === 'true',
    url: import.meta.env.VITE_ZOWIE_URL || '',
    instanceIdAirhelp: import.meta.env.VITE_ZOWIE_AIRHELP_INSTANCEID || '',
  },
  webappUrl: import.meta.env.VITE_WEBAPP_FRONTEND_URL || '',
  websiteUrl: import.meta.env.VITE_WEBSITE_URL || '',
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '',
  stripeAccountId: import.meta.env.VITE_STRIPE_ACCOUNT_ID || '',
  supportedRegionalDomains:
    import.meta.env.VITE_SUPPORTED_REGIONAL_DOMAINS.split(','),
  trustpilotApiKey: import.meta.env.VITE_TRUSTPILOT_API_KEY || '',
  turnstileSiteKey: import.meta.env.VITE_TURNSTILE_SITE_KEY || '',
  // We assume that the maximum age of a passenger is 120 years :)
  maxPassengerAge: 120,
  defaultPassengerAge: 18,
  websitePaths: {
    airPassengerRights: {
      en: 'air-passenger-rights',
    },
    faqPath: {
      'pt-BR': 'help/direitos-dos-passageiros-aereos',
      da: 'help/lov-om-flypassagerrettigheder',
      de: 'help/fluggastrecht',
      el: 'help/syxnes-erwthseis',
      en: 'help/most-asked-questions',
      es: 'help/preguntas-frecuentes',
      fi: 'help/lentomatkustajan-oikeudet',
      fr: 'help/le-droit-des-passagers-aeriens',
      it: 'help/ec-261-la-legge-sui-diritti-dei-passeggeri',
      nb: 'help/ofte-spurte-spoersmal',
      nl: 'help/vaakst-gestelde-vragen/',
      pl: 'help/najczesciej-zadawane-pytania',
      pt: 'help/direitos-dos-passageiros-aereos',
      ro: 'help/cele-mai-puse-intrebari',
      ru: 'help/zakon-ob-aviapassazirah',
      sv: 'help/ofta-fraagade-fraagor',
    },
    help: {
      en: 'help',
    },
    privacyPath: {
      'pt-BR': 'privacidade',
      'zh-CN': 'privacy',
      da: 'fortrolighed',
      de: 'datenschutz',
      el: 'exemytheia',
      en: 'privacy',
      es: 'privacidad',
      fi: 'yksityisyys',
      fr: 'confidentialite',
      it: 'privacy',
      nb: 'privat',
      nl: 'privacy',
      pl: 'prywatnosc',
      pt: 'privacidade',
      ro: 'politica-de-confidentialitate',
      ru: 'konfidientsialnost',
      sv: 'privat',
      tr: 'gizlilik',
    },
    termsPath: {
      'pt-BR': 'termos',
      'zh-CN': 'terms',
      da: 'vilkar',
      de: 'nutzungsbedingungen',
      el: 'oroi',
      en: 'terms',
      es: 'terminos',
      fi: 'ehdot',
      fr: 'conditions-dutilisation',
      it: 'termini-e-condizioni',
      nb: 'vilkar',
      nl: 'voorwaarden',
      pl: 'regulamin',
      pt: 'termos',
      ro: 'termeni',
      ru: 'sroki',
      sv: 'villkor',
      tr: 'kosullar',
    },
  },
};

export default config;
