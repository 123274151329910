import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { AltArrowBack } from '@airhelp/icons';
import {
  Box,
  Button,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { NOTIFICATION_DISMISSED_COOKIE } from 'config/cookies';
import cookie from 'react-cookies';

import { PAGE_PATHS } from 'config/routes';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useFeatureFlags } from '@airhelp/plus/react';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot/PulsatingNotificationDot';
import { createCookieOptions } from 'utils/cookies';

export enum MyAccountTab {
  DETAILS = 'details',
  SETTINGS = 'settings',
  BENEFITS = 'benefits',
  SPECIAL_OFFER = 'special-offer',
  BILLING = 'billing',
}

export enum MyAccountEvents {
  DETAILS = 'details',
  SETTINGS = 'settings',
  BENEFITS = 'my benefits',
  SPECIAL_OFFER = 'special offer',
  BILLING = 'billing',
}

interface Tab {
  eventName: MyAccountEvents;
  key: MyAccountTab;
  label: string;
  isActive: boolean;
  path: string;
  showNotification: boolean;
}

const MyAccountView = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userProfile } = useFetchMyProfile();
  const { data: featureFlags } = useFeatureFlags();

  const { trackCtaClick, trackPageInteractions } = useTrackEvent();

  const showSpecialOffer =
    userProfile?.policy?.membership?.isEssential &&
    featureFlags?.essential_upgrade;

  const showPaymentManagement = featureFlags?.payment_management;
  const isActiveMember = userProfile?.policy.membership?.fromCustomer;

  const tabs: Tab[] = useMemo(() => {
    const tabsArray: Tab[] = [];

    if (showSpecialOffer) {
      tabsArray.push({
        eventName: MyAccountEvents.SPECIAL_OFFER,
        key: MyAccountTab.SPECIAL_OFFER,
        label: t('common.special_offer'),
        isActive: location.pathname.includes(MyAccountTab.SPECIAL_OFFER),
        path: PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE,
        showNotification: !cookie.load(NOTIFICATION_DISMISSED_COOKIE),
      });
    }

    tabsArray.push(
      {
        eventName: MyAccountEvents.DETAILS,
        key: MyAccountTab.DETAILS,
        label: t('common.details'),
        isActive: location.pathname.includes(MyAccountTab.DETAILS),
        path: PAGE_PATHS.MY_ACCOUNT.DETAILS_PAGE,
        showNotification: false,
      },
      {
        eventName: MyAccountEvents.BENEFITS,
        key: MyAccountTab.BENEFITS,
        label: t('common.plan_and_benefits'),
        isActive: location.pathname.includes(MyAccountTab.BENEFITS),
        path: PAGE_PATHS.MY_ACCOUNT.BENEFITS_PAGE,
        showNotification: false,
      },
    );

    if (showPaymentManagement && isActiveMember) {
      tabsArray.push({
        eventName: MyAccountEvents.BILLING,
        key: MyAccountTab.BILLING,
        label: t('payment_management.payment'),
        isActive: location.pathname.includes(MyAccountTab.BILLING),
        path: PAGE_PATHS.MY_ACCOUNT.BILLING_PAGE,
        showNotification: false,
      });
    }

    tabsArray.push({
      eventName: MyAccountEvents.SETTINGS,
      key: MyAccountTab.SETTINGS,
      label: t('common.settings'),
      isActive: location.pathname.includes(MyAccountTab.SETTINGS),
      path: PAGE_PATHS.MY_ACCOUNT.SETTINGS_PAGE,
      showNotification: false,
    });

    return tabsArray;
  }, [locale, location, showSpecialOffer, showPaymentManagement]);

  const onTabClick = (tab: Tab) => {
    trackPageInteractions('clicked', tab.eventName);

    navigate(generatePath(tab.path));
  };

  const handleBackButtonClick = () => {
    trackCtaClick('back', 'my account page');

    navigate(PAGE_PATHS.HOME_PAGE);
  };

  const isFitted = useBreakpointValue({ base: true, lg: undefined });
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box width="100%">
      {isDesktop ? (
        <Button
          variant="link"
          data-testid="back-button"
          leftIcon={<AltArrowBack />}
          onClick={handleBackButtonClick}
        >
          {t('common.back')}
        </Button>
      ) : null}
      <Text fontSize="xl" fontWeight="700">
        {t('my_account.header')}
      </Text>
      <Tabs
        my={4}
        pr={4}
        isFitted={isFitted}
        index={tabs.findIndex((tab) => tab.isActive)}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab
              key={`account-details-tab-${tab.key}`}
              data-testid={`account-details-tab-${tab.key}`}
              onClick={() => {
                onTabClick(tab);

                if (tab.showNotification) {
                  cookie.save(
                    NOTIFICATION_DISMISSED_COOKIE,
                    Date.now(),
                    createCookieOptions(),
                  );
                }
              }}
            >
              {tab.label}
              {tab.showNotification ? (
                <Box position="relative">
                  <PulsatingNotificationDot width="6px" height="6px" />
                </Box>
              ) : null}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Outlet />
    </Box>
  );
};

export default MyAccountView;
