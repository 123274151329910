import { useTranslation } from 'react-i18next';

import { sub, isAfter } from 'date-fns';
import {
  format as formatDate,
  getTimezoneOffset,
  toDate,
  toZonedTime,
} from 'date-fns-tz';
import { slice, filter, orderBy } from 'lodash';
import queryString from 'query-string';

import { Airport, EditableFlight, Itinerary, Journey } from '@airhelp/plus';

import {
  dateFnLocaleHelper,
  useLocale,
} from 'contexts/LocaleContext/LocaleContextProvider';
import { getWebappNewClaimUrl } from 'utils/sites';

export const cityTranslations = (airport: Airport | null) => {
  const { i18n } = useTranslation();

  if (airport) {
    return (
      airport.cityTranslations[i18n.language] ||
      airport.cityTranslations.en ||
      airport.city
    );
  }
  return '';
};

export const createClaimUrl = (itinerary: Itinerary): string => {
  interface ClaimingFlight {
    departureAirport: string | null;
    arrivalAirport: string | null;
    departureDate: Date;
    airlineCode: string | null;
    flightNumber: string;
  }

  const airSpaceError = itinerary.flights
    .map(
      (flight) =>
        flight.departureAirport === null || flight.arrivalAirport === null,
    )
    .includes(true);

  if (airSpaceError) {
    return getWebappNewClaimUrl();
  }

  try {
    const flights: ClaimingFlight[] = itinerary.flights.map((flight) => ({
      departureAirport: flight.departureAirport
        ? flight.departureAirport.iata
        : null,
      arrivalAirport: flight.arrivalAirport ? flight.arrivalAirport.iata : null,
      departureDate: flight.localDepartureDate,
      airlineCode: flight.airline.code,
      flightNumber: flight.flightNumber,
    }));

    return queryString.stringifyUrl(
      {
        url: getWebappNewClaimUrl(),
        query: {
          flights: flights.map((flight) => JSON.stringify(flight)),
        },
      },
      { arrayFormat: 'index' },
    );
  } catch (e) {
    return getWebappNewClaimUrl();
  }
};

export const cityWithCode = (airport?: Airport, fallback?: string) => {
  const { i18n } = useTranslation();

  if (airport?.cityTranslations) {
    const cityName =
      airport.cityTranslations[i18n.language] ||
      airport.cityTranslations.en ||
      airport.city;

    return `${cityName} (${airport?.iata})`;
  }
  return fallback || '-';
};

export const flightDateFormatted = (date: Date) => {
  const { i18n } = useTranslation();

  const dateLocale = dateFnLocaleHelper(i18n.language);

  // We can't use `new Date(flightDate)` because we have no timezone information, but we want to show the date without time offsets
  return formatDate(toZonedTime(date, 'UTC'), 'dd LLL yyyy', {
    locale: dateLocale,
  });
};

export const flightLocalTime = (
  time?: Date | string | null,
  timeZone?: string,
) => {
  if (time && timeZone) {
    const parsedDate = toDate(time);
    // Time offset in hours
    const timeZoneOffset =
      getTimezoneOffset(timeZone, parsedDate) / 1000 / 60 / 60;
    const zonedTime = toZonedTime(parsedDate, timeZone);

    // Side note @leszek 29.03.2023
    // We need to use hack for `America/Mexico_City` timeZone only
    // because `date-fns-tz` and others libs has not valid time offset value yet
    // DST is no longer in use
    // The previous DST change in Mexico was on 30 October 2022
    // From 30 October 2022 valid time offset is GMT-6 (not GMT-5)
    if (
      isAfter(parsedDate, toDate('2022-10-30T00:00:00 UTC')) &&
      timeZone === 'America/Mexico_City' &&
      timeZoneOffset === -5
    ) {
      return formatDate(sub(zonedTime, { hours: 1 }), 'HH:mm', { timeZone });
    }
    return formatDate(zonedTime, 'HH:mm', { timeZone });
  }
  return '-';
};

export const filteredAndSortedJourneys = (
  journeys: Journey[],
  active: boolean,
  limitNumber?: number,
) => {
  const sortedJourneys = orderBy(
    filter(journeys, { active }),
    'itineraries[0].flights[0].localDepartureDate',
    [active ? 'asc' : 'desc'],
  );

  return limitNumber ? slice(sortedJourneys, 0, limitNumber) : sortedJourneys;
};

export const updateFollowingFlightsDates = (
  referenceIndex: number,
  referenceDate: string | Date,
  flights: EditableFlight[],
) => {
  // loop through the flights array starting from the nearest flight
  for (let i = referenceIndex + 1; i < flights.length; i++) {
    // minDate should be always equal to the departure date of the previous flight (referenceDate)
    flights[i].metadata.minDate = referenceDate;

    // if the flight has a departure date, we can break the loop - minDate for following flights is already set
    if (flights[i].localDepartureDate) {
      break;
    }
  }
};

export const updatePreviousFlightsDates = (
  referenceIndex: number,
  referenceDate: string | Date,
  flights: EditableFlight[],
) => {
  // loop through the flights array starting from the nearest flight
  for (let i = referenceIndex - 1; i >= 0; i--) {
    // maxDate should be always equal to the departure date of the next flight (referenceDate)
    flights[i].metadata.maxDate = referenceDate;

    // if the flight has a departure date, we can break the loop - maxDate for previous flights is already set
    if (flights[i].localDepartureDate) {
      break;
    }
  }
};

export const updateFlightsDateBoundaries = (
  referenceIndex: number,
  referenceDate: Date | string,
  flights: EditableFlight[],
) => {
  updateFollowingFlightsDates(referenceIndex, referenceDate, flights);
  updatePreviousFlightsDates(referenceIndex, referenceDate, flights);
};

export const generateDisplayName = (journey: Journey): string => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { name, itineraries } = journey;

  if (name) {
    return name;
  }
  const firstItinerary = itineraries[0];
  const lastFlight = firstItinerary.flights[firstItinerary.flights.length - 1];
  const destinationAirport = lastFlight.arrivalAirport;

  return t('journeys.name_default', {
    destination:
      destinationAirport?.cityTranslations[locale] || destinationAirport?.city,
  });
};

export const MAX_LENGTH_JOURNEY_TITLE = 40;
