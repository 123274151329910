import { useState } from 'react';

import useEffectOnce from 'hooks/useEffectOnce';

interface Size {
  width?: number;
  height?: number;
}

// Hook
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const [isDesktop, setIsDesktop] = useState<boolean>();

  useEffectOnce(() => {
    function handleResize() {
      const windowSize: Size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setWindowSize(windowSize);
      if (windowSize.width != undefined) {
        setIsDesktop(windowSize.width >= 1024);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  });
  return { windowSize, isDesktop };
};

export default useWindowSize;
