import React from 'react';
import { Button, useDisclosure, VStack } from '@chakra-ui/react';
import { Edit } from '@airhelp/icons';
import LabelValue from '../common/LabelValue/LabelValue';
import { BillingManagementAddress } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';
import Section from 'components/MyAccountView/BenefitsView/Section';
import UpdateBillingInformation from './UpdateBillingInformation';

interface IComponent {
  billingAddress: BillingManagementAddress;
}

const BillingInformationView: React.FC<IComponent> = ({ billingAddress }) => {
  const { t } = useTranslation();
  const { onClose, isOpen, onOpen } = useDisclosure();

  const {
    name,
    city,
    countryCode,
    postalCode,
    addressLine1,
    addressLine2,
    state,
  } = billingAddress;

  return (
    <>
      <Section header={t('payment_management.billing_address')}>
        <VStack
          w={{ base: '100%', md: '67%' }}
          mt={{ base: 4, md: 0 }}
          borderWidth={{ base: '0', md: '1px' }}
          borderColor="greyscale.400"
          borderRadius="lg"
          p={{ base: 0, md: 4 }}
          alignItems="flex-start"
          spacing={{ base: 4, md: 5 }}
        >
          <VStack
            spacing={{ base: 2, md: 3 }}
            width="100%"
            alignItems="flex-start"
          >
            <LabelValue label={t('common.name')} value={name} />
            <LabelValue
              label={t('common.address')}
              value={`${addressLine1} ${addressLine2 ? addressLine2 : ''}`}
            />
            <LabelValue
              label={t('common.city')}
              value={`${postalCode} ${city} ${state ? state : ''} ${countryCode}`}
            />
          </VStack>
          <Button
            size="lg"
            leftIcon={<Edit boxSize={{ base: 4, xlg: 5 }} />}
            variant="link"
            color="primary.500"
            fontWeight="normal"
            fontSize={{ base: 'sm', md: 'md' }}
            justifyContent="flex-start"
            onClick={onOpen}
            data-testid="btn-update-billing-address"
          >
            {t('payment_management.update_billing_address')}
          </Button>
        </VStack>
      </Section>
      <UpdateBillingInformation
        isOpen={isOpen}
        onClose={onClose}
        billingAddress={billingAddress}
      />
    </>
  );
};

export default BillingInformationView;
