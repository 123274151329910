import { type HTTPError, type TimeoutError } from 'ky';

export type HttpError = HTTPError | TimeoutError;
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type HttpResponse = [HttpError | null, any?];

export enum ERROR_CODES {
  ADD_JOURNEY_BLOCKER = 'add_journey_blocker',
  UNKNOWN = 'unknown',
}
