import React from 'react';
import { useTranslation } from 'react-i18next';

import { AirhelpPlus } from '@airhelp/logos';
import { USAToday, TheWashingtonPost, WSJ } from '@airhelp/react';
import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';

import LanguageSwitcher from 'elements/LanguageSwitcher';

const ImageContent = ({
  imageSrc,
  noFeaturedImages,
}: {
  imageSrc: string;
  noFeaturedImages?: boolean;
}) => {
  const { t } = useTranslation();

  const featuredImages = [
    { Image: TheWashingtonPost, key: 'the_washington_post' },
    { Image: USAToday, key: 'usa_today' },
    { Image: WSJ, key: 'wsj' },
  ];

  return (
    <>
      <Flex
        display={{ base: 'flex', lg: 'none' }}
        justifyContent="space-between"
        width="100%"
        mt={7}
      >
        <AirhelpPlus
          width={{ base: '110px', lg: '150px' }}
          height="auto"
          data-testid="airhelp-logo"
          m={4}
        />
        <LanguageSwitcher />
      </Flex>
      <Flex
        position="relative"
        width="100%"
        display={{ base: 'none', lg: 'flex' }}
      >
        <Image
          src={imageSrc}
          width="100%"
          height="100%"
          objectFit="cover"
          loading="lazy"
          alt=""
        />
        {!noFeaturedImages && (
          <Box pb={8} position="absolute" bottom={0} width="100%">
            <Text
              textTransform="uppercase"
              fontWeight="700"
              letterSpacing="1.5px"
              fontSize="xs"
              color="greyscale.100"
              textAlign="center"
              pb={8}
            >
              {t('lounge_finder.footer.en_airhelp_featured_in')}
            </Text>
            <Flex>
              <HStack
                flexWrap="wrap"
                justifyContent="center"
                alignItems="flex-end"
                flexBasis="100%"
                spacing={{ lg: 6, xl: 8 }}
              >
                {featuredImages.map(({ Image, key }) => (
                  <Image
                    h={{ sm: 8, lg: 6, xlg: 8 }}
                    w="auto"
                    color="greyscale.100"
                    key={key}
                  />
                ))}
              </HStack>
            </Flex>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default ImageContent;
