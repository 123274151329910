import React from 'react';

import {
  Drawer,
  Image,
  Text,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useTrackEvent } from 'utils/tracking/hooks';
import { ServiceProvider } from '../PerkServices';

interface IComponent {
  onClose: () => void;
  isOpen: boolean;
  content: React.ReactNode;
  imageSrc: string;
  imageText: string;
  imageTextColor?: string;
  service: string;
  provider: ServiceProvider;
}

const PerkDrawer: React.FC<IComponent> = ({
  isOpen,
  onClose,
  content,
  imageText,
  imageTextColor = 'primary.900',
  imageSrc,
  service,
  provider,
}) => {
  const { trackPageInteractions } = useTrackEvent();

  const placement: 'bottom' | 'right' =
    useBreakpointValue({ base: 'bottom', md: 'right' }) || 'bottom';

  const handleClose = async () => {
    trackPageInteractions('banner close', provider, 'travel service banner');

    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      size="sm"
      placement={placement}
    >
      <DrawerOverlay zIndex={100} />
      <DrawerContent
        position="relative"
        m={{ base: 0, md: 4 }}
        borderTopRadius="3xl"
        borderBottomRadius={{ base: 0, md: '3xl' }}
        height={{ base: '98dvh', md: 'auto' }}
      >
        <Image
          src={imageSrc}
          borderTopRadius="3xl"
          sx={{ touchAction: 'none' }}
          objectFit="cover"
          aspectRatio={{ base: 1.5, md: 'auto' }}
          width="auto"
        />
        <DrawerBody
          sx={{
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              width: 2,
            },
            '::-webkit-scrollbar-track': {
              background: 'transparent',
              borderRadius: 'md',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'greyscale.400',
              borderRadius: '3xl',
              height: '100px',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: { md: 'greyscale.400' },
            },
            scrollbarWidth: 0,
          }}
          pl={4}
          pr={2}
          py={0}
          mt={{ base: 4, md: 8 }}
          mb={{ base: 4, md: 8 }}
        >
          <DrawerCloseButton
            borderRadius="sm"
            boxSize={6}
            top={{ base: 4, md: 8 }}
            right={{ base: 4, md: 8 }}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2.5 },
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            data-testid={`btn-drawer-close-${service}`}
          />
          <Text
            fontWeight="medium"
            position="absolute"
            fontSize={{ base: 'sm', md: 'md' }}
            top={{ base: 4, md: 8 }}
            left={{ base: 5, md: 8 }}
            color={imageTextColor}
          >
            {imageText}
          </Text>
          {content}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default PerkDrawer;
