import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PriceTag } from '@airhelp/icons';
import {
  Box,
  Center,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import perkLogo from 'assets/images/perks/logo-rentalcars.svg';
import { CopyToClipboardButton } from 'elements';
import { useTrackEvent } from 'utils/tracking/hooks';

import BenefitsList from '../BenefitsList';
import GoToSiteButton from '../GoToSiteButton';

interface IComponent {
  code: string;
  discount: number;
}

const TRACKING_PROVIDER_NAME = 'rentalcars';

const CarRental: React.FC<IComponent> = ({ code, discount }) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();

  const benefits = [
    {
      content: t('dashboard.perks.car_rentals.free_cancellation_on_bookings'),
      key: 'free_cancellation',
    },
    {
      content: t('dashboard.perks.car_rentals.customer_support_in_languages'),
      key: 'customer_support',
    },
    {
      content: t('dashboard.perks.car_rentals.multiple_locations'),
      key: 'locations',
    },
    { content: t('dashboard.perks.car_rentals.no_fees'), key: 'no_fees' },
    {
      content: t('dashboard.perks.car_rentals.price_guarantee'),
      key: 'price_gurantee',
    },
    {
      content: t('dashboard.perks.car_rentals.flexible_rentals'),
      key: 'flexible_rentals',
    },
  ];

  const siteUrl =
    'https://www.rentalcars.com/?affiliateCode=airhelpge667&enabler=pbx1';

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(siteUrl, '_blank');
  };

  const onCodeCopied = () => {
    trackPageInteractions(
      'promo code copy',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );
  };

  return (
    <VStack
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      minHeight="100%"
    >
      {/* top section */}
      <Box>
        <HStack
          pb={{ base: 4, md: 8 }}
          justifyContent="space-between"
          alignItems="center"
          mb={{ base: 2, md: 0 }}
          width="100%"
        >
          <Image src={perkLogo} h={{ base: 4, md: 6 }} />
          <Center
            borderRadius="sm"
            px={2}
            py={3}
            h={4}
            alignItems="center"
            background="success.500"
          >
            <PriceTag color="white" mr={1} boxSize={4} />
            <Text
              color="white"
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
            >
              {t('dashboard.perks.percentage_off', { percentage: discount })}
            </Text>
          </Center>
        </HStack>

        <Heading variant={{ base: 'h5', md: 'h4' }} mb={4}>
          <Trans
            i18nKey="dashboard.perks.car_rentals.percentage_off_your_car_rental"
            values={{ percentage: discount }}
            components={{
              strong: (
                <Text
                  display="inline"
                  fontSize="xl"
                  fontWeight="bold"
                  color="danger.500"
                />
              ),
            }}
          />
        </Heading>

        <Text
          fontWeight="medium"
          fontSize={{ base: 'sm', md: 'md' }}
          pb={{ base: 4, md: 8 }}
        >
          {t('dashboard.perks.car_rentals.cancel_or_change_bookings', {
            discount,
          })}
        </Text>

        <Text fontSize={{ base: 'sm', md: 'md' }} color="greyscale.600">
          {t('dashboard.perks.car_rentals.find_perfect_wheels')}
        </Text>

        <Box my={{ base: 4, md: 8 }}>
          <BenefitsList benefits={benefits} />
        </Box>
      </Box>

      {/* bottom section */}
      <Box p={4} background="greyscale.300" borderRadius="xl" width="100%">
        <Text fontSize="sm" fontWeight="medium" pb={1}>
          {t('dashboard.perks.get_your_promocode')}
        </Text>

        <CopyToClipboardButton
          id="perk-code"
          inputValue={code}
          fullWidth
          valueAlign="left"
          onCopy={onCodeCopied}
        />

        <Text fontSize="md" color="greyscale.600" pt={2} pb={6}>
          {t('dashboard.perks.use_it_on', { url: 'rentalcars.com' })}
        </Text>

        <GoToSiteButton
          onClick={goToSite}
          label={t('dashboard.perks.car_rentals.get_car_rental')}
        />
      </Box>
    </VStack>
  );
};

export default CarRental;
