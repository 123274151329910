import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';

import { type LoungePass } from 'models/LoungePass';

import PassInformationItem from './PassInformationItem';
import PassNumeration from './PassNumeration';
import PassQrCode from './PassQrCode';

interface IComponent {
  ordinalNumber: number;
  loungePass: LoungePass;
}

const PassDetails: React.FC<IComponent> = ({ ordinalNumber, loungePass }) => {
  const { t } = useTranslation();

  const validUntil = format(
    new Date(loungePass.validUntil.substring(0, 10)),
    'dd.MM.yyyy',
  );
  const offsetMarginTop = ordinalNumber === 1 ? 3 : 0;

  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      backgroundColor="greyscale.200"
      borderRadius="lg"
      px={{ base: 4, md: 6 }}
      py={5}
      mt={{ base: 0, md: offsetMarginTop }}
      mb={{ base: 4, md: 3 }}
      position="relative"
      data-testid={`lounge-pass-${loungePass.passCode}-${ordinalNumber}`}
    >
      <PassNumeration number={ordinalNumber} />
      <PassQrCode imageUrl={loungePass.imageUrl} />
      <Box mt={4} width={{ base: '100%', md: '50%' }}>
        <PassInformationItem
          label={t('lounges.passes.voucher.name')}
          value={loungePass.passengerName}
        />
        <PassInformationItem
          label={t('lounges.passes.voucher.qr')}
          value={loungePass.passCode}
        />
        <PassInformationItem
          label={t('lounges.passes.voucher.expires_on')}
          value={validUntil}
        />
      </Box>
    </Flex>
  );
};

export default PassDetails;
