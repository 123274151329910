import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, generatePath } from 'react-router-dom';
import track from 'react-tracking';

import SettingsView from 'components/MyAccountView/SettingsView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import useFetchConsents from 'hooks/api/userProfiles/useFetchConsents/useFetchConsents';
import { type Consents } from 'models/userProfile/Consents';
import { usePageTracking } from 'utils/tracking';

const SettingsPage: React.FC = () => {
  usePageTracking();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  const {
    data: consentsData,
    isLoading: isLoadingConsents,
    isError: consentsError,
  } = useFetchConsents();

  if (isLoadingConsents) {
    return <Loader dataTestId="loader" />;
  }

  if (consentsError) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  const consents = consentsData as Consents;

  return <SettingsView consents={consents} />;
};

export default track({ page_type: 'MyAccount.Settings' })(SettingsPage);
