import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';

import checkBukaImage from 'assets/images/check-buka@2x.png';

interface IComponent {
  onStepComplete: () => void;
}

const PayoutConfirmationView: React.FC<IComponent> = ({ onStepComplete }) => {
  const { t } = useTranslation();

  const onSubmit = async () => {
    onStepComplete();
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading variant="h4" pb={5} data-testid="header">
        {t('air_luggage.luggage_details.we_have_got_everything')}
      </Heading>
      <Image src={checkBukaImage} alt="" width="256px" height="256px" />
      <Text fontSize="lg" fontWeight="normal" textAlign="center">
        {t('air_luggage.luggage_details.we_have_submitted_your_claim')}
      </Text>
      <Text fontSize="lg">
        {t('air_luggage.luggage_details.we_will_be_in_touch')}
      </Text>
      <Button
        size="m"
        width={{ base: '100%', md: 'initial' }}
        onClick={onSubmit}
        mt={6}
        data-testid="continue-button"
      >
        {t('common.continue_to_dashboard')}
      </Button>
    </Flex>
  );
};

export default PayoutConfirmationView;
