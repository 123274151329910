import React from 'react';

import { type IconType } from '@airhelp/icons';
import { Divider, VStack } from '@chakra-ui/react';

import { type TranslationKey } from 'types/i18n';

import PlanDetailsItem from './PlanDetailsItem';

export interface PlanItem {
  title?: string;
  title_tkey?: TranslationKey;
  value?: Record<string, string>;
  icon: IconType;
  tooltip?: {
    content: TranslationKey;
    value?: Record<string, string | number>;
  };
  key: string;
  included?: boolean;
}

const PlanDetailsList: React.FC<{
  planDetails: PlanItem[];
}> = ({ planDetails }) => {
  return (
    <VStack
      w={{ base: '100%', md: '67%' }}
      mt={{ base: 4, md: 0 }}
      borderWidth={{ base: '0', md: '1px' }}
      borderColor="greyscale.400"
      borderRadius="lg"
      p={{ base: 0, md: 8 }}
      alignItems="flex-start"
      spacing={4}
      divider={<Divider borderColor="greyscale.400" />}
    >
      {planDetails.map((item) => (
        <PlanDetailsItem item={item} key={item.key} />
      ))}
    </VStack>
  );
};

export default PlanDetailsList;
